import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useNotifications from './useNotifications';
import Error404 from 'components/errors/Error404';
import PageLoader from 'components/common/PageLoader';
import { Card } from 'react-bootstrap';
import useDomainNavigate from 'hooks/useDomainNavigate';
import { EventAction, EventName, EventPrefix } from 'apis/flex/notifications';
import { useNotificationAction } from './ActionListener';
export const getEventDomain = (eventName: EventName) => {
  if (!eventName) return null;
  const parts = eventName.split('-');
  return parts.filter((x, i) => i < parts.length - 1).join('-') as EventPrefix;
};
export const getEventAction = (eventName: EventName) => {
  if (!eventName) return null;
  const parts = eventName.split('-');
  return parts[parts.length - 1] as EventAction;
};
const NotificationRedirect = () => {
  const { id: _id, ids: _ids, isMultiple } = useParams();
  const ids = _ids
    ?.split(',')
    .map(i => Number(i))
    .filter(Boolean);
  const id = Number(_id);
  const { setSeen } = useNotificationAction({ id: ids?.length ? ids : id });
  const { data: notification, error } = useNotifications({
    id: ids?.length ? ids : id,
    columns: ['eventName', 'itemId']
  });
  console.log({ id, ids, isMultiple, notification, error });
  useEffect(() => {
    setSeen();
    // setActioned();
  }, []);
  const {
    navigate,
    url,
    error: routerError
  } = useDomainNavigate({
    domain: getEventDomain(notification?.[0]?.eventName),
    id: notification?.[0]?.itemId,
    isMultiple:
      typeof isMultiple === 'string' ? isMultiple === 'true' : !!isMultiple
  });
  useEffect(() => {
    if (url) navigate();
  }, [url]);
  return error || routerError || (notification && !notification.length) ? (
    <Error404 />
  ) : (
    <Card className="p-4">
      <PageLoader title="Redirecting..." height={'60vh'} />
    </Card>
  );
};
export default NotificationRedirect;
