import React from 'react';
import classNames from 'classnames';

const Divider = ({
  className,
  children,
  bg = null,
  color = null
}: {
  className?: string;
  children?: React.ReactNode;
  bg?: number | string;
  color?: string;
}) => (
  <div
    className={classNames('w-100 position-relative text-center', className, {
      'mb-2': !className
    })}
  >
    <hr
      className={color ? `text-${color}` : undefined}
      style={{
        color: color === 'inherit' ? 'inherit' : undefined
      }}
    />
    <div
      style={{
        // backgroundColor: 'inherit',
        color: color === 'inherit' ? 'inherit' : undefined
      }}
      className={classNames(`divider-content-center`, {
        [`text-${color}`]: color !== 'inherit' && color,
        ['bg-' + bg]: bg
      })}
    >
      {children}
    </div>
  </div>
);

export default Divider;
