import React from 'react';
import { Card, Col, ColProps } from 'react-bootstrap';
import Background from './Background';
import corner7 from 'assets/img/icons/spot-illustrations/corner-7.png';
import classNames from 'classnames';

export default ({
  description,
  textClassName,
  children,
  className,
  ...rest
}: {
  textClassName?: string;
  description: string;
  children?: React.ReactNode;
  className?: string;
} & ColProps) => {
  return (
    <Col className={classNames('mb-3', className)} {...rest}>
      <Card>
        <Card.Body className="z-1">
          <div
            className={textClassName}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {children}
        </Card.Body>
        <Background image={corner7} />
      </Card>
    </Col>
  );
};
