import { useMutation } from "@tanstack/react-query";
export default () => {
  const { mutate } = useMutation({
    mutationFn: async ({ cb, ms }) => {
      await new Promise((res) => setTimeout(res, ms));
    },
    onMutate: ({ cb, cancelled }) => {
      if (cancelled) return;
      cb?.();
    }
  });
  return {
    set: (cb, ms) => {
      let cancelled = false;
      const cancel = () => {
        cancelled = true;
      };
      mutate({ cb, ms, cancelled });
      return cancel;
    }
  };
};
