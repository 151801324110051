import React, { useEffect } from 'react';
import WizardInput, {
  WizardInputComponent,
  WizardInputOptions
} from '../WizardInput';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import SmallCheck from '../SmallCheck';
import { ensureArray } from 'helpers/utils';

const WizardQuickfire: WizardInputComponent<{
  // options: { label: string; value: any }[][];
  secondsPerGroup: number;
}> = ({ renderProps, pluginProps, options, formControlProps }) => {
  const { form, input, label } = renderProps;
  const handleChange = (val: any[]) => {
    // const current = form.field.value || [];
    // current[index] = val;
    form.field.onChange(val);
  };
  const groupedOptions = options.filter(
    v => typeof v !== 'string' && v.options
  ) as { options: WizardInputOptions; label: string; value: any }[];
  const [started, setStarted] = React.useState(false);
  return (
    <>
      {label}
      {input(
        <div className="mb-3 p-4 rounded-5 w-100 d-flex align-items-center justify-content-center">
          {form.field.value?.length && !formControlProps.readOnly ? (
            <h5>
              <SmallCheck size="lg" className="me-3" />
              Done!
            </h5>
          ) : started || formControlProps.readOnly ? (
            <Quickfire
              setValue={handleChange}
              optionGroups={groupedOptions}
              secondsPerGroup={pluginProps.secondsPerGroup}
              review={formControlProps.readOnly}
              values={form.field.value}
            />
          ) : (
            <>
              {/* //render a hidden select to ensure the coponent loads before the test starts */}
              <WizardInput
                type="radio"
                name="hidden"
                options={[]}
                registerProps={{ shouldUnregister: true }}
                formGroupProps={{ className: 'd-none' }}
              />
              <Button variant="falcon-primary" onClick={() => setStarted(true)}>
                Begin
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

const Quickfire = ({
  setValue,
  optionGroups,
  secondsPerGroup = 2,
  review,
  values,
  multiple
}: {
  setValue: (value: string[]) => void;
  optionGroups: { options: WizardInputOptions; label: string; value: any }[];
  secondsPerGroup?: number;
  review?: boolean;
  values?: string[];
  multiple?: boolean;
}) => {
  const [index, setIndex] = React.useState(0);
  const methods = useForm({
    defaultValues: {
      values: (values || []).reduce<Record<string, string[] | string>>(
        (a, b) => {
          const groups = optionGroups.filter(g =>
            g.options.find(v => (typeof v === 'string' ? v : v.value) === b)
          );
          groups.forEach(g => {
            if (multiple) {
              if (!a[g.value] || typeof a[g.value] === 'string') {
                a[g.value] = [];
              }
              (a[g.value] as string[]).push(b);
            } else {
              a[g.value] = b;
            }
          });
          return a;
        },
        {}
      )
    }
  });
  const handleFinish = () => {
    const formVals = methods.getValues('values');
    const vals = Object.values(formVals);
    const arr = ensureArray(vals);
    console.log('form vals', formVals, methods.getValues());
    setValue(arr.flat());
  };
  useEffect(() => {
    if (index >= optionGroups.length) {
      handleFinish();
    }
    refreshTimer();
  }, [index]);
  const [timerToggle, setTimerToggle] = React.useState(false);
  const refreshTimer = () => {
    setTimerToggle(!timerToggle);
  };
  const handleNext = () => {
    setIndex(i => i + 1);
    //for testing
    // setIndex(i => (i === 1 ? 0 : 1));
  };
  return (
    <div className="w-100">
      <FormProvider {...methods}>
        <Row className="align-items-center justify-content-center row">
          <Col
            xs={review ? 12 : 10}
            md={review ? 12 : 7}
            xl={review ? 12 : 4}
            // className={classNames({ 'd-flex gap-3': review })}
          >
            <Row>
              <AnimatePresence initial={false} mode="popLayout">
                {optionGroups.map(
                  (group, i) =>
                    (index === i || review) && (
                      <Col xs={12} lg={review ? 4 : 12} key={group.value}>
                        <motion.div
                          id={group.value}
                          transition={{ duration: 0.1 }}
                          initial={{ x: -400, opacity: 0 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{
                            opacity: 0,
                            x: 400
                          }}
                        >
                          {group.options?.length ? (
                            <WizardInput
                              type="radio"
                              pluginProps={{ button: true }}
                              name={'values.' + group.value}
                              readOnly={review}
                              options={group.options}
                              multiple={multiple}
                              label={
                                typeof group.label === 'string'
                                  ? group.label
                                  : ''
                              }
                              registerProps={{
                                required: false,
                                onChange: e => {
                                  handleNext();
                                }
                              }}
                            />
                          ) : (
                            <Form.Label>
                              {typeof group.label === 'string'
                                ? group.label
                                : ''}
                            </Form.Label>
                          )}
                        </motion.div>
                      </Col>
                    )
                )}
              </AnimatePresence>
            </Row>
          </Col>
        </Row>
      </FormProvider>
      {!review && (
        <Timer
          key={timerToggle.toString()}
          ms={secondsPerGroup * 1000}
          onEnd={handleNext}
        />
      )}
    </div>
  );
};
const Timer = ({ ms, onEnd }) => {
  const [lastChange, setLastChange] = React.useState<number>(Date.now());
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      // console.log('checking progress', lastChange + ms, Date.now());
      setProgress(Date.now() - lastChange);
    }, 50); // Check every 100ms (adjust as needed)

    return () => clearInterval(interval); // Cleanup on unmount
  }, [lastChange, ms, onEnd]);
  useEffect(() => {
    if (progress >= ms) {
      onEnd();
      setLastChange(Date.now()); // Reset the timer
    }
  }, [progress]);
  return (
    <div
      className="overflow-hidden w-100 bg-200 rounded-2"
      style={{ height: 10 }}
    >
      <div
        className="bg-primary h-100"
        style={{ width: `${(progress / ms) * 100}%`, transition: 'width 0.1s' }}
      ></div>
    </div>
    // <ProgressBar
    //   animated={false}
    //   style={{
    //     height: '10px',
    //     transitionDelay: '0s',
    //     transitionDuration: '0s'
    //   }}
    //   //without this it never gets to the end as the animation takes too long
    //   now={progress}
    //   min={0}
    //   max={ms}
    // />
  );
};
export default WizardQuickfire;
