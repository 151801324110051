import React, { useCallback } from 'react';
import { ColumnObject } from 'components/common/advance-table-v2/AdvanceTableProvider';
import useFormQuestions from '../hooks.js/useFormQuestions';
// import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { getCustomInputWrap } from '../helpers';
import { FormQuestion, FormResponse } from 'apis/flex/customForms';
import CustomWizardInput from '../Wizard/CustomWizardInput';
const getType = (q: FormQuestion) => {
  switch (q.inputType) {
    case 'interview':
    case 'transcribe':
    case 'speaking':
      return 'score';
    default:
      return q.inputType;
  }
};
export default <
  T extends any[] = ColumnObject<FormResponse & { metadata?: any }>[]
>({
  formId,
  visible = false,
  select,
  enabled = true
}: {
  formId: number | number[];
  responseIds?: number[];
  visible?: boolean;
  select?: (questions: ColumnObject<FormResponse & { metadata?: any }>[]) => T;
  enabled?: boolean;
}) => {
  const formatter = useCallback(
    questions => {
      const mapped = questions.map(q => ({
        id: q.id.toString(),

        header: q.label,
        type: getType(q),

        editor: ({ inputProps }) => (
          <CustomWizardInput question={q} inputProps={inputProps} />
        ),
        wrap: getCustomInputWrap(q.inputType),
        visible: !!visible,
        headerDownload: q.label,
        options: q.options?.length ? q.options : undefined,
        headerTooltip: (
          <div dangerouslySetInnerHTML={{ __html: q.questionText }}></div>
        ), //q.questionText,
        accessorFn: d => {
          const response = d[q.id.toString()];
          return d.metadata?.[q.id.toString()]?.analysis || response;
        },
        meta: {
          formId: q.formId
        }
        // formatter: getValue =>
        //   customInputValueFormatter(q.inputType)(getValue())
      }));
      if (!select) return mapped;
      return select(mapped);
    },
    [select]
  );
  const qs = useFormQuestions<T>({
    formId,
    staleTime: Infinity,
    select: formatter,
    enabled
  });
  console.log('formquestions', qs);
  return qs;
};
