import { useMutation } from '@tanstack/react-query';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
  Overlay,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import LoadingButton from './LoadingButton';
import PropTypes from 'prop-types';
import React from 'react';
import signalImg from 'assets/img/icons/signal.png';
import { aiApi } from 'apis/flex/helpers';
import FalconCardHeader from './FalconCardHeader';
import IconButton, { IconButtonProps } from './IconButton';
import { faBolt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from './FalconCloseButton';

export const AiIconButton = ({
  children,
  ...props
}: Partial<IconButtonProps>) => (
  <IconButton size="sm" variant="falcon-primary" icon={faBolt} {...props}>
    {children}
  </IconButton>
);
export type AiAssistantProps = {
  name: string;
  whoAmI?: string;
  preprompt?: string;
  enabled?: boolean;
  content?: 'html' | 'text' | 'json';
  jsonShape?: Record<string, any>;
  children?: ReactNode;
  buttonProps?: any;
};
export const AiButton = ({ children, ...props }: AiAssistantProps) => {
  const { setValue, getValues } = useFormContext();
  const [history, setHistory] = useState([]);
  const [context, setContext] = useState('');
  useEffect(() => {
    const value = getValues(props.name);
    if (!history?.length && !!value && !!context) {
      setHistory([{ user: createPrompt() }, { assistant: value ? value : '' }]);
    }
  }, [history, context]);
  const createPrompt = () =>
    history?.length
      ? context || props.preprompt
      : props.preprompt + '. ' + context;
  const { mutate, isLoading } = useMutation<
    string,
    Error,
    { history: any[]; prompt: string }
  >({
    mutationFn: ({ history, prompt }) =>
      props.content === 'html'
        ? aiApi.generateHtml(prompt, {
            whoAmI: props.whoAmI,
            history
          })
        : props.content === 'json'
        ? aiApi.generateJson(createPrompt(), {
            whoAmI: props.whoAmI,
            history,
            jsonShape: props.jsonShape
          })
        : aiApi.generateText(createPrompt(), { whoAmI: props.whoAmI, history }),
    onSuccess: (v, { history, prompt }) => {
      setHistory([...history, { user: prompt }, { assistant: v }]);
      setValue(props.name, v);
    }
  });
  const [showContext, setShowContext] = useState(false);
  const target = useRef(null);
  const input = useRef(null);
  useEffect(() => {
    if (showContext) input.current?.focus({ preventScroll: true });
  }, [input, showContext]);
  const handleWrite = () => {
    mutate({ history, prompt: createPrompt() });
  };
  return (
    <InputGroup ref={target} {...props.buttonProps}>
      <AiIconButton loading={isLoading} onClick={handleWrite}>
        {children || 'Write it'}
      </AiIconButton>
      <Overlay
        target={target.current}
        show={showContext}
        placement="top"
        rootClose
      >
        {props => (
          <Popover show {...props} style={{ ...props.style, maxWidth: 400 }}>
            <Popover.Body>
              {/* <Form onSubmit={e => e.preventDefault()}> */}
              <InputGroup>
                <Form.Control
                  placeholder="Make it more expressive"
                  onChange={e => setContext(e.target.value)}
                  // autoFocus
                  // onFocus={e => e.preventDefault()}
                  ref={input}
                  style={{
                    width: '14rem'
                  }}
                  size="sm"
                />
                <IconButton
                  icon={faBolt}
                  size={'sm'}
                  variant="falcon-primary"
                  loading={isLoading}
                  onClick={handleWrite}
                  breakpoint="xxl"
                  type="submit"
                >
                  {children || 'Write it'}
                </IconButton>
                <FalconCloseButton
                  size="sm"
                  className="ms-2 my-auto"
                  onClick={() => setShowContext(false)}
                />
              </InputGroup>
              {/* </Form> */}
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
      <Button
        variant="falcon-default"
        className="text-600 dropdown-caret-none"
        size="sm"
        onClick={() => setShowContext(true)}
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </Button>
    </InputGroup>
  );
};
const AiFormAssistant = ({
  children,
  name,
  description = 'Have AI write it for you!',
  preprompt,
  enabled,
  content = 'html',
  whoAmI
}: {
  children: ReactNode;
  description?: string;
} & AiAssistantProps) => {
  const { setValue } = useFormContext();
  const [prompt, setPrompt] = useState('');
  const [detail, setDetail] = useState('');
  const { mutate, isLoading } = useMutation<string, Error, string>({
    mutationFn: prompt =>
      content === 'html'
        ? aiApi.generateHtml(prompt, { whoAmI })
        : aiApi.generateText(prompt, { whoAmI }),
    onSuccess: v => {
      setValue(name, v);
    }
  });
  useEffect(() => {
    if (
      (enabled === undefined || enabled === true) &&
      !!preprompt &&
      preprompt !== prompt
    ) {
      setPrompt(preprompt);
      //   runPrompt();
    }
  }, [name, preprompt, enabled]);
  const handleCreate = () => {
    mutate(prompt + '\n' + detail);
  };
  return (
    <div className="position-relative">
      <OverlayTrigger
        trigger={['click']}
        rootClose
        overlay={
          <Card style={{ maxWidth: '600px' }}>
            <FalconCardHeader
              title={
                <div>
                  <img
                    src={signalImg}
                    alt="intelligence"
                    height={35}
                    className="me-2 mb-2"
                  />{' '}
                  AI Assistant
                </div>
              }
              subtitle={description}
              // className="d-flex justify-content-between"
            />
            <Card.Body>
              <Form.Group className="mb-4">
                <Form.Label>Extra detail (optional)</Form.Label>
                <textarea
                  rows={4}
                  cols={30}
                  onChange={e => setDetail(e.target.value)}
                  placeholder="Make it sound expressive!"
                  className="form-control"
                />
              </Form.Group>
              <div className="text-end">
                <LoadingButton
                  loading={isLoading}
                  onClick={handleCreate}
                  variant="falcon-primary"
                  size="sm"
                  className="float-right"
                >
                  Write it
                </LoadingButton>
              </div>
            </Card.Body>
          </Card>
        }
      >
        <div className="position-relative">{children}</div>
      </OverlayTrigger>
    </div>
  );
};
AiFormAssistant.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  preprompt: PropTypes.string,
  enabled: PropTypes.bool
};
export default AiFormAssistant;
