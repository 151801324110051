import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
export default (key, defaultValue, enabled = true) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = useMemo(() => {
    const param = searchParams.get(key);
    if (param !== null) {
      try {
        return JSON.parse(param);
      } catch {
        return param;
      }
    }
    return defaultValue;
  }, [searchParams, key, defaultValue]);
  const setValue = useCallback(
    (newValue) => {
      const updatedValue = typeof newValue === "function" ? newValue(value) : newValue;
      const newParams = new URLSearchParams(searchParams);
      if (updatedValue === null || updatedValue === void 0) {
        newParams.delete(key);
      } else {
        newParams.set(key, JSON.stringify(updatedValue));
      }
      setSearchParams(newParams, { replace: true });
    },
    [key, value, searchParams, setSearchParams]
  );
  const [basicState, setBasicState] = useState(value);
  return [basicState, setBasicState];
  return [value, setValue];
};
