import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import useResourceValues from './hooks/useResourceValues';
import { useUser } from 'hooks/useUser';
import { useState } from 'react';
import WizardInput from 'components/wizard/WizardInput';
import IconButton from 'components/common/IconButton';
import {
  faPlusCircle,
  faThumbsDown,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import { useEmployeeTrainingResources } from '../hooks/useUserTraining';

export default ({ onFinished = () => {}, index }) => {
  const { getValue } = useResourceValues({ index });
  // const { submit } = useDetailPage();
  const { update: _update } = useEmployeeTrainingResources({
    onSaveError: () => {
      resetLoaders();
    },
    noReturnOnChange: true
  });
  const { id } = useUser();
  // const ensureFinishDate = () => {
  //   //because a manager can approve/fail an unfinished resource, and other checks rely on 'finishedDate'
  //   if (!getValue('finishedDate')) {
  //     setValue('finishedDate', getValue('lastSubmittedDate'));
  //   }
  // };
  const update = vals => {
    _update(
      {
        id: getValue('id'),
        data: vals
      },
      {
        onSuccess: handleFinish
      }
    );
  };
  const [isFailing, setIsFailing] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isAddingAttempt, setIsAddingAttempt] = useState(false);
  const resetLoaders = () => {
    setIsFailing(false);
    setIsApproving(false);
    setIsAddingAttempt(false);
  };
  const handleFinish = () => {
    resetLoaders();
    onFinished();
  };
  const handleFail = () => {
    setIsFailing(true);
    update({
      failedDate: new Date(),
      failedBy: id,
      approvedDate: null,
      approvedBy: null
    });
  };
  const handleApprove = () => {
    setIsApproving(true);
    update({
      failedDate: null,
      failedBy: null,
      failedReason: null,
      approvedDate: new Date(),
      approvedBy: id
    });
  };
  const handleAddAttempt = () => {
    setIsAddingAttempt(true);
    update({
      extraAttempts: getValue('extraAttempts') + 1,
      failedDate: null,
      failedBy: null,
      failedReason: null,
      finishedDate: null
    });
  };
  const [showReason, setShowReason] = useState(false);
  return (
    <>
      <IconButton
        disabled={getValue('failedDate')}
        variant="danger"
        loading={isFailing}
        icon={faThumbsDown}
        onClick={() => setShowReason(true)}
      >
        Fail
      </IconButton>
      <IconButton
        disabled={getValue('approvedDate')}
        variant="falcon-success"
        icon={faPlusCircle}
        loading={isAddingAttempt}
        onClick={() => handleAddAttempt()}
      >
        Extra attempt
      </IconButton>
      <IconButton
        disabled={getValue('approvedDate')}
        icon={faThumbsUp}
        variant="success"
        loading={isApproving}
        onClick={handleApprove}
      >
        Approve
      </IconButton>
      <Modal show={showReason} onHide={() => setShowReason(false)}>
        <Modal.Body>
          <WizardInput
            type="textarea"
            label="Reason for failure (optional)"
            instruction={'If given, this will be shown to the trainee'}
            name={`resources.${index}.failedReason`}
            registerProps={{ required: false }}
          />
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button variant="link" onClick={() => setShowReason(false)}>
            Cancel
          </Button>
          <Button onClick={handleFail}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
