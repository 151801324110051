import { Button, Card, Col, Dropdown, Modal } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import ReportBuilder from './ReportBuilder';
import ReportTile from './ReportTile';
import CardDropdown from 'components/common/CardDropdown';
import usePageReports from './usePageReports';
import { ResponsiveModal } from 'components/common/Modals';
import { ReportConfig } from 'apis/flex/reporting';
import { CustomRule } from 'helpers/validation/validate';
import Skeleton from 'react-loading-skeleton';
import useReportConfigs from './useReportConfigs';
import ExpandableParagraph from 'components/common/ExpandableParagraph';
import { Guard, GuardRole, useGuard } from 'hooks/useGuard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';

export default ({
  reportId,
  config: configProp,
  simple,
  filters,
  variables
}: {
  reportId?: number;
  config?: ReportConfig;
  simple?: boolean;
  filters?: CustomRule | ((config: ReportConfig) => CustomRule);
  variables?: Record<string, any>;
}) => {
  const [editing, setEditing] = useState(false);
  const [viewing, setViewing] = useState(false);
  const { remove, data, isRemoving, isLoading } = usePageReports({
    filters: { reportId: reportId || configProp?.id },
    useFilter: true,
    silent: true
  });
  const { data: loadedConfig } = useReportConfigs({
    id: reportId,
    select: data => data[0]
  });
  const config = useMemo(
    () => loadedConfig || configProp,
    [loadedConfig, configProp]
  );
  const { canEdit } = useGuard({ roles: ['report-config'] });
  const [isStale, setIsStale] = useState(false);
  return (
    <Guard roles={config?.roles as GuardRole[]}>
      <Card>
        <Card.Header className="pb-0">
          <h6 className="mb-0">{isLoading ? <Skeleton /> : config?.title}</h6>
          <div className="form-text mt-0 d-inline-block">
            <ExpandableParagraph
              maxHeight={'2rem'}
              buttonProps={{ size: 'sm', className: 'fs--2' }}
            >
              {config?.description || <> </>}
            </ExpandableParagraph>
          </div>
          <div className="position-absolute top-0 end-0 m-1 d-flex btn-reveal-trigger">
            <Button
              variant="link"
              className="text-600 btn-reveal"
              size="sm"
              onClick={() => setIsStale(!isStale)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <CardDropdown>
              {canEdit && (
                <Dropdown.Item onClick={() => setEditing(true)}>
                  Edit
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className="text-danger"
                onClick={() => remove(data?.[0]?.id)}
              >
                Remove
              </Dropdown.Item>
            </CardDropdown>
          </div>
        </Card.Header>
        <div
          onClick={() => setViewing(true)}
          // id={tileId}
          // layoutId={tileId}
          // layout
        >
          <Card.Body className="pt-0">
            <ReportTile
              isLoading={isRemoving || isLoading}
              simple={simple}
              reportId={reportId}
              config={config}
              variables={variables}
              filters={filters}
              isStale={isStale}
              onRefetch={() => setIsStale(false)}
            />
          </Card.Body>
        </div>
        <ResponsiveModal wide show={viewing} onHide={() => setViewing(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>{isLoading ? <Skeleton /> : config?.title}</h5>
            </Modal.Title>
          </Modal.Header>
          {/* <AnimatePresence> */}
          {/* <motion.div layout id={tileId} layoutId={tileId}> */}
          <Modal.Body>
            <div className="mb-3">
              <ExpandableParagraph maxHeight={'3rem'}>
                {config?.description}
              </ExpandableParagraph>
            </div>
            <ReportTile height={'70vh'} reportId={reportId} config={config} />
          </Modal.Body>
          {/* </motion.div> */}
          {/* </AnimatePresence> */}
        </ResponsiveModal>
        <ResponsiveModal wide show={editing} onHide={() => setEditing(false)}>
          <ReportBuilder
            variables={variables}
            config={config}
            reportId={reportId}
            onSave={() => setEditing(false)}
            onCancel={() => setEditing(false)}
          />
        </ResponsiveModal>
      </Card>
    </Guard>
  );
};
