import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import React, {
  lazy,
  ReactNode,
  RefAttributes,
  useMemo,
  useState
} from 'react';
import Tooltip from 'components/common/Tooltip';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { WindowedMenuList } from 'react-windowed-select';
import PropTypes from 'prop-types';
import WizardInput, {
  WizardInputComponent,
  WizardInputOptions
} from '../WizardInput';
import {
  components,
  CSSObjectWithLabel,
  OptionProps,
  StylesConfig
} from 'react-select';
import Select, {
  FormatOptionLabelMeta,
  PublicBaseSelectProps
} from 'react-select/dist/declarations/src/Select';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import Wrapper from 'helpers/Wrapper';
import { FloatingLabel } from 'react-bootstrap';
import useMainRef from 'hooks/useMainRef';
const WindowedSelect = lazy(() => import('react-windowed-select'));

const MoreSelectedBadge = ({ items }) => {
  const style = {
    order: 99
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length} more...`;

  return (
    length > 0 && (
      <Tooltip content={title.toString()}>
        <span>
          <SoftBadge className={'ms-auto'} style={style} bg="dark">
            {label}
          </SoftBadge>
        </span>
      </Tooltip>
    )
  );
};
MoreSelectedBadge.propTypes = {
  items: PropTypes.array
};
const MultiValueContainer = ({ data, ...rest }: any) => {
  return (
    <Tooltip content={data.label.toString()}>
      <span style={{ maxWidth: '100%' }}>
        <components.MultiValueContainer {...{ data, ...rest }} />
      </span>
    </Tooltip>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map(x => x.label);
  return index < maxToShow ? (
    <>
      <components.MultiValue {...props} />
    </>
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
type ParsedOption = {
  value: any;
  label: string;
  isDisabled?: boolean;
  options?: ParsedOption[];
  description?: string;
  render?: (...args: any) => any;
  context?: any;
  exclusive?: boolean;
};
const WizardSelect: WizardInputComponent<
  Omit<
    PublicBaseSelectProps<ParsedOption, boolean, any> &
      CreatableProps<ParsedOption, boolean, any> &
      RefAttributes<Select<ParsedOption, boolean, any>>,
    'styles'
  > & {
    styles?: Partial<
      Record<keyof StylesConfig<ParsedOption, boolean, any>, CSSObjectWithLabel>
    >;
    grid: boolean | { cols?: number; width?: number };
    size: SizeProp;
    creatable: boolean;
    onCreate: (inputValue: string) => void;
  }
> = ({
  renderProps,
  multiple,
  placeholder,
  pluginProps: { styles: pluginPropStyles, ...pluginProps },
  formControlProps,
  options,
  type,
  id,
  name
}) => {
  // console.log('WizardSelect', name, options);
  const [specifyInputs, setSpecifyInputs] = useState([]);
  const {
    label,
    form: { field, errors, error },
    input
  } = renderProps;
  const flatten = arr => {
    return [...arr]
      .map(o => {
        if (o.options) {
          return flatten(o.options);
        }
        return o;
      })
      .flat(1);
  };
  const formatOptionLabel = (data, meta) =>
    pluginProps?.formatOptionLabel?.(data, meta) || (
      <div>
        <div>{data.label}</div>
        <div
          style={{ fontSize: '80%', color: 'gray', whiteSpace: 'break-spaces' }}
        >
          {data.description}
        </div>
      </div>
    );
  const Option = (
    props: OptionProps<ParsedOption>,
    meta: FormatOptionLabelMeta<ParsedOption>
  ) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {formatOptionLabel(props.data, meta)}
        </div>
      </components.Option>
    );
  };
  const styleIfGrid = (key, defaultVal) => {
    if (pluginProps?.grid) {
      if (pluginProps?.grid === true) {
        return defaultVal;
      } else {
        return pluginProps?.grid[key] || defaultVal;
      }
    }
  };
  const parseOptions = (options): ParsedOption =>
    JSON.parse(
      JSON.stringify(
        (options || [])
          .filter(o => o !== undefined)
          .map(o => ({
            value: o.options ? undefined : o.value ?? o,
            label: o.label ?? o,
            isDisabled: !!o.disabled,
            options: o.options && parseOptions(o.options),
            description: o.description,
            render: o.render,
            context: o.context,
            exclusive: o.exclusive
          }))
      )
    );
  const optionsObj = parseOptions(options);
  const flatOptions = flatten(optionsObj);
  const customStyles: StylesConfig = {
    menu: styles => {
      return {
        ...styles,
        ...pluginPropStyles?.menu,
        fontSize: 'small',
        cursor: 'pointer',
        marginTop: '0px',
        width: styleIfGrid('width', 400) || '100%'
      };
    },

    menuPortal: styles => {
      return {
        ...styles,
        zIndex: 9999,
        // position: 'fixed',
        ...pluginPropStyles?.menuPortal
      };
    },
    menuList: styles => {
      return {
        ...styles,
        ...pluginPropStyles?.menuList,
        display: pluginProps?.grid && 'grid',
        gridTemplateColumns: pluginProps?.grid
          ? 'repeat(' + styleIfGrid('cols', 3) + ', 1fr)'
          : null, // Adjust columns as needed
        gap: styleIfGrid('gap', 10) // Add some spacing between grid items
      };
    },
    control: (styles, state) => {
      if (pluginProps.size === 'sm') {
        styles.paddingLeft = '5px';
      }
      if (pluginProps.size !== 'sm') {
        styles.paddingTop = '3px';
        styles.paddingBottom = '3px';
      }
      if (state.isDisabled) {
        return {
          ...styles,
          backgroundColor: 'var(--falcon-gray-200)!important',
          opacity: 1
        };
      }
      return { ...styles, ...pluginPropStyles?.control };
    },
    dropdownIndicator: styles => {
      if (pluginProps.size === 'sm') {
        styles.padding = '6px 2px';
      }
      return {
        ...styles,
        ...pluginPropStyles?.dropdownIndicator,
        display: 'none'
      };
    },
    indicatorSeparator() {
      return { display: 'none' };
    },
    clearIndicator: styles => {
      if (pluginProps.size === 'sm') {
        styles.padding = '6px 2px';
      }
      // if (floatingLabel) {
      styles.padding = '0px 2px';
      // }
      return { ...styles, ...pluginPropStyles?.clearIndicator };
    },
    valueContainer: styles => {
      // if (floatingLabel) {
      styles.padding = '0px 0px';
      // }
      return {
        ...styles,
        ...pluginPropStyles?.valueContainer
      };
    },
    option: (provided, state) => ({
      ...provided,
      ...pluginPropStyles?.option,
      opacity: state.isDisabled ? 0.5 : provided.opacity || 1,
      cursor: state.isDisabled ? 'not-allowed' : provided.cursor
      // maxWidth: '50vw'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      ...pluginPropStyles?.multiValueRemove,
      display: state.isDisabled ? 'none' : provided.display
    }),
    container: (provided, state) => ({
      ...provided,
      pointerEvents: state.isDisabled ? 'all' : provided.pointerEvents
    })
  };
  const isMultiple = multiple || type === 'multiSelect';
  const assureSelected = selected => {
    const val = valueToOptions(field.value);
    if (isMultiple && Array.isArray(val)) {
      const nonRemovable = val.filter(v => v && v.isDisabled).map(v => v.value);
      const reinstated = new Set([...(selected || []), ...nonRemovable]);
      return [...reinstated];
    }
    return selected;
  };
  const valueToOptions = val => {
    return (
      (val !== undefined && (Array.isArray(val) ? val : [val])) ||
      []
    ).map(v =>
      flatOptions.find(
        o =>
          v !== undefined &&
          v !== null &&
          o.value.toString() === (v.value ?? v.toString())
      )
    );
  };
  const mainRef = useMainRef();
  // console.log('formControlProps', formControlProps);
  const elementProps: any = {
    className: classNames('w-100', {
      'is-valid': Object.keys(errors).length > 0 && !error,
      'is-invalid': !!error,
      'fs--1': pluginProps.size === 'sm'
    }),
    classNamePrefix: 'react-select',
    placeholder,
    // menuIsOpen: true,
    formatOptionLabel: formatOptionLabel,
    hideSelectedOptions: false,
    maxMenuHeight: 200,
    isClearable: true,
    isMulti: isMultiple,
    isDisabled: formControlProps.disabled || formControlProps.readOnly,
    value: valueToOptions(field.value),
    components: {
      MultiValueContainer,
      MultiValue,
      Option
      //for some reason this floating label thing causes the select input to remain focussed after clicking away
      // Control: ControlWithFloatingLabel
    },
    closeMenuOnSelect: !isMultiple,
    options: optionsObj,
    inputId: id,
    menuPortalTarget: document.body,
    //if it's fixed, the mobile keyboard will mess up the positioning
    menuPosition: 'absolute',
    menuPlacement: 'auto',
    styles: customStyles,
    ...pluginProps,
    classNames: {
      control: p => classNames(p.className, 'form-select'),
      valueContainer: p => {
        return classNames(p.className, 'py-0');
      }
    },
    onChange: selected => {
      const selectedOptions = Array.isArray(selected) ? selected : [selected];
      const specifies = selectedOptions.filter(o => o && o.specify);
      setSpecifyInputs(specifies);
      const exclusive = selectedOptions
        .filter(o => o && o.exclusive)
        .map(v => v?.value ?? '');
      if (exclusive?.length) {
        const lastExclusive = exclusive[exclusive.length - 1];
        return field.onChange(
          isMultiple ? assureSelected([lastExclusive]) : lastExclusive
        );
      } else {
        const val = selectedOptions.map(v => v?.value ?? '');
        field.onChange(isMultiple ? assureSelected(val) : val[0]);
      }
    }
  };
  const SelectComponent:
    | CreatableSelect
    | React.LazyExoticComponent<React.ForwardRefExoticComponent<any>> =
    useMemo(() => {
      if (pluginProps.creatable || pluginProps.onCreate) {
        return props => (
          <CreatableSelect
            components={{ MenuList: WindowedMenuList }}
            onCreateOption={pluginProps.onCreate || function () {}}
            selectProps={formControlProps}
            dataTour={formControlProps['data-tour']}
            {...props}
          />
        );
      }
      return props => (
        <WindowedSelect
          dataTour={formControlProps['data-tour']}
          selectProps={formControlProps}
          {...props}
        />
      );
    }, [pluginProps.creatable, pluginProps.onCreate]);
  return (
    <>
      {label}
      {input(<SelectComponent {...elementProps} />)}
      {specifyInputs.map((specify, i) => (
        <WizardInput
          key={i}
          type="textarea"
          name={specify.specify}
          label={specify.label + ' (please specify)'}
        />
      ))}
    </>
  );
};
export default WizardSelect;
