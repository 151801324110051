import {
  useMutation,
  useQueryClient
} from "@tanstack/react-query";
import { apiError, apiSuccess } from "apis/errors";
import useDefaultCrudTags from "./useDefaultCrudTags";
import { mutateAsyncToast } from "./helpers";
const successToast = (action) => apiSuccess("Done!");
export default ({
  noReturnOnChange,
  api,
  beforeSave,
  silent,
  afterSave,
  queryKey,
  invalidate: _invalidate,
  _queryKey,
  optimistic,
  afterChange,
  onSaveError
}) => {
  const queryClient = useQueryClient();
  const invalidate = async () => {
    return await Promise.all(
      [queryKey, ..._invalidate].map(
        (key) => queryClient.invalidateQueries({ queryKey: [key], exact: false }).then(() => {
          queryClient.refetchQueries({
            queryKey: [key],
            exact: false
          });
        })
      )
    );
  };
  const onSuccess = (action) => async (data) => {
    !silent && successToast(action);
    if (afterSave) {
      afterSave(data);
    }
    if (afterChange) {
      afterChange();
    }
    await invalidate();
  };
  const optimisticUpdater = (queryDataMutator) => (props) => {
    const previous = queryClient.getQueryData(_queryKey);
    if (optimistic && !!previous) {
      try {
        const updated = queryDataMutator(previous, props);
        queryClient.setQueryData(_queryKey, updated);
        return { previous, updated };
      } catch (e) {
        console.error(e);
        return { previous, updated: previous };
      }
    }
    return { previous, updated: previous };
  };
  const removeId = (data) => data.map(({ id, ...d }) => d);
  const optimisticUpdate = optimisticUpdater((data, inputProps) => {
    return data.map(
      (d) => d.id === inputProps.id ? { d, ...inputProps.data } : d
    );
  });
  const optimisticBulkUpdate = optimisticUpdater((data, inputProps) => {
    return data.map(
      (d) => inputProps.ids.includes(d.id) ? { d, ...inputProps.data } : d
    );
  });
  const optimisticAdd = optimisticUpdater((data, inputProps) => {
    return data.concat(
      removeId(
        Array.isArray(inputProps.vals) ? inputProps.vals : [inputProps.vals]
      )
    );
  });
  const optimisticClone = optimisticUpdater((data, inputProps) => {
    return data.concat(removeId([data.find((d) => d.id === inputProps.id)]));
  });
  const optimisticDelete = optimisticUpdater((data, id) => {
    return data;
  });
  const optimisticBulkDelete = optimisticUpdater((data, ids) => {
    return data;
  });
  const onError = (err, data, context) => {
    queryClient.setQueryData(_queryKey, context.previous);
    !silent && apiError(err);
    onSaveError?.(err);
  };
  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError,
    mutateAsync: updateAsync
  } = useMutation({
    mutationFn: ({ id, data, isSelf, noReturn = noReturnOnChange }) => isSelf ? api.selfUpdate(id, beforeSave(data), noReturn) : api.update(id, beforeSave(data), noReturn),
    onMutate: optimisticUpdate,
    onSuccess: onSuccess("update"),
    onError
  });
  const {
    mutate: bulkUpdate,
    isLoading: isBulkUpdating,
    error: bulkUpdateError,
    mutateAsync: bulkUpdateAsync
  } = useMutation({
    mutationFn: ({ ids, data, noReturn = noReturnOnChange }) => api.updateBulk({ id: ids }, beforeSave(data), noReturn),
    onMutate: optimisticBulkUpdate,
    onSuccess: onSuccess("bulkUpdate"),
    onError
  });
  const {
    mutate: add,
    isLoading: isAdding,
    error: addError,
    mutateAsync: addAsync
  } = useMutation({
    onMutate: optimisticAdd,
    onError,
    mutationFn: ({ vals, isSelf, noReturn = noReturnOnChange }) => isSelf ? api.selfInsert(beforeSave(vals), noReturn) : api.insert(beforeSave(vals), noReturn),
    onSuccess: onSuccess("add")
  });
  const {
    mutate: clone,
    isLoading: isCloning,
    error: cloneError,
    mutateAsync: cloneAsync
  } = useMutation({
    onMutate: optimisticClone,
    onError,
    mutationFn: ({ id, noReturn = noReturnOnChange }) => api.clone(id, noReturn),
    onSuccess: onSuccess("clone")
  });
  const {
    mutate: remove,
    isLoading: isRemoving,
    error: removeError,
    mutateAsync: removeAsync
  } = useMutation({
    onMutate: optimisticDelete,
    onError,
    mutationFn: (id) => api.remove(id),
    onSuccess: onSuccess("remove")
  });
  const {
    mutate: bulkRemove,
    isLoading: isBulkRemoving,
    error: bulkRemoveError,
    mutateAsync: bulkRemoveAsync
  } = useMutation({
    onMutate: optimisticBulkDelete,
    onError,
    mutationFn: (ids) => api.bulkRemove(ids),
    onSuccess: onSuccess("bulkRemove")
  });
  const {
    mutate: bulkSelfRemove,
    isLoading: isBulkSelfRemoving,
    error: bulkSelfRemoveError,
    mutateAsync: bulkSelfRemoveAsync
  } = useMutation({
    onMutate: optimisticBulkDelete,
    onError,
    mutationFn: (ids) => api.bulkRemove(ids, true),
    onSuccess: onSuccess("bulkRemove")
  });
  const {
    mutate: bulkRestore,
    isLoading: isBulkRestoring,
    error: bulkRestoreError,
    mutateAsync: bulkRestoreAsync
  } = useMutation({
    // onMutate: optimisticBulkDelete,
    onError,
    mutationFn: (filters) => api.bulkRestore(filters),
    onSuccess: onSuccess("bulkRestore")
  });
  const {
    mutate: bulkSelfRestore,
    isLoading: isBulkSelfRestoring,
    error: bulkSelfRestoreError,
    mutateAsync: bulkSelfRestoreAsync
  } = useMutation({
    // onMutate: optimisticBulkDelete,
    onError,
    mutationFn: (filters) => api.bulkRestore(filters, true),
    onSuccess: onSuccess("bulkRestore")
  });
  const {
    mutate: bulkAdd,
    isLoading: isBulkAdding,
    error: bulkAddError,
    mutateAsync: bulkAddAsync
  } = useMutation({
    onMutate: optimisticAdd,
    onError,
    mutationFn: ({ vals, noReturn = noReturnOnChange, isSelf }) => api.insertBulk(beforeSave(vals), noReturn, isSelf),
    onSuccess: onSuccess("bulkAdd")
  });
  const tagsMethods = useDefaultCrudTags({
    api,
    onSuccess: async () => {
      if (afterChange) {
        afterChange();
      }
      await invalidate();
    }
  });
  const upsert = (async) => (vals, done = () => {
  }, isSelf, noReturn) => {
    if (vals.id) {
      if (async) {
        return updateAsync({ id: vals.id, data: vals, isSelf, noReturn });
      }
      update(
        { id: vals.id, data: vals, isSelf, noReturn },
        {
          onSuccess: (d) => done(d)
        }
      );
    } else {
      if (async) {
        return addAsync({ vals, isSelf, noReturn });
      }
      add(
        { vals, isSelf, noReturn },
        {
          onSuccess: (d) => done(d)
        }
      );
    }
  };
  const invalidateServerCache = useMutation({
    mutationFn: api.invalidateCache
  });
  const interceptors = {
    updateSelf: (async) => (vals, opts) => async === true ? updateAsync({ ...vals, isSelf: true }, opts) : update({ ...vals, isSelf: true }, opts),
    add: (async) => (vals, opts, noReturn) => async ? addAsync({ vals, isSelf: false, noReturn }, opts) : add({ vals, isSelf: false, noReturn }, opts),
    clone: (async) => (id, opts, noReturn) => async ? cloneAsync({ id, isSelf: false, noReturn }, opts) : clone({ id, isSelf: false, noReturn }, opts),
    addSelf: (async) => (vals, opts, noReturn) => async ? addAsync({ vals, isSelf: true, noReturn }, opts) : add({ vals, isSelf: true, noReturn }, opts),
    upsertSelf: (async) => (vals, done, noReturn) => upsert(async)({ ...vals }, done, true, noReturn),
    bulkAdd: (async) => (vals, opts, noReturn, isSelf) => async ? bulkAddAsync({ vals, noReturn, isSelf }, opts) : bulkAdd({ vals, noReturn, isSelf }, opts)
  };
  return {
    ...tagsMethods,
    error: addError || cloneError || removeError || bulkAddError || updateError || bulkUpdateError || bulkRemoveError || bulkSelfRemoveError || bulkRestoreError || bulkSelfRestoreError || invalidateServerCache.error,
    upsert: upsert(false),
    bulkSelfRemove,
    remove,
    isRemoving,
    update,
    bulkUpdate,
    isBulkUpdating,
    isUpdating,
    isAdding,
    isCloning,
    isUpserting: isUpdating || isAdding,
    isBulkAdding,
    isBulkRemoving: isBulkRemoving || isBulkSelfRemoving,
    isInvalidating: invalidateServerCache.isLoading,
    invalidateServerCache: invalidateServerCache.mutate,
    invalidateServerCacheAsync: invalidateServerCache.mutateAsync,
    // ...interceptors,
    add: interceptors.add(false),
    addSelf: interceptors.addSelf(false),
    updateSelf: interceptors.updateSelf(false),
    clone: interceptors.clone(false),
    bulkAdd: interceptors.bulkAdd(false),
    upsertSelf: interceptors.upsertSelf(false),
    addAsync: mutateAsyncToast(interceptors.add(true), silent),
    addSelfAsync: mutateAsyncToast(interceptors.addSelf(true), silent),
    updateSelfAsync: mutateAsyncToast(
      interceptors.updateSelf(true),
      silent
    ),
    cloneAsync: mutateAsyncToast(interceptors.clone(true), silent),
    bulkAddAsync: mutateAsyncToast(interceptors.bulkAdd(true), silent),
    upsertSelfAsync: mutateAsyncToast(
      interceptors.upsertSelf(true),
      silent
    ),
    removeAsync: mutateAsyncToast(removeAsync, silent),
    updateAsync: mutateAsyncToast(updateAsync, silent),
    bulkUpdateAsync: mutateAsyncToast(bulkUpdateAsync, silent),
    upsertAsync: mutateAsyncToast(upsert(true), silent),
    bulkRemove,
    bulkRemoveAsync: mutateAsyncToast(bulkRemoveAsync, silent),
    bulkSelfRemoveAsync: mutateAsyncToast(bulkSelfRemoveAsync, silent),
    bulkRestore,
    bulkRestoreAsync: mutateAsyncToast(bulkRestoreAsync, silent),
    bulkSelfRestore,
    bulkSelfRestoreAsync: mutateAsyncToast(bulkSelfRestoreAsync, silent)
  };
};
