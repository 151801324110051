import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React, { ReactNode } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { uniq, uniqBy } from 'lodash';
import SettingsBox from 'components/common/SettingsBox';
import Expandable from 'components/common/Expandable';
import {
  DomainItemSelectorProps,
  getDomainItemSelector
} from './DomainItemSelector';
import {
  DefaultCrudData,
  mergeFilters
} from 'hooks/defaultCrud/useDefaultCrud';
import { EventPrefix } from 'apis/flex/notifications';
import { AvailabilityRule, ExtractParamsFromRule } from 'apis/flex/helpers';
import { domainConfigs } from 'components/notification/config';
import InputPlaceholder from 'components/wizard/InputPlaceholder';
import { Col } from 'react-bootstrap';
import { formatDate } from '@fullcalendar/core';
import { ensureArray } from 'helpers/utils';

export type FormAvailabilityRule<
  T extends Record<string, any> = Record<string, any>
> = Omit<T, 'dateRange'> & {
  dateRange: [Date, Date];
};
const getRuleParams = (rule: AvailabilityRule | FormAvailabilityRule) => {
  return uniq(Object.keys(rule)).filter(
    k =>
      k !== 'id' &&
      k !== 'companyId' &&
      k !== 'itemId' &&
      k !== 'ruleIndex' &&
      k !== 'startDate' &&
      k !== 'endDate' &&
      k !== 'createdDate' &&
      k !== 'createdBy' &&
      k !== 'modifiedDate' &&
      k !== 'modifiedBy' &&
      k !== 'dateRange'
  );
};
export const convertRulesToForm = <
  TParams extends Omit<Record<string, any>, 'dateRange'>
>(
  rules: AvailabilityRule<TParams>[]
): FormAvailabilityRule<TParams>[] => {
  const indices = uniq(rules?.map(r => r.ruleIndex));
  return indices?.map(ruleIndex => {
    const ruleGroup = rules.filter(r => r.ruleIndex === ruleIndex);
    const getValues = (name: string & keyof FormAvailabilityRule) =>
      uniq(
        ruleGroup?.map(r => r[name]).filter(d => d !== null && d !== undefined)
      );
    const uniqueFields = uniq(ruleGroup?.flatMap(getRuleParams));
    return uniqueFields.reduce((acc, k) => ({ ...acc, [k]: getValues(k) }), {
      dateRange: ruleGroup?.[0]?.startDate
        ? [
            new Date(ruleGroup?.[0]?.startDate),
            new Date(ruleGroup?.[0]?.endDate)
          ]
        : undefined
    } as FormAvailabilityRule<TParams>);
  });
};
export function generateObjectCombinations<TParams extends string>(
  input: Record<
    TParams,
    (number | string | null | undefined)[] | null | undefined
  >,
  _keys?: TParams[]
): Record<TParams, string | number>[] {
  // Filter out keys where the array is empty or only contains null/undefined values
  const filteredEntries = Object.entries<
    (number | string | null | undefined)[] | null | undefined
  >(input)
    .map(([key, values]) => ({
      key: key as TParams,
      values: ensureArray(values)?.filter(v => v !== null && v !== undefined)
    }))
    .filter(
      entry => entry.values?.length && (!_keys || _keys.includes(entry.key))
    ); // Remove keys with no valid values

  const keys = filteredEntries.map(entry => entry.key as TParams);
  const values = filteredEntries.map(entry => entry.values);

  // Helper function to generate cartesian product (combinations)
  function cartesian<T>(arr: T[][]): T[][] {
    return arr.reduce<T[][]>(
      (acc, curr) => acc.flatMap(a => curr.map(b => [...a, b])),
      [[]]
    );
  }

  // Generate combinations as an array of arrays
  const combinations = cartesian(values);

  // Convert array format to object format
  return combinations.map(combination =>
    Object.fromEntries(keys.map((key, index) => [key, combination[index]]))
  ) as Record<TParams, string | number>[];
}

function generateCombinations<TParams extends Record<string, any>>(
  itemId: number,
  rule: FormAvailabilityRule,
  ruleIndex: number
): AvailabilityRule<TParams>[] {
  const combinations: AvailabilityRule<TParams>[] = [];
  const keys = getRuleParams(rule);

  const combinationsArray = generateObjectCombinations(rule, keys);

  combinationsArray.forEach(c => {
    combinations.push({
      id: undefined,
      ruleIndex,
      itemId,
      ...c,
      startDate: rule.dateRange?.[0],
      endDate: rule.dateRange?.[1]
    } as AvailabilityRule<TParams>);
  });
  return uniqBy(combinations, JSON.stringify);
}
export const convertRulesFromForm = <TParams extends Record<string, any>>(
  itemId: number,
  rules: FormAvailabilityRule[]
): AvailabilityRule<TParams>[] => {
  return rules.flatMap((rule, i) => generateCombinations(itemId, rule, i));
};
type AvailabilityParamsFromData<
  TData extends DefaultCrudData & {
    availabilityRules?: AvailabilityRule<any>[];
  }
> = (string &
  keyof ExtractParamsFromRule<TData['availabilityRules'][number]>)[];
export const AvailabilityRulesForm = <
  TData extends DefaultCrudData & {
    availabilityRules?: AvailabilityRule<any>[];
  } = DefaultCrudData & { availabilityRules?: AvailabilityRule<any>[] },
  TParams extends AvailabilityParamsFromData<TData> = AvailabilityParamsFromData<TData>
>({
  name = 'availabilityRules',
  fields: paramFields
}: Omit<Partial<WizardInputProps>, 'children'> & {
  fields: Partial<
    Record<
      TParams[number],
      (
        props: WizardInputProps & {
          name: string;
          multiple: boolean;
          registerProps: any;
        },
        index: number
      ) => ReactNode
    >
  >;
}) => {
  const { fields, append, remove } = useFieldArray<
    Record<string, AvailabilityRule<Record<keyof TParams, any>>[]>,
    any
  >({ name });
  const { getValues } = useFormContext();
  const renderers = Object.entries<
    (
      props: WizardInputProps & {
        name: string;
        multiple: boolean;
        registerProps: any;
        key: string;
      },
      index: number
    ) => ReactNode
  >(paramFields);
  return (
    <FieldArrayList
      fields={fields}
      append={val => append(val)}
      remove={remove}
      defaultValues={{}}
      divider="OR"
      item={(field, i) => {
        const vals = getValues(`${name}.${i}`);
        const isDateField = r => r[0] === 'weekday';
        const dateFields = renderers.filter(isDateField);
        const otherFields = renderers.filter(r => !isDateField(r));
        const firstField = otherFields[0];
        const extraFields = otherFields.slice(1);
        const activeFields = extraFields.filter(r => vals?.[r[0]]?.length);
        return (
          <SettingsBox
            bg="light"
            title={`Rule ${i + 1}`}
            description="Will be permitted if ALL of these conditions are met"
          >
            {dateFields.map(f =>
              f[1]?.(
                {
                  name: `${name}.${i}.${f[0]}`,
                  multiple: true,
                  registerProps: { required: false },
                  key: fields[i].id + '_' + f[0]
                },
                i
              )
            )}
            <WizardInput
              name={`${name}.${i}.dateRange`}
              label="Date Range"
              type="daterange"
              registerProps={{ required: false }}
            />
            <SettingsBox
              bg="light"
              title={`Rule ${i + 1} additional conditions`}
              description="Will be permitted if ANY of these conditions are met, as well as all of the conditions above (where specified)"
            >
              {firstField[1]?.(
                {
                  name: `${name}.${i}.${firstField[0]}`,
                  multiple: true,
                  registerProps: { required: false },
                  key: fields[i].id + '_' + firstField[0]
                },
                i
              )}
              <Expandable activeCount={activeFields.length} bg="light">
                {extraFields.map(r =>
                  r[1]?.(
                    {
                      name: `${name}.${i}.${r[0]}`,
                      multiple: true,
                      registerProps: { required: false },
                      key: fields[i].id + '_' + r[0]
                    },
                    i
                  )
                )}
              </Expandable>
            </SettingsBox>
          </SettingsBox>
        );
      }}
    />
  );
};
export const getAvailableItemSelector = <
  TData extends DefaultCrudData & {
    availabilityRules?: AvailabilityRule<any>[];
  },
  TParams extends ExtractParamsFromRule<
    TData['availabilityRules'][number]
  > = ExtractParamsFromRule<TData['availabilityRules'][number]>
>({
  domain,
  startDate,
  endDate,
  availableWithParams
}: {
  domain: EventPrefix;
  startDate?: Date;
  endDate?: Date;
  availableWithParams?: TParams;
}) =>
  getDomainItemSelector<TData>(domain, {
    crudProps: {
      availableWith: {
        startDate,
        endDate,
        ...availableWithParams
      }
    }
  });
export const AvailablePerDaySelector = <
  TData extends DefaultCrudData & {
    availabilityRules?: AvailabilityRule<any>[];
  },
  TParams extends ExtractParamsFromRule<
    TData['availabilityRules'][number]
  > = ExtractParamsFromRule<TData['availabilityRules'][number]>
>({
  name,
  domain,
  availableWith,
  filter: defaultFilter,
  render = (input, date) => (
    <Col key={date} xs={12} md={4} xl={3}>
      {input({ label: formatDate(new Date(date)) })}
    </Col>
  ),
  startDate,
  endDate,
  ...defaultProps
}: DomainItemSelectorProps<TData> & {
  render?: (
    item: (props: DomainItemSelectorProps<TData>) => JSX.Element,
    date: `${string}-${string}-${string}`
  ) => ReactNode;
  availableWith: TParams;
  startDate: Date;
  endDate: Date;
  domain: EventPrefix;
}) => {
  const config = domainConfigs[domain];
  const crudHook = config.crudHook;
  const res = crudHook?.({
    availableWith: {
      startDate,
      endDate,
      ...availableWith
    }
  });
  const Picker = getDomainItemSelector<TData>(domain);
  if (!res) return null;
  const { availabilitySchedule, isLoading } = res;
  return isLoading ? (
    <InputPlaceholder />
  ) : (
    Object.keys(availabilitySchedule || {}).map(
      (date: `${string}-${string}-${string}`) =>
        render(
          ({ filter, ...props }) => (
            <Picker
              onNewClick={false}
              name={`${name}.${date}`}
              loading={isLoading}
              filter={mergeFilters(defaultFilter, filter, [
                {
                  first: 'id',
                  second: availabilitySchedule[date]?.filter(Boolean)
                }
              ])}
              {...defaultProps}
              {...props}
            />
          ),
          date
        )
    )
  );
};
