import { useQuery } from "@tanstack/react-query";
import {
  getResourceGroupSchedule,
  getResourcePlan
} from "apis/flex/projects";
import { useEffect, useMemo, useState } from "react";
export default ({
  filters,
  select
}) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["resourcePlan", filters],
    queryFn: () => getResourcePlan({ filters }),
    select,
    staleTime: Infinity
    // ...rest
  });
  const [refetching, setRefetching] = useState(false);
  useEffect(() => {
    if (refetching && !isRefetching) {
      setRefetching(false);
    }
  }, [isRefetching]);
  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: () => {
        setRefetching(true);
        refetch();
      },
      isFetching: isLoading,
      isRefetching: refetching
    }),
    [data, isLoading, refetch, refetching]
  );
};
export const useResourceGroupSchedule = (id) => {
  return useQuery({
    queryKey: ["resourceGroupSchedule", id],
    queryFn: () => getResourceGroupSchedule({ id }),
    staleTime: Infinity
  });
};
