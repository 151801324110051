import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import DiffBadge from './DiffBadge';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (data: Array<number>, variant = 'primary') => ({
  series: [
    {
      type: 'line',
      data,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor(variant), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor(variant), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { bottom: '-10px' }
});

const SimpleLine = ({
  data,
  total,
  prevTotal,
  title,
  description = null,
  ...rest
}: {
  data: Array<number>;
  total?: number;
  prevTotal?: number;
  title: string;
  description?: string;
}) => {
  return (
    <Card className="h-md-100" {...rest}>
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          {title}
          {description && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: 'fixed' }}>{description}</Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
            </OverlayTrigger>
          )}
        </h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{total}</h2>
          <DiffBadge current={total} prev={prevTotal} />
        </div>
        <div className="ps-0">
          <SparkLine data={data} style={{ width: '8.5rem', height: 87 }} />
        </div>
      </Card.Body>
    </Card>
  );
};
export const SparkLine = ({
  style = null,
  data = [0, 0],
  variant = 'primary'
}) => {
  return (
    <BasicECharts
      echarts={echarts}
      options={getOptions(data, variant)}
      style={style || { width: '8.5rem', height: 87 }}
    />
  );
};

export default SimpleLine;
