import React from 'react';
import useCurrentUserTraining from '../hooks/useCurrentUserTraining';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import TrainingCoursePicker from '../widgets/TrainingCoursePicker';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useUserTraining from '../hooks/useUserTraining';
import { ResourcePreview } from '../client/TrainingClient';
import { TrainingCourse, EmployeeTrainingCourse } from 'apis/flex/hr';
import Background from 'components/common/Background';
import { getMediaStreamUrl } from 'helpers/utils';
import coverSrc from 'assets/img/generic/training.jpg';
import DomainTable from 'components/common/DomainTable';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import TrainingAttempts from './TrainingAttempts';

const NewCoursePicker = ({
  myTrainingLookup
}: {
  myTrainingLookup: Map<number, EmployeeTrainingCourse>;
}) => {
  return (
    <TrainingCoursePicker
      name="courseId"
      label="Enroll on a new course"
      registerProps={{ required: false }}
      confirmText="Take a look"
      mutator={course => ({
        locked:
          course.prerequisites &&
          !course.prerequisites?.some(p =>
            myTrainingLookup
              .get(p.prerequisiteId)
              ?.resources?.some(r => !r.approvedDate)
          )
      })}
      filter={{
        first: 'id',
        negate: true,
        second: [...myTrainingLookup.keys()]
      }}
    />
  );
};
const MyCoursesTable = () => {
  const nav = useNavigate();
  const { id: userId } = useUser();
  return <TrainingAttempts userId={userId} />;
  // return (
  //   <DomainTable<EmployeeTrainingCourse>
  //     domain="employee-training"
  //     crudHook={useUserTraining}
  //     crudProps={{ filters: { userId } }}
  //     onRowClick={r => nav('' + r.original.courseId)}
  //     columns={[
  //       {
  //         id: 'courseId',
  //         domain: 'training-course'
  //       },
  //       {
  //         id: 'resources',
  //         visible: false
  //       },
  //       {
  //         id: 'progress',
  //         accessorFn: row => {
  //           const numberOfResources = row?.resources?.length;
  //           const resourcesDone = row?.resources?.filter(
  //             r => r?.approvedDate
  //           ).length;
  //           return resourcesDone / numberOfResources;
  //         },
  //         formatter: (v, d) => {
  //           const prog = v();
  //           const failed = d.resources.some(r => r.failedDate);
  //           return (
  //             <ProgressBar
  //               className="w-100"
  //               variant={failed ? 'danger' : prog === 1 ? 'success' : 'warning'}
  //               animated={prog < 1}
  //               now={prog}
  //               max={1}
  //               label={failed ? 'Failed' : prog === 1 ? 'Completed' : ''}
  //             />
  //           );
  //         },
  //         header: 'Progress',
  //         size: 200
  //       },
  //       'deadlineDate',
  //       "expieryDate",
  //       'lastSubmittedDate'
  //     ]}
  //     title="My courses"
  //   />
  // );
};
const CoursePreview = ({
  course,
  isLoading,
  onConfirm
}: {
  course: TrainingCourse;
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  return (
    <Flex
      alignItems={'start'}
      wrap={'wrap'}
      justifyContent={'between'}
      className="flex-column p-2 d-flex p-2"
    >
      <Flex className={'w-100 position-relative  mb-4'} style={{ height: 120 }}>
        {/* <CourseItemContent
          //   href={'' + course.id}
          data={course}
          isLoading={isLoading}
        /> */}
        <Background
          isLoading={isLoading}
          image={
            course.profileBackground
              ? getMediaStreamUrl(course.profileBackground)
              : coverSrc
          }
          className="rounded-3"
        />
        <Card
          className="position-absolute start-0 m-2 px-3 py-2"
          style={{ bottom: -28 }}
        >
          <h5>{course.name}</h5>
        </Card>
      </Flex>
      <p
        className="text-muted mt-2 fs--1 ps-2"
        dangerouslySetInnerHTML={{ __html: course.description }}
      />
      <Flex className={'gap-2 p-2 w-100 flex-column'}>
        <h6>Modules</h6>
        <div className="ps-2">
          {course.resources.map(r => (
            <ResourcePreview key={r.id} resource={r} />
          ))}
        </div>
      </Flex>
      <Button
        onClick={onConfirm}
        className="align-self-end mt-2"
        variant="primary"
      >
        Enroll
      </Button>
    </Flex>
  );
};
const CourseEnroll = ({ onEnrolled }) => {
  const { myTrainingLookup, courses, isLoading, userId } =
    useCurrentUserTraining();
  const newId = useWatch({ name: 'courseId' });
  const newCourse = courses?.find(c => c.id === Number(newId));
  const { addSelf } = useUserTraining();
  const handleEnroll = () => {
    addSelf(
      { courseId: newCourse.id, userId },
      { onSuccess: () => onEnrolled() }
    );
  };
  return (
    <Card>
      <Card.Body>
        <NewCoursePicker myTrainingLookup={myTrainingLookup} />
        {newCourse && (
          <CoursePreview
            course={newCourse}
            isLoading={isLoading}
            onConfirm={handleEnroll}
          />
        )}
      </Card.Body>
    </Card>
  );
};
export default () => {
  const methods = useForm();
  return (
    <Row className="gap-3">
      <Col xs={12}>
        <MyCoursesTable />
      </Col>
      <Col>
        <FormProvider {...methods}>
          <CourseEnroll onEnrolled={methods.reset} />
        </FormProvider>
      </Col>
    </Row>
  );
};
