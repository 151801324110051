import { useUser } from "hooks/useUser";
import useTrainingCourses from "./useTrainingCourses";
import useUserTraining from "./useUserTraining";
export default () => {
  const { id: userId } = useUser();
  const { data: myTraining, isLoading: utLoading } = useUserTraining({
    filters: { userId },
    useFilter: !!userId
  });
  const myTrainingLookup = new Map(myTraining?.map((r) => [r.courseId, r]));
  const { data, isLoading } = useTrainingCourses({ useFilter: true });
  return {
    courses: data,
    isLoading: isLoading || utLoading,
    myTrainingLookup,
    userId
  };
};
