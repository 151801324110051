import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import LoadingButton from './LoadingButton';
import { useInputConfig } from 'components/wizard/InputConfig';
import classNames from 'classnames';

export default ({
  submitText = 'Submit',
  cancelText = 'Cancel',
  isLoading,
  onSubmit,
  onCancel,
  className,
  size,
  children,
  disabled,
  variant,
  ...rest
}: {
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  size?: ButtonProps['size'];
  disabled?: boolean;
  variant?: ButtonProps['variant'];
} & Partial<React.HTMLAttributes<HTMLDivElement>>) => {
  const configs = useInputConfig();
  const dis = disabled === undefined ? configs?.readOnly : disabled;
  return (
    <div
      className={classNames(className, 'd-flex justify-content-end')}
      {...rest}
    >
      {onCancel && (
        <Button disabled={dis} size={size} variant="link" onClick={onCancel}>
          {cancelText}
        </Button>
      )}
      {children}
      <LoadingButton
        disabled={dis}
        type="submit"
        size={size}
        variant={variant}
        loading={isLoading}
        onClick={onSubmit}
      >
        {submitText}
      </LoadingButton>
    </div>
  );
};
