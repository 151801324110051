import React from 'react';
import PageLoader from 'components/common/PageLoader';
import FeedbackModal from 'components/navbar/top/FeedbackModal';
import useApp from 'hooks/useApp';
import { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import EmoteProvider from 'widgets/EmoteProvider';
import { NavigationBlockerProvider } from 'hooks/useNavWhen';

export default () => {
  const { config, setConfig } = useApp();
  return (
    <CookiesProvider>
      <EmoteProvider>
        <Suspense fallback={<PageLoader />}>
          <NavigationBlockerProvider>
            <FeedbackModal
              show={config.showFeedbackModal}
              setShow={s => setConfig('showFeedbackModal', s)}
            />
            <Outlet />
          </NavigationBlockerProvider>
        </Suspense>
      </EmoteProvider>
    </CookiesProvider>
  );
};
