import React, { useEffect } from 'react';
import { motion, AnimationProps } from 'framer-motion';
import classNames from 'classnames';

const Attention = ({
  trigger,
  setTrigger,
  children,
  className,
  ...rest
}: {
  trigger: boolean;
  className?: string;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => {
  const shadow = '0 0 2px 1px rgb(16 225 225 / 30%)';
  const wiggleAnimation: AnimationProps['animate'] = {
    rotate: [0, -4, 4, -4, 4, 0], // Wiggle effect
    scale: [1, 1.1, 1.1, 1.1, 1.1, 1],
    // boxShadow: [
    //   '0 0 0 0 rgba(0,0,0,0)',
    //   shadow,
    //   shadow,
    //   shadow,
    //   shadow,
    //   '0 0 0 0 rgba(0,0,0,0)'
    // ],
    transition: {
      duration: 0.5,
      //   repeat: 0,
      //   repeatType: 'loop',
      ease: 'easeOut'
    }
  };
  useEffect(() => {
    if (trigger) {
      setTimeout(() => setTrigger(false), 1000);
    }
  }, [trigger]);
  return (
    <motion.div
      className={classNames('d-inline-block', className)}
      {...rest}
      animate={trigger ? wiggleAnimation : {}}
    >
      {children}
    </motion.div>
  );
};

export default Attention;
