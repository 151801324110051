import React, { useReducer } from 'react';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import 'react-loading-skeleton/dist/skeleton.css';

import { Chart as ChartJS, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { atomWithStorage } from 'jotai/utils';
import { ApiError, apiError, getErrorCode, isNetworkError } from 'apis/errors';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setDefaultOptions } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Error500 from 'components/errors/Error500';
import ErrorMaintenance from 'components/errors/ErrorMaintenance';

ChartJS.register(...registerables);

const Main = () => {
  setDefaultOptions({ locale: enGB });

  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    isDemo: getItemFromStore('isDemo', settings.isDemo),
    isMobile: window.screen.width < 992
  };
  const [config, configDispatch] = useReducer<(p: any, a: any) => any>(
    configReducer,
    configState
  );

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const [downForMaintance, setDownForMaintenance] = React.useState(false);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryOnMount: false,
        networkMode: 'always',
        retry: (failureCount, error: ApiError) => {
          if (getErrorCode(error) === 429 || getErrorCode(error) === 502) {
            setDownForMaintenance(true);
            return false;
          }
          if (getErrorCode(error) === 401 || isNetworkError(error)) {
            return failureCount < 3;
          }
          return false;
        }
      },
      mutations: {
        onError: apiError,
        networkMode: 'always'
      }
    }
  });
  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isDemo',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };
  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{ config, setConfig, configDispatch, ...config }}
      >
        <ReactQueryDevtools />
        {downForMaintance ? <ErrorMaintenance /> : <App />}
      </AppContext.Provider>
    </QueryClientProvider>
  );
};
export const returnToAtom = atomWithStorage('returnTo', null, sessionStorage);
export default Main;
