import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition
} from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  Spinner,
  Modal,
  Dropdown,
  ButtonGroup
} from 'react-bootstrap';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { callIfFunction, camelToSentence, downloadCSV } from 'helpers/utils';
import {
  BulkAction,
  ProcessedCell,
  useAdvanceTable
} from './AdvanceTableProvider';
import {
  faEdit,
  faEllipsisH,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import {
  AdvanceTableCrudFilters,
  AdvanceTableFilters
} from './AdvanceTableFilters';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../FalconCardHeader';
import { format } from 'date-fns';
import AdminEditorModal from '../admin-table-v2/AdminEditorModal';
import Flex from '../Flex';
import { ModalConfirm, ResponsiveModal } from '../Modals';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import { Row as RowType } from '@tanstack/react-table';
import DomainIcon from '../DomainIcon';
import { CustomRule } from 'helpers/validation/validate';
import { ActionButtons } from '../BottomBar';
import classNames from 'classnames';
import SettingsDropdown from '../SettingsDropdown';

const BulkEditModal = ({ show, setShow }) => {
  return <AdminEditorModal show={show} setShow={setShow} />;
};
BulkEditModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};
const ExportModal = ({
  rows,
  exportTitle,
  show,
  setShow,
  exportAllData
}: {
  rows: RowType<any>[];
  exportTitle: string;
  show: boolean;
  setShow: (show: boolean) => void;
  exportAllData?: boolean;
}) => {
  const { getAllFlatColumns } = useAdvanceTable();
  const getAllHeaders = () => {
    return getAllFlatColumns()
      .filter(c => c.columnDef.download !== false || exportAllData)
      .map((c, i) => ({
        download: c.columnDef.headerDownload || camelToSentence(c.columnDef.id),
        id: c.columnDef.id,
        index: i
      }));
  };
  const allHeaders = getAllHeaders();
  const getDownloadData = () =>
    rows.map(r => {
      return r
        .getAllCells()
        .sort((a, b) => {
          const headerA = allHeaders?.find(h => h.id === a.column.columnDef.id);
          const headerB = allHeaders?.find(h => h.id === b.column.columnDef.id);
          return headerA?.index - headerB?.index;
        })
        .reduce((a, b: ProcessedCell) => {
          if (typeof b.column.columnDef.download === 'function') {
            a[b.column.columnDef.id] = b.column.columnDef.download(
              r.getValue(b.column.columnDef.id),
              r.original
            );
          } else if (allHeaders?.find(h => h.id === b.column.columnDef.id)) {
            a[b.column.columnDef.id] =
              r.getValue(b.column.columnDef.id) ||
              r.original[b.column.columnDef.id] ||
              '';
          }
          return a;
        }, {});
    });
  const handleExport = useCallback(
    data => {
      downloadCSV(
        data,
        exportTitle,
        h => allHeaders?.find(hh => hh.id === h).download || h
      );
    },
    [rows]
  );
  const methods = useForm<Record<string, any>, any, Record<string, any>>({
    defaultValues: { headers: [], all: false }
  });
  const handleSubmit = methods.handleSubmit(vals => {
    handleExport(
      getDownloadData().map(d =>
        vals.headers.reduce((a, b) => ({ ...a, [b]: d[b] }), {})
      )
    );
    setShow(false);
  });
  return (
    <ResponsiveModal wide show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <FormProvider {...methods}>
          <WizardInput
            name="headers"
            type="checkbox"
            multiple
            pluginProps={{
              colProps: {
                className: 'py-2'
              }
            }}
            formControlProps={{ rows: 10 }}
            options={allHeaders?.map(d => ({
              label: d.download,
              value: d.id
            }))}
            label="Select the fields to export"
            instruction="For data security reasons, it's always best to download only the minimum that you need"
          />
          <div className="mt-4 bg-light pb-2 pt-3 px-1">
            <WizardInput
              name="all"
              type="checkbox"
              label="Select all"
              registerProps={{
                required: false,
                onChange: e => {
                  methods.setValue(
                    'headers',
                    e.target.value ? allHeaders?.map(d => d.id) : []
                  );
                }
              }}
            />
          </div>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Export</Button>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
ExportModal.propTypes = {
  rows: PropTypes.array,
  exportTitle: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  exportAllData: PropTypes.bool
};
const DataExporter = ({ fileName }) => {
  const { getFilteredRowModel, title, dataId } = useAdvanceTable();
  const exportTitle = useMemo(
    () =>
      (fileName || title) +
      ' data ' +
      format(new Date(), 'dd-MM-yyyy HH-mm') +
      '.csv',
    [title, fileName]
  );
  const [rows, setRows] = useState<any[]>();
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        variant="falcon-default"
        data-tour="table-data-exporter"
        size="sm"
        disabled={rows?.length === 0}
        onClick={() => {
          setShow(true);
          setRows(getFilteredRowModel().rows);
        }}
      >
        <FontAwesomeIcon icon="external-link-alt" transform="shrink-3" />
        <span className="ms-1 d-none d-md-inline">Export</span>
      </Button>
      <ExportModal
        show={show}
        setShow={setShow}
        exportTitle={exportTitle}
        rows={rows}
      />
    </>
  );
};
DataExporter.propTypes = {
  fileName: PropTypes.string
};
const NewRecordButton = ({ onClick }) => {
  return (
    <>
      <Button
        variant="falcon-default"
        size="sm"
        className="tour-btn-new-record"
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        <FontAwesomeIcon icon="plus" transform="shrink-3" />
        <span className="ms-1 d-none d-md-inline">New</span>
      </Button>
    </>
  );
};
NewRecordButton.propTypes = {
  onClick: PropTypes.func
};
const FilterButton = ({
  remoteFilters = null,
  filterConfig
}: {
  remoteFilters?: any;
  filterConfig?: { filterable: string[]; notFilterable: string[] };
}) => {
  const [filterShow, setFilterShow] = useState(false);
  const { filters = [] } = useAdvanceTable();
  return (
    <>
      <Button
        variant={
          filters.filter(f => f.value?.active).length === 0
            ? 'falcon-default'
            : 'primary'
        }
        size="sm"
        className="tour-btn-filter"
        onClick={() => setFilterShow(true)}
      >
        <FontAwesomeIcon icon="filter" transform="shrink-3" />
        <span className="ms-1 d-none d-md-inline">Filter</span>
      </Button>
      {remoteFilters ? (
        <AdvanceTableCrudFilters
          filterShow={filterShow}
          setFilterShow={setFilterShow}
          remoteFilters={remoteFilters}
        />
      ) : (
        <AdvanceTableFilters
          filterShow={filterShow}
          setFilterShow={setFilterShow}
        />
      )}
    </>
  );
};
const TableActions = () => {
  const { getTableActions } = useAdvanceTable();
  const actions = getTableActions();
  return (
    !!actions?.length && (
      <ActionButtons actions={getTableActions()} maxShown={0}>
        <Dropdown.Toggle
          variant="falcon-default"
          size="sm"
          data-boundary="viewport"
          className={classNames('text-600 dropdown-caret-none')}
        >
          <FontAwesomeIcon icon={faEllipsisH} className="fs--2" />
        </Dropdown.Toggle>
      </ActionButtons>
    )
  );
};
const TableSettings = () => {
  const { getTableSettings } = useAdvanceTable();
  const settings = getTableSettings();
  return <SettingsDropdown settings={settings} />;
};
const TableHeader = ({
  autoFocus,
  exportConfig,
  newRecordConfig = { onClick: null },
  filterConfig,
  bulkActions = [],
  setEditing,
  edit,
  remoteFilters = null,
  onReview
}: {
  autoFocus?: boolean;
  exportConfig?: any;
  newRecordConfig?: any;
  filterConfig?: { filterable: string[]; notFilterable: string[] };
  bulkActions?: BulkAction<any>[];
  setEditing?: any;
  edit?: any;
  remoteFilters?: { filters: CustomRule; isLoading: boolean };
  onReview?: () => void;
}) => {
  const {
    getFilteredSelectedRowModel,
    toggleAllRowsSelected,
    setGlobalFilter,
    globalFilter,
    getOnNewClick,
    resetRowSelection,
    isLoading,
    setPagination,
    pagination,
    getHeaderGroups,
    getPrePaginationRowModel
  } = useAdvanceTable();
  const onNewClick = getOnNewClick();
  if (onNewClick) {
    newRecordConfig.onClick = onNewClick;
  }
  const bulkActionsWithDefaults = useMemo(() => {
    const defaults = [];
    if (edit && !bulkActions.find(a => a.isEdit)) {
      defaults.push({
        name: 'Edit',
        icon: faEdit,
        isEdit: true
      });
    }
    return bulkActions.concat(defaults);
  }, [bulkActions, edit]);
  const [applying, startApply] = useTransition();
  const [actionModal, setActionModal] = useState<BulkAction<any>>();
  function handleBulkApply(index) {
    const selectedRows = getFilteredSelectedRowModel().rows;
    startApply(() => {
      const action = bulkActionsWithDefaults[index];
      if (action) {
        if (action.isEdit) {
          // console.log('setting bulk editing', selectedRows);
          setEditing(selectedRows);
        }
        if (action.modalOnClick) {
          return setActionModal(action);
        }
        const confirm =
          typeof action.confirm === 'function'
            ? action.confirm(selectedRows)
            : typeof action.confirm === 'string' && action.confirm;
        if (confirm) {
          return setShowConfirm({
            text:
              typeof confirm === 'string'
                ? confirm
                : 'Perform the selected action on ' +
                  selectedRows.length +
                  ' rows?',
            onConfirm: done => {
              action.actionFn?.(selectedRows, () => {
                console.log('done in header');
                done();
                resetRowSelection();
              });
            }
          });
        }
        action.actionFn?.(selectedRows, () => {
          resetRowSelection();
        });
      }
    });
  }
  const [showConfirm, setShowConfirm] = useState<{
    text: string | boolean;
    onConfirm: (done: () => void) => void;
  }>();

  //this creates a max recursion error during data loading for some reason
  // const selected = useMemo(
  //   () =>
  //     !isLoading &&
  //     !!dataId &&
  //     (getIsSomeRowsSelected() || getIsAllRowsSelected()),
  //   [isLoading, dataId, getIsSomeRowsSelected()]
  // );
  const selected = getFilteredSelectedRowModel().rows;

  return (
    !isLoading && (
      <>
        <Row className="flex-end-center flex-lg-nowrap gy-2 mx-0">
          {selected?.length ? (
            <Col xs="auto" className="d-flex gap-2">
              <>
                <ModalConfirm
                  body={showConfirm?.text}
                  onConfirm={showConfirm?.onConfirm}
                  extShow={!!showConfirm}
                  onHide={() => setShowConfirm(null)}
                />
                {actionModal && (
                  <ResponsiveModal show onHide={() => setActionModal(null)}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {callIfFunction(actionModal.name, selected)}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {actionModal.modalOnClick({
                        hide: () => setActionModal(null),
                        rows: selected
                      })}
                    </Modal.Body>
                  </ResponsiveModal>
                )}
                {bulkActionsWithDefaults.length > 0 && (
                  <Form.Group>
                    {applying ? (
                      <Skeleton />
                    ) : (
                      <Form.Select
                        className={'w-auto'}
                        size="sm"
                        aria-label="Bulk actions"
                        onChange={e => handleBulkApply(e.target.value)}
                      >
                        <option value="">Bulk Actions</option>
                        {bulkActionsWithDefaults
                          // .filter(
                          //   a =>
                          //     a.show === undefined ||
                          //     callIfFunction(a.show, selected)
                          // )
                          .map((a, i) => (
                            <option
                              key={i}
                              value={i}
                              className={classNames({
                                'd-none':
                                  callIfFunction(a.show, selected) === false
                              })}
                            >
                              {callIfFunction(a.name, selected)}
                            </option>
                          ))}
                      </Form.Select>
                    )}
                  </Form.Group>
                )}

                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon={faTimes}
                  transform="shrink-3"
                  onClick={() => toggleAllRowsSelected(false)}
                >
                  <span className="ms-1">Clear</span>
                </IconButton>
              </>
            </Col>
          ) : (
            <>
              {/* {!remoteFilters && ( */}
              <Col xs={12} lg={'auto'} className="px-0 me-lg-3">
                <AdvanceTableSearchBox
                  className={'w-auto'}
                  formControlProps={{
                    autoFocus: autoFocus,
                    onInput: () => {
                      if (pagination.pageIndex > 0) {
                        setPagination(p => ({ ...p, pageIndex: 0 }));
                      }
                    }
                  }}
                  onChange={val => {
                    setGlobalFilter(val);
                  }}
                  value={globalFilter}
                />
              </Col>
              {/* )} */}
              {/* <Col xs={'auto'} sm={3} md={4} xl={3} className="ps-0 ps-sm-2 px-0">
              <SortButton />
            </Col> */}
              <Col
                xs={12}
                lg={'auto'}
                className="d-inline-flex flex-end-center px-0 gap-2"
              >
                <ButtonGroup className="flex-1">
                  {newRecordConfig?.onClick && (
                    <NewRecordButton {...newRecordConfig} />
                  )}
                  <FilterButton
                    {...filterConfig}
                    remoteFilters={remoteFilters}
                  />
                  <Button variant="falcon-default" size="sm" onClick={onReview}>
                    <FontAwesomeIcon icon="eye" transform="shrink-3" />
                    <span className="ms-1 d-none d-md-inline">Review</span>
                  </Button>
                  <DataExporter {...exportConfig} />
                  <TableActions />
                </ButtonGroup>
                <TableSettings />
              </Col>
            </>
          )}
        </Row>
      </>
    )
  );
};

TableHeader.propTypes = {
  autoFocus: PropTypes.bool,
  exportConfig: PropTypes.object,
  newRecordConfig: PropTypes.object,
  filterConfig: PropTypes.object,
  bulkActions: PropTypes.array,
  setEditing: PropTypes.func,
  edit: PropTypes.bool
};
const AdvanceTableHeader = ({
  autoFocus,
  isFetching,
  bulkActions,
  setEditing,
  edit,
  remoteFilters = null
}) => {
  const { title, domain, startReview } = useAdvanceTable();

  return (
    <FalconCardHeader
      // className="sticky-top"
      breakPoint="xl"
      title={
        <div className="d-flex align-items-center">
          {domain && <DomainIcon className="me-2" domain={domain} />} {title}
        </div>
      }
      light
    >
      <TableHeader
        setEditing={setEditing}
        autoFocus={autoFocus}
        bulkActions={bulkActions}
        edit={edit}
        remoteFilters={remoteFilters}
        onReview={startReview}
      />
      <div className={'fs--2 p-2 text-primary position-absolute top-0 start-0'}>
        {isFetching && (
          <Flex alignItems={'center'} direction={'column'}>
            <Spinner animation="grow" size="sm" />
          </Flex>
        )}
      </div>
    </FalconCardHeader>
  );
};
AdvanceTableHeader.propTypes = {
  autoFocus: PropTypes.bool,
  isFetching: PropTypes.bool,
  bulkActions: PropTypes.array,
  setEditing: PropTypes.func,
  edit: PropTypes.bool
};

export default AdvanceTableHeader;
