import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import {
  faComment,
  faEnvelope,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import { OverlayTrigger } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import blankUser from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import usePublicUsers from '../hooks/usePublicUsers';

const UserMenu = ({ user }) => {
  const width = 60;
  const Button = ({ i, ...props }) => (
    <motion.div
      initial={{ x: -width * i }}
      animate={{ x: 0 }}
      exit={{ x: -width * i }}
      transition={transitions.lightBounce}
      className="position-relative"
    >
      <IconButton
        size="sm"
        variant="falcon-default"
        className="w-100"
        {...props}
      />
    </motion.div>
  );
  Button.propTypes = {
    i: PropTypes.number
  };
  return (
    <div className="bg-100 position-absolute shadow-show-xl">
      <Flex className={'gap-1'}>
        <Button i={0} icon={faUser} />
        <Button i={1} icon={faEnvelope} onClick={() => console.log(user)} />
        <Button i={2} icon={faPhone} />
        <Button i={3} icon={faComment} />
      </Flex>
    </div>
  );
};
UserMenu.propTypes = {
  user: PropTypes.object
};
const UserIcon = ({
  id = null,
  size = 's',
  className = '',
  showName = false,
  hideJobTitle = false,
  nameClass = null,
  showIcon = true,
  ...rest
}) => {
  const { data: user, isLoading } = usePublicUsers({
    id,
    select: d => d[0]
  });
  const avatarSrc = user?.avatar;
  return (
    <motion.div
      whileHover={'hover'}
      className="position-relative d-inline-block"
    >
      <OverlayTrigger
        placement="bottom-start"
        offset={[0, 0]}
        overlay={
          user ? (
            <></>
          ) : (
            // <Popover className="position-absolute">
            //   <UserMenu user={user} />
            // </Popover>
            <></>
          )
        }
        trigger="click"
        rootClose
      >
        <div
          className={classNames(
            'cursor-pointer',
            'd-flex align-items-center',
            className
          )}
          {...rest}
        >
          {showIcon && (
            <Avatar
              src={avatarSrc || blankUser}
              className={`status-offline me-2`}
              mediaClass={'border border-3 rounded-circle border-200 bg-100'}
              isLoading={id && isLoading}
              size={size}
            />
          )}
          {user && (
            <>
              {showName ? (
                <motion.div
                  initial={{ x: -50, maxWidth: 'auto' }}
                  animate={{ x: 0 }}
                  className="text-start"
                >
                  <h6
                    className={
                      nameClass ||
                      classNames(
                        { 'fs--2': size === 's' },
                        'mb-0',
                        'text-700',
                        'fw-semi-bold'
                      )
                    }
                  >
                    {user?.firstName + ' ' + user?.surname}
                  </h6>
                  {!hideJobTitle && (
                    <motion.div
                      transition={transitions.lightBounce}
                      className="overflow-hidden text-nowrap d-block"
                      variants={{ hover: { height: 16 } }}
                      initial={{ height: 0 }}
                    >
                      <p className="fs--2 lh-1 mb-0 pt-1 text-700">
                        {user?.jobTitle}
                      </p>
                    </motion.div>
                  )}
                </motion.div>
              ) : (
                <motion.div
                  className="overflow-hidden text-nowrap text-start"
                  variants={{
                    hover: { maxWidth: '200px', marginLeft: '0.5rem' }
                  }}
                  initial={{ x: -50, maxWidth: '0px', marginLeft: 0 }}
                  animate={{ x: 0, maxWidth: '0px', marginLeft: 0 }}
                >
                  <h6
                    className={
                      nameClass ||
                      classNames(
                        { 'fs--2': size === 's' },
                        'mb-0',
                        'text-700',
                        'fw-semi-bold'
                      )
                    }
                  >
                    {user?.firstName + ' ' + user?.surname}
                  </h6>
                  <p className="fs--2 lh-1 mb-0 pt-1 text-700">
                    {user?.jobTitle}
                  </p>
                </motion.div>
              )}
            </>
          )}
        </div>
      </OverlayTrigger>
    </motion.div>
  );
};
UserIcon.propTypes = {
  id: PropTypes.number.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  showName: PropTypes.bool,
  hideJobTitle: PropTypes.bool,
  nameClass: PropTypes.string,
  showIcon: PropTypes.bool,
  employeeId: PropTypes.number
};
export default UserIcon;
