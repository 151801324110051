// import { NavContext } from 'context/Context';
// import React, { useContext, useEffect, useState } from 'react';
// import { useBlocker, useNavigate } from 'react-router-dom';

// const useNavWhenHook = (
//   when = true,
//   afterPrompt: (confirmed?: boolean) => void = () => {},
//   setIngored: (isIgnored: boolean) => void
// ) => {
//   const navigate = useNavigate();
//   const navBlocker = useBlocker(when);
//   // console.log('navblocker', navBlocker, when);
//   useEffect(() => {
//     console.trace('should block?', navBlocker.state === 'blocked');
//     if (navBlocker.state === 'blocked') {
//       if (
//         confirm(
//           'You may have unsaved data on this page. Are you sure you want to leave?'
//         )
//       ) {
//         console.log('ignoring block');
//         afterPrompt(true);
//         setIngored?.(true);
//         return navBlocker.proceed();
//       }
//       setIngored?.(false);
//       // console.log('resetting block');
//       navBlocker?.reset?.();
//       afterPrompt(false);
//     }
//   }, [navBlocker, when]);
//   useEffect(() => {
//     // console.log('should reset?', navBlocker.state === 'blocked', !when);
//     if (navBlocker.state === 'blocked' && when) {
//       navBlocker?.reset?.();
//     }
//   }, [navBlocker, when]);
//   useEffect(() => {
//     // console.log('checking when. Should allow?', !when);
//     if (when === false) {
//       window.onbeforeunload = () => true;
//     } else {
//       window.onbeforeunload = undefined;
//     }
//     return () => (window.onbeforeunload = undefined);
//   }, [when]);
//   useEffect(() => {
//     return () => {
//       console.log('unloading, resetting block');
//       navBlocker?.reset?.();
//     };
//   }, []);
//   return navigate;
// };
// export const NavProvider = ({ children }) => {
//   const [shouldBlock, setShouldBlock] = useState(false);
//   const [afterPrompt, setAfterPrompt] = useState(() => () => {});
//   const [shouldIgnore, setShouldIgnore] = useState(false);
//   const nav = useNavWhenHook(shouldBlock, afterPrompt, setShouldIgnore);
//   useEffect(() => {
//     if (shouldIgnore) {
//       setShouldBlock(false);
//     }
//   }, [shouldIgnore]);
//   return (
//     <NavContext.Provider
//       value={{ nav, shouldBlock, setShouldBlock, setAfterPrompt }}
//     >
//       {children}
//     </NavContext.Provider>
//   );
// };

// export default (
//   shouldAllow: boolean,
//   afterPrompt: (confirmed?: boolean) => void
// ) => {
//   const hook = useContext(NavContext);
//   const { setShouldBlock } = hook;
//   useEffect(() => {
//     console.trace('setting shouldAllow', shouldAllow);
//     if (shouldAllow) {
//       setShouldBlock(false);
//     } else {
//       setShouldBlock(true);
//     }
//     return () => setShouldBlock(false);
//   }, [shouldAllow]);
//   useEffect(() => {
//     if (afterPrompt) {
//       hook.setAfterPrompt(() => afterPrompt);
//     } else {
//       hook.setAfterPrompt(() => () => {});
//     }
//     return () => hook.setAfterPrompt(() => () => {});
//   }, [afterPrompt]);
//   return hook;
// };

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  useBlocker,
  useNavigate,
  Blocker,
  BlockerFunction,
  NavigateFunction
} from 'react-router-dom';

interface NavigationBlockerContextType {
  useNavWhen: (
    shouldBlock: boolean,
    onProceed?: () => void
  ) => NavigateFunction;
  setShouldBlock: (shouldBlock: boolean) => void;
}

const NavigationBlockerContext = createContext<
  NavigationBlockerContextType | undefined
>(undefined);

export const NavigationBlockerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [shouldBlock, setShouldBlock] = useState(false);
  const [onProceedCallback, setOnProceedCallback] = useState<
    (() => void) | null
  >(null);

  const blocker: BlockerFunction = ({ currentLocation, nextLocation }) => {
    if (!shouldBlock) return false;

    const userConfirmed = window.confirm(
      'Are you sure you want to leave? Unsaved changes may be lost.'
    );

    if (userConfirmed) {
      setShouldBlock(false);
      return false; // Allow navigation
    }
    onProceedCallback?.();

    console.log('blocker after confirm', currentLocation, nextLocation, {
      shouldBlock,
      userConfirmed
    });
    // return true
    return currentLocation.pathname !== nextLocation.pathname; // Block if still different
  };

  useBlocker(blocker);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log('handling before unload', { shouldBlock });
      if (shouldBlock) {
        event.preventDefault();
        // event.returnValue =
        //   'Are you sure you want to leave? Unsaved changes may be lost.';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldBlock]);

  const navigate = useNavigate();
  const useNavWhen = useCallback(
    (shouldAllow: boolean, onProceed?: () => void) => {
      console.log('setting shouldBlock in useNavWhen', !shouldAllow);
      setShouldBlock(!shouldAllow);
      setOnProceedCallback(() => onProceed || null);
      return navigate;
    },
    []
  );

  return (
    <NavigationBlockerContext.Provider value={{ useNavWhen, setShouldBlock }}>
      {children}
    </NavigationBlockerContext.Provider>
  );
};

export const useNavigationBlocker = () => {
  const context = useContext(NavigationBlockerContext);
  if (!context) {
    throw new Error(
      'useNavigationBlocker must be used within a NavigationBlockerProvider'
    );
  }
  return context;
};
