import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import AdvanceTableProvider, {
  Action,
  AdvanceTableProviderProps,
  BulkAction,
  Column,
  ColumnObject,
  useAdvanceTable
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import React, { useCallback, useMemo, useState } from 'react';
import {
  CrudFilters,
  DefaultCrudData,
  defaultCrudHookBuilder,
  getCrudFilterFields,
  mergeCrudProps,
  mergeFilters,
  UseCrudProps
} from 'hooks/defaultCrud/useDefaultCrud';
import AdvanceEditor, {
  AdvanceEditorProps
} from 'components/common/advance-table-v2/AdvanceEditor';
import { domainConfigs } from 'components/notification/config';
import { DomainConfig } from 'config';
import { EventPrefix } from 'apis/flex/notifications';
import { useNavigate } from 'react-router-dom';
import {
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faCopy,
  faEdit,
  faFolder,
  faFolderOpen,
  faList,
  faListAlt,
  faQuestionCircle,
  faSyncAlt,
  faTags,
  faTimesCircle,
  faTrash,
  faTrashRestore
} from '@fortawesome/free-solid-svg-icons';
import { domainToSentence } from './DomainTimeline';
import { TabItem } from './advance-table-v2/AdvanceTableTabProvider';
import CrudTableProvider, {
  CrudTableProps,
  CrudTabNavItem
} from './advance-table-v2/CrudTableProvider';
import { getDomainHome, getDomainItemUrl } from 'hooks/useDomainRouter';
import { Row } from '@tanstack/react-table';
import CustomTabs from './CustomTabs';
import { ApiField } from 'apis/types';
import classNames from 'classnames';
import { useGuard } from 'hooks/useGuard';
import _, { uniq } from 'lodash';
import DomainTags, { AddDomainTag, RemoveDomainTag } from './DomainTags';
import TagSelector, { Tags } from './TagSelector';
import { callIfFunction, camelToSentence } from 'helpers/utils';
import { useUser } from 'hooks/useUser';
import { TabItem as CustomTabItem } from 'components/common/CustomTabs';
import DomainCustomTags from './DomainCustomTags';

export type DomainTab<
  TData extends DefaultCrudData,
  TAccessed extends TData = TData
> = Omit<TabItem<TData, TAccessed>, 'crudFilter'> & {
  crudFilter?: CrudFilters<TAccessed>;
  state?: string | string[];
};
type DomainTableBaseProps<
  TData extends DefaultCrudData,
  TFormData = TData,
  TAccessed extends TData = TData
> = {
  tabs?: DomainTab<TData, TAccessed>[];
  editFields?: (keyof TFormData & string)[];
  bulkEditFields?: (keyof TFormData & string)[];
  domain: EventPrefix;
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: Partial<UseCrudProps<TData, TData[]>>;
  editorProps?: Partial<AdvanceEditorProps<TData, TFormData>>;
  /** @deprecated use @param globalActions instead */
  actions?: Action<TAccessed>[] | ((data: TAccessed[]) => Action<TAccessed>[]);
  /** @deprecated use @param globalActions instead */
  bulkActions?:
    | BulkAction<TAccessed>[]
    | ((data: TAccessed[]) => BulkAction<TAccessed>[]);
  globalActions?:
    | BulkAction<TAccessed>[]
    | ((data: TAccessed[]) => BulkAction<TAccessed>[]);
  selectAll?: boolean;
  allColumns?: boolean;
  isSelf?: boolean | ((data: TAccessed) => boolean);
  selfAdd?: boolean;
  canClone?: boolean;
  canDelete?: boolean;
  stateTabs?:
    | boolean
    | string[]
    | ((state: string) => Partial<DomainTab<TData, TAccessed>>);
  blockUrlState?: boolean;
};
type DomainTableColumns<TData extends DefaultCrudData> =
  | {
      columns: Column<TData>[];
    }
  | {
      columns?: never;
      allColumns: boolean;
    };
export type DomainTableProps<
  TData extends DefaultCrudData = DefaultCrudData,
  TFormData = TData,
  TAccessed extends TData = TData
> = DomainTableBaseProps<TData, TFormData, TAccessed> &
  DomainTableColumns<TData> &
  Partial<
    Omit<
      AdvanceTableProviderProps<TData, TAccessed>,
      'tabs' | 'remoteData' | 'actions' | 'bulkActions' | 'columns'
    >
  >;

export type RemoteDomainTableProps<
  TData extends DefaultCrudData,
  TFormData,
  TAccessed extends TData = TData
> = Omit<DomainTableBaseProps<TData, TFormData, TAccessed>, 'columns'> &
  Omit<
    CrudTableProps<TData>,
    'tabs' | 'remoteData' | 'actions' | 'bulkActions'
  >;

export const RemoteDomainTable = <
  TData extends DefaultCrudData = any,
  TFormData = TData
>({
  domain,
  crudHook,
  editFields,
  crudProps,
  editorProps,
  tabs: _tabs,
  // remote,
  actions,
  bulkActions,
  isLoading,
  selectAll,
  ...props
}: RemoteDomainTableProps<TData, TFormData>) => {
  const tableProps = useDomainTableProps<TData>({
    ...props,
    domain,
    actions,
    bulkActions,
    crudHook,
    crudProps,
    selectAll,
    columns: props.columns,
    isLoading,
    editFields,
    tabs: _tabs
  });
  return (
    <CrudTableProvider<TData>
      isLoading={isLoading}
      {...tableProps.staticProps}
      {...tableProps.dynamicProps}
      {...props}
      crudHook={tableProps.staticProps.crudHook}
    >
      <AdvanceTable
        emptyPlaceholder={`No ${domainToSentence(domain).toLowerCase()}s here`}
      />
      {editFields && (
        <AdvanceEditor
          domain={domain as EventPrefix}
          crudHook={crudHook}
          fields={editFields}
          {...editorProps}
        />
      )}
    </CrudTableProvider>
  );
};
const stateIconLookup = (state: string) => {
  switch (state) {
    case 'active':
      return faCheckCircle;
    case 'open':
      return faFolderOpen;
    case 'closed':
      return faFolder;
    case 'inactive':
      return faTimesCircle;
    case 'draft':
      return faEdit;
    case 'deleted':
      return faTrash;
    case 'others':
      return faQuestionCircle;
    case 'all':
      return faList;
    default:
      return undefined;
  }
};
const useDomainTableProps = <
  TData extends DefaultCrudData = DefaultCrudData,
  TAccessed extends TData = TData
>({
  columns,
  domain,
  actions = [],
  bulkActions = [],
  crudHook: passedCrudHook,
  crudProps: passedCrudProps,
  selectAll,
  isLoading,
  editFields,
  editable,
  tableSettings,
  bulkEditFields,
  allColumns,
  isSelf,
  selfAdd,
  canClone = true,
  canDelete = true,
  stateTabs,
  tabs,
  refreshTrigger,
  onNewClick,
  onRowClick,
  title,
  blockUrlState
}: Partial<DomainTableProps<TData, any, TAccessed>>): Partial<
  Record<
    'dynamicProps' | 'staticProps',
    Partial<CrudTableProps<TData, TAccessed>>
  >
> => {
  const config = domainConfigs[domain] as DomainConfig<TData>;
  const crudHook = useMemo(
    () => passedCrudHook ?? config.crudHook,
    [config, passedCrudHook]
  );
  const { canEdit, canCreate } = useGuard({ roles: [domain] });
  const nav = useNavigate();
  const user = useUser();

  const handleEdit = useCallback(
    (row: Row<TAccessed>) => {
      nav(
        getDomainItemUrl(domain, user, row.original) ||
          getDomainHome(domain, user, row.original) +
            '/' +
            row.original.id.toString()
      );
    },
    [domain]
  );
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const crudProps: Partial<UseCrudProps<TData, TData[]>> = useMemo(
    () =>
      mergeCrudProps(
        {
          includeDeleted,
          useFilter: true,
          asList: !columns && !selectAll,
          enabled:
            !isLoading &&
            (!passedCrudProps || passedCrudProps?.enabled !== false),
          columns:
            selectAll || allColumns
              ? undefined
              : columns
              ? uniq(
                  columns
                    .map(c => (typeof c === 'string' ? c : c.id))
                    .concat(
                      getCrudFilterFields(passedCrudProps?.filters).map(
                        c => c.split('.')[0] as ApiField<TData>
                      )
                    )
                    .concat(
                      passedCrudProps?.customFilter?.flatMap(f =>
                        Object.keys(
                          f.map(
                            ff => ff.question.split('.')[0] as ApiField<TData>
                          )
                        )
                      )
                    )
                    .concat(editFields || [])
                    .concat(includeDeleted ? ['deletedBy', 'deletedDate'] : [])
                    .filter(Boolean) as ApiField<TData>[]
                )
              : // .map(c => c.split('.')[0] as ApiField<TData>)
                undefined
        },
        passedCrudProps
      ),
    [columns, passedCrudProps, includeDeleted]
  );
  const {
    cloneAsync,
    removeAsync,
    bulkRemoveAsync,
    queryError,
    bulkRestoreAsync,
    meta
  } = crudHook({
    noReturnOnChange: crudProps.noReturnOnChange,
    afterSave: crudProps.afterSave,
    invalidate: crudProps.invalidate,
    data: false
  });
  const staticProps = useMemo(() => {
    const bulkActionsRendered = data => {
      const ac = canEdit ? callIfFunction(bulkActions, data) || [] : [];
      // console.log('bulkActionsRendered', data, actions);
      return ac;
    };
    const getActions = data => {
      return [
        {
          name: 'Edit',
          onClick: editFields ? undefined : handleEdit,
          icon: faEdit,
          isEdit: true,
          show: canEdit
        },
        {
          name: 'Duplicate',
          onClick: row => cloneAsync(row.original.id),
          icon: faCopy,
          show: canCreate && canClone
        },
        {
          name: r => (r.original.deletedDate ? 'Restore' : 'Delete'),
          variant: 'danger',
          show: canCreate && canDelete,
          confirm: r =>
            (r.original.deletedDate ? 'Restore' : 'Delete') +
            ' this ' +
            domainToSentence(domain) +
            '?',
          onClick: (row, done) => {
            if (row.original.deletedDate) {
              bulkRestoreAsync(
                {
                  first: 'id',
                  second: row.original.id
                },
                { onSuccess: done }
              );
            } else {
              removeAsync(row.original.id, { onSuccess: done });
            }
          },
          icon: r => (r.original.deletedDate ? faTrashRestore : faTrash)
        },
        ...(canEdit
          ? typeof actions === 'function'
            ? actions(data)
            : actions
          : [])
      ];
    };
    return {
      tabs:
        tabs ||
        (stateTabs
          ? meta?.states
              ?.filter(
                s =>
                  typeof stateTabs === 'boolean' ||
                  typeof stateTabs === 'function' ||
                  (typeof stateTabs !== 'function' && stateTabs.includes(s))
              )
              //sort to match 'stateTabs' order, if it's a string array
              .sort((a, b) =>
                typeof stateTabs === 'boolean' ||
                typeof stateTabs === 'function'
                  ? 0
                  : stateTabs.indexOf(a) - stateTabs.indexOf(b)
              )
              .map<DomainTab<TData, TAccessed>>(s => ({
                label: camelToSentence(s),
                state: s,
                icon: stateIconLookup(s),
                ...(typeof stateTabs === 'function' &&
                  callIfFunction(stateTabs, s))
              }))
              .concat([
                {
                  label: 'Others',
                  state: null,
                  icon: stateIconLookup('others'),
                  ...(typeof stateTabs === 'function' &&
                    callIfFunction(stateTabs, null))
                },
                {
                  label: 'All',
                  icon: stateIconLookup('all'),
                  state: undefined
                }
              ])
          : undefined),
      title: title || domainToSentence(domain) + 's',
      autoFocus: true,
      sqlDb: config?.sqlDb,
      sqlTables: [config.sqlTable],
      domain,
      readOnly: !canEdit,
      tableSettings: meta?.columns.find(c => c.COLUMN_NAME === 'deletedBy')
        ? [
            {
              label: 'Show deleted',
              value: includeDeleted,
              onChange: b => {
                setIncludeDeleted(b);
              }
            },
            ...(tableSettings || [])
          ]
        : tableSettings,
      columns: [
        // {
        //   id: 'isActive',
        //   header: '',
        //   maxSize: 30,
        //   formatter: v => {
        //     return v() === undefined ? (
        //       <></>
        //     ) : (
        //       <div
        //         className={classNames(
        //           `bg-${v() ? 'success' : 'secondary'}`,
        //           'rounded-circle'
        //         )}
        //       />
        //     );
        //   }
        // } as Column<TData> | ApiField<TData>
      ]
        .concat(
          (allColumns
            ? (meta?.columns || []).map(c => c.COLUMN_NAME as ApiField<TData>)
            : columns || []
          ).filter(
            c =>
              (typeof c === 'string' && c !== 'tags') ||
              (typeof c === 'object' && c?.id !== 'tags')
          )
        )
        .concat(
          meta?.columns.find(
            c => c.COLUMN_NAME.split('.')[0] === 'availabilityRules'
          )
            ? meta?.columns
                .filter(
                  c =>
                    c.COLUMN_NAME.split('.')[0] === 'availabilityRules' &&
                    !columns?.some(cc =>
                      typeof cc === 'string'
                        ? cc === c.COLUMN_NAME
                        : cc.id === c.COLUMN_NAME
                    )
                )
                .map(c => ({ id: c.COLUMN_NAME, visble: false }))
            : []
        )
        .concat(
          (config.tags || meta?.tags
            ? [
                {
                  id: 'tags',
                  header: 'Tags',
                  accessorFn: d => {
                    const tagIds = d.tags?.map(t => t.tagId) || [];
                    return tagIds;
                  },
                  filter: props => {
                    return (
                      <TagSelector
                        canCreate={false}
                        domain={domain}
                        label="Tags"
                        tableName={meta?.tagsTable}
                        {...props}
                      />
                    );
                  },
                  formatter: v => {
                    return <Tags ids={v()} />;
                  }
                } as ColumnObject<TData, 'tags', number[]>
              ]
            : []) as Column<TData>[]
        )
        .concat(
          includeDeleted
            ? ['deletedDate', { id: 'deletedBy', domain: 'user' }]
            : []
        ),
      crudProps,
      crudHook,
      editable: canEdit && (editable || !!editFields || !!bulkEditFields),
      globalSearchFields: config.searchFields as (keyof TData & string)[],
      actions: getActions,
      bulkActions: data => [
        {
          show: canCreate && canDelete,
          name: rows =>
            rows.every(r => !!r.original.deletedDate) ? 'Restore' : 'Delete',
          confirm: rows =>
            (rows.every(r => !!r.original.deletedDate) ? 'Restore' : 'Delete') +
            ' ' +
            rows.length +
            ' ' +
            domainToSentence(domain) +
            's?',
          actionFn: (rows, done) => {
            if (rows.every(r => !!r.original.deletedDate)) {
              return bulkRestoreAsync(
                {
                  first: 'id',
                  second: rows.map(r => r.original.id)
                },
                { onSuccess: done }
              );
            } else {
              return bulkRemoveAsync(
                rows.map(r => r.original.id),
                { onSuccess: done }
              );
            }
          },
          icon: rows =>
            rows.every(r => !!r.original.deletedDate) ? faTrashRestore : faTrash
        },
        ...(config.tags || meta?.tags
          ? [
              {
                name: 'Add tag',
                icon: faTags,
                show: canEdit,
                variant: 'success',
                modalOnClick: ({
                  hide,
                  rows
                }: {
                  hide: () => void;
                  rows: Row<TAccessed>[];
                }) => {
                  return (
                    <AddDomainTag
                      onFinished={hide}
                      domain={domain}
                      itemIds={rows.map(r => r.original.id)}
                    />
                  );
                }
              },
              {
                name: 'Remove tag',
                icon: faTags,
                show: canEdit,
                variant: 'danger',
                modalOnClick: ({
                  hide,
                  rows
                }: {
                  hide: () => void;
                  rows: Row<TAccessed>[];
                }) => {
                  return (
                    <RemoveDomainTag
                      onFinished={hide}
                      domain={domain}
                      itemIds={rows.map(r => r.original.id)}
                      defaultValues={_.uniq(
                        rows.flatMap(r => r.original.tags.map(t => t.tagId))
                      )}
                    />
                  );
                }
              }
            ]
          : []),
        ...bulkActionsRendered(data)
      ],
      onRowClick:
        onRowClick !== undefined
          ? onRowClick
          : editFields
          ? undefined
          : handleEdit,
      onNewClick:
        onNewClick !== undefined
          ? onNewClick
          : editFields || (!canCreate && !(isSelf && selfAdd))
          ? undefined
          : defaultValue => {
              if (defaultValue) {
                delete defaultValue.id;
              }
              // console.log(
              //   'onNewClick getdomainhome',
              //   domain,
              //   user,
              //   defaultValue,
              //   getDomainHome(domain, user, defaultValue)
              // );
              //need a defaultValue in case things like parentId are required to resolve domain home
              nav(getDomainHome(domain, user, defaultValue || {}) + '/new');
            },
      error: queryError,
      useUrlState: !blockUrlState
    };
  }, [
    domain,
    handleEdit,
    crudProps,
    editFields,
    includeDeleted,
    meta,
    user,
    isSelf,
    queryError
  ]);
  const dynamicProps = useMemo(
    () => ({
      refreshTrigger
    }),
    [refreshTrigger]
  );
  // console.log('staticProps', staticProps);
  return {
    dynamicProps,
    staticProps
  };
};
export const DomainTable = <
  TData extends DefaultCrudData = any,
  TFormData = TData,
  TAccessed extends TData = TData
>({
  domain,
  crudHook: passedCrudHook,
  editFields,
  crudProps: passedCrudProps,
  editorProps,
  tabs: _tabs,
  // remote,
  actions,
  bulkActions,
  isLoading: passedIsLoading,
  selectAll,
  stateTabs = true,
  ...props
}: DomainTableProps<TData, TFormData, TAccessed>) => {
  const {
    staticProps: { crudProps, crudHook, tabs, ...tableProps },
    dynamicProps
  } = useDomainTableProps<TData, TAccessed>({
    ...props,
    domain,
    crudHook: passedCrudHook,
    crudProps: passedCrudProps,
    selectAll,
    columns: props.columns,
    isLoading: passedIsLoading,
    editFields,
    tabs: _tabs,
    stateTabs
  });
  return passedIsLoading ? (
    <AdvanceTableProvider data={[]} columns={['id']} isLoading>
      <AdvanceTable
        emptyPlaceholder={`No ${domainToSentence(domain).toLowerCase()}s here`}
        isLoading
      />
    </AdvanceTableProvider>
  ) : (
    <CustomTabs
      alignment="top"
      showTabs={!!tabs?.length}
      useUrlState={props.useUrlState}
      items={(
        tabs || [
          {
            label: 'All',
            crudFilter: {}
          }
        ]
      ).map(
        (t): CustomTabItem => ({
          id: t.label.toLowerCase(),
          title: () => (
            <CrudTabNavItem
              label={t.label}
              crudHook={crudHook}
              crudProps={crudProps}
              filters={t.crudFilter}
              state={t.state}
              icon={t.icon}
            />
          ),
          content: (a, b, c, isActive) => (
            <DomainTableProvider
              domain={domain}
              tabs={tabs}
              editFields={editFields}
              crudHook={passedCrudHook}
              isLoading={passedIsLoading}
              selectAll={selectAll}
              editorProps={editorProps}
              {...dynamicProps}
              {...props}
              {...tableProps}
              {...t?.tableProps?.({
                ...props,
                ...tableProps,
                isLoading: passedIsLoading,
                editorProps,
                editFields
              })}
              crudProps={mergeCrudProps(
                crudProps,
                {
                  enabled: isActive,
                  state: t.state,
                  filters: mergeFilters(passedCrudProps?.filters, t.crudFilter)
                },
                t?.tableProps?.({
                  ...props,
                  ...tableProps,
                  isLoading: passedIsLoading,
                  editorProps,
                  editFields
                })?.crudProps
              )}
              actions={actions}
              bulkActions={bulkActions}
            >
              {props.children || (
                <AdvanceTable
                  emptyPlaceholder={`No ${domainToSentence(
                    domain
                  ).toLowerCase()}s here`}
                />
              )}
            </DomainTableProvider>
          )
        })
      )}
    />
  );
};
export const DomainTableProvider = <
  TData extends { id: number } = any,
  TFormData = TData,
  TAccessed extends TData = TData
>({
  domain,
  actions: passedActions,
  bulkActions: passedBulkActions,
  tabs: _tabs,
  crudHook: passedCrudHook,
  crudProps: passedCrudProps,
  isLoading: passedIsLoading,
  selectAll,
  editFields,
  editorProps,
  children,
  bulkEditFields,
  globalActions,
  ...props
}: DomainTableProps<TData, TFormData, TAccessed>) => {
  const {
    staticProps: { crudProps, crudHook, tabs, ...tableProps },
    dynamicProps
  } = useDomainTableProps<TData, TAccessed>({
    ...props,
    domain,
    actions: passedActions,
    bulkActions: passedBulkActions,
    crudHook: passedCrudHook,
    crudProps: passedCrudProps,
    selectAll,
    columns: props.columns,
    isLoading: passedIsLoading,
    editFields,
    tabs: _tabs
  });
  const {
    isLoading,
    data,
    queryError,
    refetch,
    isRefetching,
    refetchMeta,
    invalidateServerCache,
    isMetaRefetching,
    isInvalidating
  } = crudHook({
    ...crudProps,
    useFilter: true
  });
  return (
    <AdvanceTableProvider<TData, TAccessed>
      tableActions={[
        {
          label: 'Refresh',
          icon: faSyncAlt,
          fn: () => {
            console.log('refreshing');
            invalidateServerCache(null, {
              onSuccess: () => {
                refetch();
                refetchMeta();
              }
            });
          },
          loading: isRefetching || isMetaRefetching || isInvalidating
        }
      ]}
      {...dynamicProps}
      {...tableProps}
      {...props}
      domain={domain}
      globalActions={callIfFunction(globalActions, data)}
      tabs={tabs}
      data={data}
      error={queryError}
      isLoading={isLoading || passedIsLoading}
      columns={tableProps.columns}
    >
      <>
        <Children>{children}</Children>
        {(editFields || bulkEditFields) && (
          <AdvanceEditor
            domain={domain as EventPrefix}
            crudHook={crudHook}
            fields={editFields || bulkEditFields}
            {...editorProps}
          />
        )}
      </>
    </AdvanceTableProvider>
  );
};
const Children = ({ children }) => {
  const { getRowModel, isLoading } = useAdvanceTable();
  return (
    <>
      {typeof children === 'function'
        ? children({
            rows: getRowModel().rows,
            isLoading: isLoading
          })
        : children}
    </>
  );
};
export default DomainTable;
