import React, { useState } from 'react';
import { useFieldArray, useFormState, useWatch } from 'react-hook-form';
import {
  ResourceGroupSelector,
  SelectedResourceGroups
} from '../resourceGroups/ResourceGroupSelector';
import { ResponsiveModal } from 'components/common/Modals';
import {
  Button,
  Col,
  FormControl,
  FormLabel,
  Modal,
  Row
} from 'react-bootstrap';
import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import { TargetGroupResourceGroup } from 'apis/flex/projects';
import ShiftSelector from 'components/app/hr/shifts/ShiftSelector';
import Expandable from 'components/common/Expandable';
import WizardInput from 'components/wizard/WizardInput';
import { NamePrefix } from 'components/common/DomainInput';
import useTargetGroups from '../useTargetGroups';
import useResourceGroups from '../resourceGroups/useResourceGroups';
import SimpleLine, { SparkLine } from 'components/common/widgets/SimpleLine';
import DetailCard from 'components/common/detail/DetailCard';
import classNames from 'classnames';
import SettingsBox from 'components/common/SettingsBox';

const TargetGroupResourceGroups = ({ name }: { name: string }) => {
  const { fields, remove, append } = useFieldArray<
    { [key: typeof name]: TargetGroupResourceGroup[] },
    typeof name
  >({ name, rules: { required: true } });
  const targetGroupId = useWatch({ name: 'id' });
  const { errors } = useFormState();
  console.log('errors', errors);
  const hasErrors = Object.keys(errors).length;
  return (
    <SettingsBox
      title="Resource Groups"
      error={errors?.[name] ? 'Required' : ''}
      variant={hasErrors && (errors[name] ? 'danger' : 'success')}
    >
      <FieldArrayList
        fields={fields}
        maxFields={1}
        remove={remove}
        append={v => append(v)}
        defaultValues={{
          targetGroupId,
          minDailyUnits: 0,
          weekends: false,
          isPreferred: false,
          shiftIds: []
        }}
        item={(f, i) => <TGRGForm key={i} prefix={`${name}.${i}.`} />}
      />
    </SettingsBox>
  );
};
export default TargetGroupResourceGroups;
const applyShape = (
  total: number,
  slots: number,
  shape = 'linear',
  min = 0,
  max = Infinity
) => {
  const shapes = {
    linear: Array(slots).fill(1), // Equal distribution
    frontend: Array.from({ length: slots }, (_, i) => slots - i), // More in the first slots
    backend: Array.from({ length: slots }, (_, i) => i + 1), // More in the last slots
    bell: Array.from({ length: slots }, (_, i) => {
      const mid = (slots - 1) / 2;
      return 1 / Math.exp(Math.pow((i - mid) / mid, 2)); // Gaussian-like curve
    }),
    inverseBell: Array.from({ length: slots }, (_, i) => {
      const mid = (slots - 1) / 2;
      return Math.exp(Math.pow((i - mid) / mid, 2)); // Inverse Gaussian-like curve
    })
  };
  const weights = shapes[shape.toLowerCase()] || shapes['linear'];
  const totalWeight = weights.reduce(
    (sum: number, weight: number) => sum + weight,
    0
  );

  // Initialize slots with minimum values
  const distribution: number[] = Array(slots).fill(min);

  // Adjust weights to account for min values
  const availableTotal = total - min * slots;
  const adjustedWeights: number[] = weights.map(
    (weight: number) => weight / totalWeight
  );

  // Distribute the remaining total based on weights, respecting max
  adjustedWeights.forEach((weight, i) => {
    const allowedAmount = Math.min(max - min, availableTotal * weight);
    distribution[i] += allowedAmount;
  });

  // Handle any rounding errors to ensure exact total
  const roundingError =
    total - distribution.reduce((sum, value) => sum + value, 0);
  if (roundingError !== 0) {
    for (let i = 0; roundingError !== 0 && i < slots; i++) {
      const adjustment = Math.min(max - distribution[i], roundingError);
      distribution[i] += adjustment;
    }
  }

  return distribution;
};
export const ResourceShapeChart = ({
  shape,
  variant
}: {
  shape: 'linear' | 'frontend' | 'backend' | 'bell' | 'inverseBell';
  variant?: string;
}) => {
  return <SparkLine data={applyShape(100, 50, shape)} variant={variant} />;
};
const TGRGForm = ({ prefix }: { prefix: NamePrefix }) => {
  const resourceGroupId = useWatch({ name: `${prefix}resourceGroupId` });
  const unit = 'hours';
  return (
    <div>
      <ResourceGroupSelector
        name={`${prefix}resourceGroupId`}
        multiple={false}
      />
      <WizardInput
        type="radio"
        name={`${prefix}shape`}
        label="Resource shape"
        options={[
          {
            value: 'linear',
            label: 'Linear'
          },
          {
            value: 'frontend',
            label: 'Frontend',
            description: 'More resource earlier, less at the end'
          },
          {
            value: 'backend',
            label: 'Backend',
            description: 'More resource at the end, less at the beginning'
          },
          {
            value: 'bell',
            label: 'Bell',
            description:
              'Less resource at the beginning and the end, more in the middle'
          },
          {
            value: 'inverseBell',
            label: 'U-shape',
            description:
              'More resource at the beginning and the end, less in the middle'
          }
        ]}
        formControlProps={{
          rows: 2
        }}
        // formGroupProps={{ className: 'w-auto' }}
        pluginProps={{
          button: true,
          formatOptionLabel: ({ option, checked }) => (
            <div
              className={
                classNames()
                // 'd-flex align-items-center',
                // {
                //   'text-primary': checked,
                //   'border border-2 border-primary': checked
                // },
                // 'mb-2'
              }
            >
              {/* <span className={''}> */}
              <div className="d-flex justify-content-center">
                <ResourceShapeChart
                  variant={checked ? 'light' : 'primary'}
                  shape={
                    option.value as
                      | 'linear'
                      | 'frontend'
                      | 'backend'
                      | 'bell'
                      | 'inverseBell'
                  }
                />
              </div>
              {/* </span> */}
              <div>
                {option.label}
                {option.description && (
                  <div className="form-text">{option.description}</div>
                )}
              </div>
            </div>
          )
        }}
      />
      <Expandable>
        <Row>
          {unit && (
            <>
              <Col xs={12} md={6}>
                <WizardInput
                  type="number"
                  name={`${prefix}minDailyUnits`}
                  label={`Min daily ${unit}`}
                  registerProps={{ required: false }}
                />
              </Col>
              <Col xs={12} md={6}>
                <WizardInput
                  type="number"
                  name={`${prefix}maxDailyUnits`}
                  label={`Max daily ${unit}`}
                  registerProps={{ required: false }}
                />
              </Col>
              <Col xs={12} md={6}>
                <WizardInput
                  type="checkbox"
                  name={`${prefix}weekends`}
                  label={`Can be worked on weekends`}
                />
              </Col>
              <Col xs={12} md={6}>
                <WizardInput
                  type="number"
                  name={`${prefix}priority`}
                  registerProps={{ required: false }}
                  label="Priority"
                  instruction={`Priority among the other projects in the resource group (only relevant if resource is too low to finish all projects). The higher the number, the higher the priority.`}
                />
              </Col>
              {/* <Col xs={12} md={6}>
                <WizardInput
                  type="checkbox"
                  name={`${prefix}isPreferred`}
                  label={`Preferred`}
                  instruction={`The project would prefer to use this target group above the others`}
                />
              </Col> */}
            </>
          )}
        </Row>
      </Expandable>
    </div>
  );
};
