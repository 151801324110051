import React from 'react';
import useCampaigns from './campaigns/hooks/useCampaigns';
import DetailCard from 'components/common/detail/DetailCard';
import useJobRoles from '../hr/jobRoles/useJobRoles';
import ExpandableParagraph from 'components/common/ExpandableParagraph';
import { format } from 'date-fns';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublicLayout from 'layouts/PublicLayout';
import companyConfig from 'companyConfig';
import { parseUKCurrency } from 'helpers/strings';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import PageHeader from 'components/common/PageHeader';

export default () => {
  const { data: campaigns, isLoading } = useCampaigns({
    getPublic: true,
    meta: false,
    useFilter: true,
    state: 'open',
    select: c => c.filter(c => !c.maxApplicantsReached)
  });
  const { data: jobRoles, isLoading: isJobRolesLoading } = useJobRoles({
    getPublic: true,
    id: campaigns?.map(c => c.jobTitleId),
    meta: false
  });
  const roleLookup = new Map(jobRoles?.map(r => [r.id, r]));
  const loading = isLoading || isJobRolesLoading;
  const parsePay = (min: number, max: number) =>
    min
      ? parseUKCurrency(min) + (!max ? '+' : ' - ') + parseUKCurrency(max)
      : 'Up to ' + parseUKCurrency(max);
  return (
    <PublicLayout>
      <PageHeader
        className="mb-3"
        isLoading={loading}
        title="Jobs Board"
        description={
          'All active job listings for ' +
          companyConfig?.companyName +
          ' are listed below. Job requirements are changing all the time so check back again soon!'
        }
      />
      {!campaigns?.length && !loading && (
        <Card>
          {/* <Lottie animationData={anim} style={{height: 200}} loop={true} /> */}
          <Card.Body className="text-center text-muted">
            There are no active job listings at the moment. Check back later!
          </Card.Body>
        </Card>
      )}
      {campaigns?.map(c => (
        <DetailCard
          headerProps={{
            className: 'top-0'
          }}
          key={c.id}
          footer={
            <>Published: {format(new Date(c.createdDate), 'dd/MM/yyyy')}</>
          }
          id={c.id.toString()}
          title={c.advertTitle || c.jobTitle}
        >
          <div className="h6 fs--1 text-500">
            <p>
              <b>Job title:</b> {c.jobTitle}
            </p>
            {!!(c.salaryMin || c.salaryMax) && (
              <p>
                <b>Salary:</b> {parsePay(c.salaryMin, c.salaryMax)}
              </p>
            )}
            {!!(c.maxHourlyRate || c.minHourlyRate) && (
              <p>
                <b>Hourly rate:</b> {parsePay(c.minHourlyRate, c.maxHourlyRate)}
              </p>
            )}
            <p>
              <b>Closing date:</b> {format(new Date(c.endDate), 'dd/MM/yyyy')}
            </p>
            <p>
              <b>Start date:</b>{' '}
              {format(new Date(c.startDate), 'dd/MM/yyyy') || 'ASAP'}
            </p>
            <p>
              <b>Job description:</b>
              <ExpandableParagraph maxHeight={'4rem'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      c.advertDescription ||
                      roleLookup.get(c.jobTitleId)?.jobDescription
                  }}
                />
              </ExpandableParagraph>
            </p>
          </div>
          <div className="text-end">
            <Button
              variant="falcon-default"
              size="sm"
              disabled={!c.inviteLink}
              onClick={() => window.open(c.inviteLink)}
            >
              Apply
              <FontAwesomeIcon className="ms-1" icon={faExternalLinkAlt} />
            </Button>
            <br />
          </div>
        </DetailCard>
      ))}
    </PublicLayout>
  );
};
