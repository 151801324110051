import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ShareCampaign from '../InvitePeople';
import DomainReportTiles from 'components/app/reporting/DomainReportTiles';
import ApplicantsTable, {
  CampaignApplicants
} from '../../applicants/ApplicantsTable';
import DomainDetail from 'components/common/DomainDetail';
import { CampaignSidebar } from '../create/PublishCampaign';
import DetailCard from 'components/common/detail/DetailCard';
import CampaignInformationForm from '../create/CampaignInformationForm';
import JobInformation, { JobAdvert } from '../create/JobInformation';
import Stages from '../create/Stages';
import Pipelines from '../create/Pipelines';
import { Guard } from 'hooks/useGuard';
import { Campaign } from 'apis/flex/recruitment';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  const { campaignId } = useParams();
  return (
    <DomainDetail<Campaign>
      domain="recruitment-campaign"
      loadMutator={data => {
        return {
          ...data,
          campaignDates: [data.startDate, data.endDate],
          hourlyRate: [data.minHourlyRate, data.maxHourlyRate],
          annualSalary: [data.salaryMin, data.salaryMax],
          asap: !data.jobStartDate
        };
      }}
      saveMutator={data => ({
        ...data,
        isActive: !!data.isActive,
        startDate: data.campaignDates?.[0],
        endDate: data.campaignDates?.[1],
        minHourlyRate: data.hourlyRate?.[0],
        maxHourlyRate: data.hourlyRate?.[1],
        salaryMin: data.annualSalary?.[0],
        salaryMax: data.annualSalary?.[1]
      })}
      beforeSave={(vals, done) => {
        const data = vals;
        if (data.campaignDates) {
          data.startDate = data.campaignDates[0];
          data.endDate = data.campaignDates[1];
        }
        if (data.annualSalary && data.annualSalary[1]) {
          data.salaryMin = data.annualSalary[0];
          data.salaryMax = data.annualSalary[1];
        }
        if (data.hourlyRate && data.hourlyRate[1]) {
          data.minHourlyRate = data.hourlyRate[0];
          data.maxHourlyRate = data.hourlyRate[1];
        }
        done();
      }}
      defaultValues={{
        name: '',
        description: '',
        jobTitleId: null,
        advertTitle: '',
        advertDescription: '',
        jobStartDate: null,
        salaryMin: null,
        salaryMax: null,
        minHourlyRate: null,
        maxHourlyRate: null,
        isRemote: false,
        formId: undefined,
        isActive: true,
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        isPrivate: false,
        allowRepeats: false,
        allowRepeatsAfter: 30,
        allowDuplicates: false,
        asap: true
      }}
      description={
        <>
          Set up a recruitment campaign to fill a position. Create and share an
          application form, then sit back and watch the applicants come in!
        </>
      }
      sidebar={<CampaignSidebar />}
    >
      {campaignId && !isNaN(Number(campaignId)) && (
        <Col xs={12}>
          <Row className="g-3">
            <Col xs={12}>
              <ShareCampaign campaignId={campaignId} />
            </Col>
            <Col>
              <CampaignReportTiles campaignId={Number(campaignId)} />
            </Col>
            {/*
            Hiding because it checks whether there are form responses without associated applicants but user permissions
            mean that we can't be sure the user is allowed to see all relevant applicants & form responses. If I am only permitted to see
            50/100 applicants, but I'm permitted to see all form responses, it will give me a false error
             <Col xs={12}>
              <ApplicantsMissing campaignId={Number(campaignId)} />
            </Col> */}
            <Col xs={12}>
              <CampaignApplicants campaignId={Number(campaignId)} />
            </Col>
          </Row>
          <CampaignEditor campaignId={Number(campaignId)} />
        </Col>
      )}
    </DomainDetail>
  );
};
const CampaignEditor = ({ campaignId }) => {
  const [show, setShow] = useState(false);
  return (
    <Guard roles={['recruitment-campaign.edit']} itemIds={[Number(campaignId)]}>
      <Card className="mt-3 mb-3">
        <Card.Body className="text-center">
          <Button variant="falcon-primary" onClick={() => setShow(!show)}>
            {show ? 'Hide' : 'Show'} detail{' '}
            <FontAwesomeIcon
              className="ms-1"
              icon={faChevronDown}
              style={{ transform: show ? 'rotate(180deg)' : 'rotate(0)' }}
            />
          </Button>
        </Card.Body>
      </Card>
      {show && (
        <>
          <DetailCard
            id="campaign-info"
            title="Campaign information"
            data-tour="campaign-information"
          >
            <CampaignInformationForm />
          </DetailCard>
          <DetailCard
            id="job-info"
            data-tour="job-info"
            title="Job information"
          >
            <JobInformation />
          </DetailCard>
          <DetailCard
            id="advert"
            title="Job Advert"
            subtitle="If filled in, will be used on the company job board, as well as any external job board integrations"
          >
            <JobAdvert />
          </DetailCard>
          <DetailCard
            id="stages"
            title="Stages"
            subtitle={
              <>
                Stages are a way to group applicants, and/or progress them
                through the application process.
                <br />
                Each stage can have a task associated with it, which applicants
                will be asked to complete, or it can simply be used internally
                to group applicants.
                <br />
                Once a stage is created here, you will be able to place
                applicants into it, and follow their progress. You can use
                stages to ask applicants further questions, to place them into
                groups, or to manage multi-stage recruitment processes.
              </>
            }
          >
            <Stages />
          </DetailCard>
          <DetailCard
            id="pipelines"
            title="Pipelines"
            subtitle={
              <>
                <b>Automate recruitment with pipelines!</b> Here you can define
                some rules that will be run whenever someone applies of fills in
                a form for one of the campaign stages. You can automatically
                accept, reject, or move an applicant to a different stage
                depending on their responses!
              </>
            }
          >
            <Pipelines />
          </DetailCard>
        </>
      )}
    </Guard>
  );
};
const CampaignReportTiles = ({ campaignId }) => {
  return (
    <DomainReportTiles
      domain="applicant"
      location="view-campaign"
      filters={[
        [
          {
            question: 'campaignId',
            value: campaignId,
            type: '=='
          }
        ]
      ]}
      simple
      colProps={{ md: 6, xl: 3 }}
      className="g-3"
    />
  );
};
