import { useQuery } from "@tanstack/react-query";
import useDomainRouter from "./useDomainRouter";
import { useNavigate } from "react-router-dom";
import { cleanFilePath } from "helpers/utils";
export default ({
  domain,
  id,
  ids,
  isMultiple
}) => {
  const uniqueIds = [...new Set(ids)];
  const { getHomeUrl, getItemUrl } = useDomainRouter();
  const { error: routerError, data: url } = useQuery({
    queryKey: ["NotificationRouter", domain, id, uniqueIds],
    queryFn: async () => {
      if (uniqueIds?.length > 1 || isMultiple) {
        const url2 = await getHomeUrl({ domain, itemId: id });
        return cleanFilePath(url2);
      } else {
        const url2 = await getItemUrl({ domain, itemId: id });
        return cleanFilePath(url2);
      }
    },
    enabled: (!!id || !!uniqueIds) && !!domain
  });
  const nav = useNavigate();
  return {
    navigate: () => url && nav("/" + url),
    url,
    error: routerError
  };
};
