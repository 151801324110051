import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition
} from 'react';
import useMediaForm from 'hooks/useMediaForm';
import { MediaFormProvider } from 'hooks/useMediaFormContext';
import { ReactNodeLike } from 'prop-types';
import DetailHeader, { PageImageConfig } from './DetailHeader';
import { Button, Card, Col, Row } from 'react-bootstrap';
import BottomBar from '../BottomBar';
import { useMutation } from '@tanstack/react-query';
// import useNavWhen from 'hooks/useNavWhen';
import DetailSidebar from './DetailSidebar';
import { RoleDomain } from 'apis/flex/users';
import classNames from 'classnames';
import {
  faArrowLeft,
  faArrowRight,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import DetailCard, { DetailCardProps, ZCol } from './DetailCard';
import { settings } from 'config';
import {
  FieldErrorsImpl,
  useFormContext,
  useFormState,
  useWatch
} from 'react-hook-form';
import InputConfig from 'components/wizard/InputConfig';
import {
  callIfFunction,
  cleanFilePath,
  getItemFromStore,
  getPropertyByDotNotation,
  setItemToStore
} from 'helpers/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { apiError, apiSuccess } from 'apis/errors';
import { WizardInputProps } from 'components/wizard/WizardInput';
import { CrudConfig } from 'hooks/useCrudConfig';
import {
  DefaultCrudData,
  UseCrudProps
} from 'hooks/defaultCrud/useDefaultCrud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import { ErrorBoundary } from '@sentry/react';
import Error500 from 'components/errors/Error500';
import { useNavigationBlocker } from 'hooks/useNavWhen';

const BottomBarIfVisible = React.forwardRef<any, any>(
  ({ bottombarProps, bottombar, domain }, ref) => {
    const { isVisible } = useVisibilityObserver(ref, '0px');
    return (
      <BottomBar
        maxShown={2}
        {...bottombarProps}
        show={bottombar !== 'scroll' || !isVisible}
        domain={domain}
      />
    );
  }
);
export type BottomBarAction = {
  label: string;
  icon: IconProp;
  variant?: string;
  fn?: (...args: any) => void | Promise<any>;
  show?: boolean | ((...args: any) => boolean);
  disabled?: boolean | ((...args: any) => boolean);
  isSubmit?: boolean;
  /**
   * primary actions will be displayed as buttons. Non-primary actions will be displayed as dropdown
   */
  isPrimary?: boolean;
  component?: () => ReactNodeLike;
  loading?: boolean | ((...args: any) => boolean);
  /**
   * If true, button will be temporarily highlighted
   */
  attention?: boolean;
  setAttention?: (isOn: boolean) => void;
  /**
   * If specified, a confirmation will be displayed before the action, showing this message
   */
  confirm?: string;
  modal?: (props: { hide: () => void; data?: any }) => ReactNode;
  size?: 'sm' | 'lg';
};
type BottombarProps = {
  avatar?: string;
  title?: string;
  actions?: BottomBarAction[];
  isLoading?: boolean;
  maxShown?: number;
};
export type Tag = ReactNode | number | { bg: string; text: string };
type DetailPagePropsBase<
  TData extends DefaultCrudData = any,
  TMutated = TData
> = {
  children?: React.ReactNode;
  defaultValues?: Partial<TMutated> & Record<string, any>;
  onSave?: (vals: any, done: (savedData: any) => void) => void;
  onSubmitError?: (errors: FieldErrorsImpl) => void;
  onReset?: (data: TData) => any;
  mutationFn?: (...args: any) => Promise<any>;
  /**
   * Function to run after successful save. If specified, toast success will not show, and nav(-1) will not be called
   */
  afterSave?: (
    returnedData: TData,
    setAuthItemId: (id: number) => void,
    vals: any
  ) => void;
  beforeSave?: (vals: any, done: () => void) => void;
  beforeReset?: (vals: any, done: () => void, block: () => void) => void;

  isLoading: boolean;
  title: string;
  sidebar?: React.ReactNode;
  bottombar?: true | BottombarProps | 'scroll';
  description?: React.ReactNode;
  avatar?: PageImageConfig;
  background?: PageImageConfig;
  authItemCollection?: RoleDomain;
  authItemId?: number;
  isSaving?: boolean;
  tags?: Tag[];
  createdBy?: number;
  createdDate?: Date;
  domain?: keyof typeof settings.domains;
  loadMutator?: (data: TData) => TMutated;
  saveMutator?: (data: TMutated) => TData;
  actions?: BottomBarAction[];
  reset?: boolean;
  readOnly?: boolean | ((data: TData) => boolean);
  /**
   * If specified, the state of the form will be saved in the URL under this param name
   */
  urlStateKey?: string;
  /**
   * If specified, will store form data in session storage using this key, for short term recovery
   */
  storeId?: string;
  inputConfig?: Partial<WizardInputProps>;
  crudConfig?: Partial<UseCrudProps<TData, TData>>;
  isSelf?: boolean;
  cards?: DetailCardProps[] | ((data: TMutated) => DetailCardProps[]);
  saveLabel?: string;
  /** Custom header items, shown after the actions/buttons */
  headerChildren?: React.ReactNode | ((data: TMutated) => React.ReactNode);
  version?: number;
};
type DetailPagePropsOneItem<
  TData extends DefaultCrudData = any,
  TMutated = TData
> = DetailPagePropsBase<TData, TMutated> & {
  allData?: never;
  data: TData;
  itemId?: number | string;
  baseUrl?: never;
};
type DetailPagePropsAllItems<
  TData extends DefaultCrudData = any,
  TMutated = TData
> = DetailPagePropsBase<TData, TMutated> & {
  allData: TData[];
  data?: never;
  itemId: number | string;
  baseUrl: string;
};
export type DetailPageProps<
  TData extends DefaultCrudData = any,
  TMutated = TData
> =
  | DetailPagePropsOneItem<TData, TMutated>
  | DetailPagePropsAllItems<TData, TMutated>;
type DetailPageContext<TData = any> = {
  isLoading?: boolean;
  submit?: () => void;
  reset?: (done: () => void) => void;
  cancel?: () => void;
  data?: TData;
};
const detailContext = React.createContext<DetailPageContext<any>>({});
const DetailPageProvider = <TData extends { id: number } = any>({
  children,
  ...rest
}: DetailPageContext<TData> & {
  children: ReactNode;
}) => {
  return (
    <detailContext.Provider value={{ ...rest }}>
      {children}
    </detailContext.Provider>
  );
};
const DataSaver = ({ storeId, trigger }) => {
  const { dirtyFields, isDirty, touchedFields } = useFormState();
  const { setValue } = useFormContext();
  const vals = useWatch();
  useEffect(() => {
    if (storeId && isDirty) {
      const str = JSON.stringify(vals);
      // if (!!urlStateKey && str !== paramsData.get(urlStateKey)) {
      //   paramsData.set(urlStateKey, JSON.stringify(methods.getValues()));
      //   window.history.replaceState(
      //     null,
      //     '',
      //     `${window.location.pathname}?${paramsData.toString()}`
      //   );
      // }
      if (
        !!storeId &&
        str !== getItemFromStore(storeId, null, sessionStorage)
      ) {
        // console.log('saving data to ', storeId, vals);
        setItemToStore(storeId, str, sessionStorage);
        setItemToStore(storeId + '.touched', touchedFields, sessionStorage);
      }
    }
  }, [dirtyFields, isDirty, touchedFields, vals]);
  const traverseObject = (pastKeys = '', currentObject = {}) => {
    if (!currentObject) return;
    const getKey = k => (pastKeys ? pastKeys + '.' : '') + k;
    return Object.keys(currentObject).flatMap(k =>
      currentObject[k] === true
        ? getKey(k)
        : typeof currentObject[k] === 'object' &&
          traverseObject(getKey(k), currentObject[k])
    );
  };
  const [checkedStorage, setCheckedStorage] = useState(false);
  useEffect(() => {
    // if (checkedStorage) return;
    setCheckedStorage(true);
    if (
      !!storeId &&
      getItemFromStore(storeId, null, sessionStorage) &&
      !isDirty
    ) {
      const data = getItemFromStore(storeId, null, sessionStorage);
      console.log('retrieving data from store ', storeId, data);
      const touchedFields = getItemFromStore(
        storeId + '.touched',
        {},
        sessionStorage
      );
      const touchedFieldsMap = traverseObject('', touchedFields).filter(d => d);
      const vals = JSON.parse(data);
      console.log('setting retrieved data values', vals, touchedFieldsMap);
      touchedFieldsMap.forEach(f => {
        console.log('setting value', f, getPropertyByDotNotation(vals, f));
        setValue(f, getPropertyByDotNotation(vals, f), {
          shouldDirty: true,
          shouldTouch: true
        });
      });
      setItemToStore(storeId, null, sessionStorage);
      setItemToStore(storeId + '.touched', null, sessionStorage);
    }
  }, [isDirty, checkedStorage, trigger]);
  return <></>;
};
export const useDetailPage = <TData extends { id: number } = any>() =>
  React.useContext<DetailPageContext<TData>>(detailContext);
const DetailNavigator = ({ allData, baseUrl, itemId }) => {
  const nav = useNavigate();
  const index = allData?.findIndex(d => d.id === itemId);
  const handleGoToNext = () => {
    if (index + 1 < allData?.length) {
      nav(`/${cleanFilePath(baseUrl)}/${allData[index + 1].id}`);
    }
  };
  const handleGoToPrevious = () => {
    if (index - 1 >= 0) {
      nav(`/${cleanFilePath(baseUrl)}/${allData[index - 1].id}`);
    }
  };
  return (
    <Col xs={12}>
      <motion.div
        initial={{ opacity: 0, y: -700 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Card className="d-flex flex-row p-3 justify-content-between">
          <Button
            onClick={handleGoToPrevious}
            disabled={index === 0}
            variant="falcon-default"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <Button
            onClick={handleGoToNext}
            disabled={index + 1 >= allData?.length}
            variant="falcon-default"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Card>
      </motion.div>
    </Col>
  );
};
const DetailPage = ({
  children,
  defaultValues,
  onSave,
  // onReset,
  mutationFn,
  afterSave,
  beforeSave,
  beforeReset = (v, d) => d(),
  onSubmitError = console.error,
  data: itemData,
  isLoading: extLoading,
  title,
  sidebar,
  bottombar = 'scroll',
  description,
  avatar,
  background,
  authItemCollection,
  authItemId,
  isSaving: isSavingExt,
  tags,
  createdBy,
  createdDate,
  domain,
  loadMutator = d => d,
  saveMutator: _saveMutator = d => d,
  actions,
  reset = true,
  readOnly,
  urlStateKey,
  itemId: itemIdStr,
  storeId,
  inputConfig,
  crudConfig,
  allData,
  baseUrl,
  headerChildren,
  cards,
  saveLabel = 'Save',
  version
}: DetailPageProps) => {
  const saveMutator = (vals: any) => {
    try {
      const ret = _saveMutator(vals);
      return ret;
    } catch (err) {
      console.error(err, vals);
      throw err;
    }
  };
  const urlParams = useUrlSearchParams();
  const itemId = itemIdStr && Number(itemIdStr);
  const data = useMemo(
    () => itemData || allData?.find(d => d.id === itemId),
    [itemData, allData, itemId]
  );
  // console.log('data', data, loadMutator(data));
  const methods = useMediaForm({
    defaultValues,
    values: data && loadMutator(data),
    authItemCollection,
    authItemId,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true
    }
  });
  const [dataLoading] = useTransition();
  const isLoading = useMemo(
    () => extLoading || dataLoading,
    [extLoading, dataLoading]
  );
  const [highlightSave, setHighlightSave] = useState(false);
  const afterPrompt = useCallback(() => {
    console.log('after prompt');
    setHighlightSave(true);
  }, []);
  const { useNavWhen } = useNavigationBlocker();
  const nav = useNavWhen(
    !!(!methods.formState.isDirty || !!readOnly),
    afterPrompt
  );
  console.log('navWhen check', {
    isDirty: methods.formState.isDirty,
    dirtyFields: methods.formState.dirtyFields,
    touchedFields: methods.formState.touchedFields,
    readOnly
  });
  const { mutate, isLoading: isMutating } = useMutation<any, any, any>({
    mutationFn: vals => {
      return mutationFn(saveMutator(vals));
    },
    onError: apiError,
    onSuccess: (d, vals) => {
      methods.reset(vals, { keepDirty: false });
      if (authItemCollection && d?.id) {
        methods.setAuthItemId(d.id);
      }
      if (storeId) {
        setItemToStore(storeId, null, sessionStorage);
      }
      if (afterSave) {
        return setTimeout(() => afterSave(d, methods.setAuthItemId, vals), 300);
      }
      if (urlParams?.after === 'close') {
        window.close();
      }
      apiSuccess('Saved!');
      nav(-1);
    }
  });
  const [isSaveStarted, startSaving] = useTransition();
  const checkBeforeSave = useCallback(
    (vals, done) => {
      if (beforeSave) {
        return beforeSave(vals, done);
      } else {
        return done();
      }
    },
    [beforeSave]
  );
  const handleSubmit = useCallback(
    methods.handleSubmit(vals => {
      startSaving(() => {
        checkBeforeSave(vals, () => {
          if (onSave) {
            const mutated = saveMutator(vals);
            return onSave(mutated, savedData => {
              //REMOVED: don't do this. We might not be returning the full data from the save.
              // Instead, the caller will be responsible for invalidating/refetching the data after a save
              // console.log('Resetting form with saved data', {
              //   vals,
              //   mutated,
              //   savedData
              // });
              // methods.reset(vals, { keepDirty: false });

              //but do this so that it doesn't block navigation for being dirty
              methods.reset(data, { keepDirty: false });
              if (afterSave) {
                if (storeId) {
                  setItemToStore(storeId, null, sessionStorage);
                }
                setTimeout(
                  () => afterSave(savedData, methods.setAuthItemId, vals),
                  300
                );
              }
              let authItemPromise = Promise.resolve();
              if (authItemCollection) {
                authItemPromise = new Promise(resolve =>
                  methods.setAuthItemId(savedData.id, resolve)
                );
              }
              if (urlParams.after === 'close') {
                setTimeout(
                  () =>
                    authItemPromise.then(() => {
                      window.close();
                    }),
                  300
                );
              }
            });
          }
          mutate(vals);
        });
      });
    }, onSubmitError),
    [
      methods.handleSubmit,
      onSave,
      saveMutator,
      mutate,
      authItemCollection,
      afterSave,
      methods.setAuthItemId
    ]
  );
  const isSaving = useMemo(
    () => isSaveStarted || isSavingExt || isMutating,
    [isSaveStarted, isSavingExt, isMutating]
  );
  const [isResetStarted, startResetting] = useTransition();
  const handleFormReset = useCallback(
    done => {
      startResetting(() => {
        beforeReset(
          methods.getValues(),
          () => {
            if (storeId) {
              setItemToStore(storeId, null, sessionStorage);
            }
            methods.setValue('reset', true, { shouldDirty: true });
            methods.reset(defaultValues, { keepDirty: true });
            done && done();
          },
          done
        );
      });
    },
    [
      beforeReset,
      methods.getValues,
      methods.setValue,
      methods.reset,
      defaultValues
    ]
  );
  const cancelHandler = useCallback(() => {
    if (urlParams?.after === 'close') {
      window.close();
    }
    nav(-1);
  }, [nav]);
  const bottombarProps: BottombarProps = useMemo(() => {
    let bb: BottombarProps;
    if (bottombar === true || typeof bottombar === 'string') {
      bb = {
        title,
        actions: [
          {
            label: 'Cancel',
            isPrimary: true,
            fn: cancelHandler,
            variant: 'link',
            icon: faArrowLeft
          }
        ]
      };
      if (actions) {
        bb.actions.push(...actions);
      }
      if (!readOnly && (!!onSave || !!mutationFn)) {
        // bb.actions.push({
        //   label: 'Reset',
        //   variant: 'danger',
        //   icon: faUndo,
        //   fn: handleFormReset,
        //   loading: isResetStarted,
        //   confirm: 'This will clear all values on the page'
        // });
        bb.actions.push({
          label: saveLabel,
          variant: 'primary',
          isPrimary: true,
          icon: faSave,
          fn: handleSubmit,
          loading: isSaving,
          disabled: !methods.formState.isDirty,
          isSubmit: true
        });
      }
    } else {
      bb = {
        title,
        isLoading,
        avatar: !!avatar && data?.[avatar?.fieldName],
        ...bottombar
      };
    }
    if (bb?.actions) {
      bb.actions = bb.actions.map(a => ({
        ...a,
        fn: a.isSubmit ? () => handleSubmit() : a.fn,
        attention: a.isSubmit && highlightSave,
        setAttention: a.isSubmit && setHighlightSave
      }));
    }
    return bb;
  }, [
    bottombar,
    methods.formState.isDirty,
    isSaving,
    handleSubmit,
    isLoading,
    handleFormReset,
    highlightSave
  ]);
  const targetElRef = useRef(null);

  const detailPages = useMemo(
    () =>
      callIfFunction(cards, data)?.map(props => (
        <DetailCard key={props.id} {...props} />
      )) ||
      React.Children.toArray(children).filter(
        child => React.isValidElement(child) && child.type === DetailCard
      ),
    [children, data]
  );
  const otherPages = useMemo(
    () =>
      cards
        ? children
        : React.Children.toArray(children).filter(
            child => !React.isValidElement(child) || child.type !== DetailCard
          ),
    [children]
  );
  // console.log(
  //   'detailpages',
  //   detailPages.length,
  //   'otherPages',
  //   otherPages.length
  // );
  const paramsData = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (urlStateKey && paramsData.has(urlStateKey) && !readOnly) {
      // const data = paramsData.get(urlStateKey);
      // methods.reset(JSON.parse(data));
    }
  }, [window.location.search]);

  const submitHandler = useCallback(() => handleSubmit(), [handleSubmit]);
  const resetHandler = useCallback(
    done => reset && handleFormReset(done),
    [handleFormReset, reset]
  );

  return (
    <MediaFormProvider {...methods}>
      <CrudConfig value={crudConfig}>
        <DetailPageProvider
          submit={submitHandler}
          reset={resetHandler}
          cancel={cancelHandler}
          isLoading={isLoading}
          data={data}
        >
          {!readOnly && (!!data || !!defaultValues) && (
            <DataSaver storeId={storeId} trigger={data || defaultValues} />
          )}
          <Row className="gy-3">
            <ZCol
              ref={sidebar ? undefined : targetElRef}
              className={classNames({
                'col-xs-12 pb-xs-1 pb-lg-0': !!background
              })}
            >
              <DetailHeader
                buttons={!sidebar && !readOnly && (!!onSave || !!mutationFn)}
                title={title}
                tags={tags}
                description={description}
                isLoading={isLoading}
                avatar={avatar}
                background={background}
                createdBy={createdBy}
                createdDate={createdDate}
                domain={domain}
                actions={bottombarProps?.actions}
                itemId={itemId || data?.id}
                version={version}
              >
                {callIfFunction(headerChildren, data)}
              </DetailHeader>
            </ZCol>
            {allData && (
              <DetailNavigator
                allData={allData}
                baseUrl={baseUrl}
                itemId={itemId}
              />
            )}
            <InputConfig
              readOnly={callIfFunction(readOnly, data)}
              {...(inputConfig
                ? inputConfig
                : { autoComplete: 'new-password' })}
            >
              <ErrorBoundary
                fallback={err => (
                  <Error500
                    button={
                      <Button size="sm" onClick={err.resetError}>
                        Retry
                      </Button>
                    }
                    error={err.error as Error}
                  />
                )}
              >
                {otherPages}
              </ErrorBoundary>

              <Col xl={sidebar ? 8 : 12} className={''}>
                <Row>
                  {/* {isLoading ? (
                    <Card>
                      <Card.Header>
                        <Skeleton />
                      </Card.Header>
                      <Card.Body>
                        <FormPlaceholder />
                      </Card.Body>
                    </Card>
                  ) : ( */}
                  {detailPages}
                  {/* {React.Children.map(detailPages, (page: any, i) => {
                  return React.cloneElement(page, {
                    key: 'detailCard-' + i,
                    id: 'detailCard-' + i
                  });
                })} */}
                  {/* )} */}
                </Row>
              </Col>
              {sidebar && (
                <Col
                  xl={detailPages?.length ? 4 : 12}
                  className={''}
                  ref={targetElRef}
                >
                  <DetailSidebar
                    onCancel={(!!onSave || !!mutationFn) && cancelHandler}
                    onSave={(!!onSave || !!mutationFn) && handleSubmit}
                    isLoading={isLoading}
                    isSaving={isSaving || isMutating}
                    title={null}
                    actions={bottombarProps?.actions}
                  >
                    {sidebar}
                  </DetailSidebar>
                </Col>
              )}
            </InputConfig>
          </Row>
          {bottombar && !readOnly && (
            <BottomBarIfVisible
              ref={targetElRef}
              bottombarProps={bottombarProps}
              bottombar={bottombar}
              domain={domain}
            />
          )}
        </DetailPageProvider>
      </CrudConfig>
    </MediaFormProvider>
  );
};
export default DetailPage;
