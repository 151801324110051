import React from 'react';
import { EventPrefix } from 'apis/flex/notifications';
import { domainConfigs } from 'components/notification/config';
import { AddTagForm } from './TagSelector';
import { WizardInputProps } from 'components/wizard/WizardInput';
import DomainCustomTags from './DomainCustomTags';
const DomainTags = ({
  domain,
  ...rest
}: {
  domain: EventPrefix;
  canCreate?: boolean;
} & Omit<WizardInputProps, 'type' | 'name'>) => {
  const config = domainConfigs[domain];
  const crudHook = config?.crudHook;
  if (!crudHook) return null;
  const { meta } = crudHook({
    meta: true
  });
  if (!meta?.tags) return null;
  return (
    <DomainCustomTags
      domain={domain}
      tagField={'tags'}
      localKey="itemId"
      tableName={meta?.tagsTable}
      {...rest}
      registerProps={{
        required: false,
        ...rest?.registerProps
      }}
    />
  );
};
type EditTagProps = {
  domain: EventPrefix;
  itemIds?: number[];
  onFinished?: () => void;
  defaultValues?: number[];
};
const EditDomainTag = ({
  domain,
  onFinished,
  isRemove,
  itemIds,
  defaultValues
}: EditTagProps & {
  isRemove: boolean;
}) => {
  const config = domainConfigs[domain];
  const { addTags, removeTags, isAddingTags, isRemovingTags, meta } =
    config.crudHook({
      afterChange: () => {
        onFinished && onFinished();
      }
    });
  return (
    <AddTagForm
      hideLabel
      isSaving={isAddingTags || isRemovingTags}
      onAdd={tagIds => {
        if (isRemove) {
          removeTags({ itemIds, tagIds });
        } else {
          addTags({ itemIds, tagIds });
        }
      }}
      onFinished={onFinished}
      tableName={meta?.tagsTable}
      defaultValues={defaultValues}
    />
  );
};

export const AddDomainTag = (props: EditTagProps) => {
  return <EditDomainTag {...props} isRemove={false} />;
};

export const RemoveDomainTag = (props: EditTagProps) => {
  return <EditDomainTag {...props} isRemove={true} />;
};

export default DomainTags;
