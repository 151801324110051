import React, {
  useState,
  useRef,
  useEffect,
  HTMLAttributes,
  forwardRef,
  useImperativeHandle
} from 'react';

export default forwardRef(
  (
    {
      children,
      follower,
      className,
      followerProps,
      onClick,
      ...rest
    }: {
      children: React.ReactNode;
      follower?: React.ReactNode;
      className?: string;
      onClick?: (position: { x: number; y: number }) => void;
      followerProps?: (position: {
        x: number;
        y: number;
      }) => HTMLAttributes<HTMLDivElement>;
    } & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onClick'>,
    ref
  ) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isInside, setIsInside] = useState(false);
    const containerRef = useRef(null);
    useImperativeHandle(ref, () => containerRef.current);

    useEffect(() => {
      const handleMouseMove = e => {
        if (!containerRef.current) return;

        const rect = containerRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setPosition({ x, y });
      };

      const handleMouseEnter = () => setIsInside(true);
      const handleMouseLeave = () => setIsInside(false);

      const container = containerRef.current;
      if (container) {
        container.addEventListener('mousemove', handleMouseMove);
        container.addEventListener('mouseenter', handleMouseEnter);
        container.addEventListener('mouseleave', handleMouseLeave);
      }

      return () => {
        if (container) {
          container.removeEventListener('mousemove', handleMouseMove);
          container.removeEventListener('mouseenter', handleMouseEnter);
          container.removeEventListener('mouseleave', handleMouseLeave);
        }
      };
    }, []);

    return (
      <div
        className={`${className} w-100 h-100`}
        ref={containerRef}
        onClick={() => onClick?.(position)}
        {...rest}
      >
        {!!(isInside && follower) && (
          <div
            className="mouse-follower"
            {...followerProps?.(position)}
            style={{
              position: 'absolute',
              top: position.y,
              left: position.x,
              ...followerProps?.(position)?.style
            }}
          >
            {follower}
          </div>
        )}
        {children}
      </div>
    );
  }
);
