import DomainDetail from 'components/common/DomainDetail';
import React from 'react';
import useShifts from './useShifts';
import WizardInput from 'components/wizard/WizardInput';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import DetailCard from 'components/common/detail/DetailCard';
import {
  convertRulesFromForm,
  convertRulesToForm
} from 'components/common/DomainAvailabilityRules';
import { ShiftAvailabilityRulesForm } from './AvailabilityRules';
import { Alert, AlertHeading, Col, Row } from 'react-bootstrap';
import { AvailableBreakPicker } from './breaks/BreaksDetail';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { getDomainHome } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import { intervalToDuration } from 'date-fns';
import { getDateFromTime, timeToNumber } from 'helpers/dates';

export default () => {
  return (
    <DomainDetail
      domain="shift"
      crudHook={useShifts}
      saveMutator={d => ({
        ...d,
        availabilityRules: convertRulesFromForm(d.id, d.availabilityRules)
      })}
      loadMutator={d => ({
        ...d,
        availabilityRules: convertRulesToForm(d.availabilityRules)
      })}
    >
      <DetailCard id="shiftDetail" title="Detail">
        <ResourceGroupSelector name="resourceGroupId" label="Resource Group" />
        <WizardInput
          name="units"
          label="Hours"
          instruction="Number of hours the employee must work within the time frame below. This is also the amount of time that will be payable."
        />
        <Alert className="fs--1" dismissible variant="info">
          <AlertHeading className="fs-0">
            Setting Start and End times
          </AlertHeading>
          <p>
            Within what time frame does the employee need to complete the above
            hours?
          </p>
          <p>
            Setting the start time to 00:00 and the end time to 23:00 would let
            the employee complete the hours above at any time during the day.
          </p>
          <p>
            Setting the times to 09:00-17:00 and the above hours to 7 would
            essentially allow a 1 hour (unpaid) break at any time during the
            day.
          </p>
        </Alert>
        <Row>
          <Col md={6}>
            <WizardInput type="time" name="startTime" label="Start Time" />
          </Col>
          <Col md={6}>
            <WizardInput
              type="time"
              name="endTime"
              label="End Time"
              registerProps={{
                validate: (v, d) => {
                  if (!v) return;
                  if (v < '00:00' || v > '23:59')
                    return 'Time must be between 00:00 and 23:59';
                  if (!d.startTime) return;
                  if (v < d.startTime)
                    return 'End time must be after start time';
                  if (
                    intervalToDuration({
                      start: getDateFromTime(d.startTime),
                      end: getDateFromTime(v)
                    }).hours < d.units
                  )
                    return (
                      'End time must be at least ' +
                      d.units +
                      ' hours after start time'
                    );
                }
              }}
            />
          </Col>
        </Row>
      </DetailCard>
      <DetailCard
        title="Breaks"
        id="breaks"
        subtitle="Paid breaks that should be allowed during the shift"
      >
        <Breaks />
      </DetailCard>
      <DetailCard
        id="shiftAvailabilityRules"
        title="Availability Rules"
        subtitle="Rules that define who is able to book this shift"
      >
        <ShiftAvailabilityRulesForm />
      </DetailCard>
    </DomainDetail>
  );
};
const Breaks = () => {
  const id = useWatch({ name: 'id' });
  const user = useUser();
  return (
    !!id && (
      <AvailableBreakPicker
        registerProps={{ required: false }}
        readOnly
        name="breakIds"
        label="Available breaks"
        instruction={
          <>
            Can only be changed via the{' '}
            <Link to={getDomainHome('break', user, {})}>Breaks</Link> section
          </>
        }
        params={{ shiftId: id }}
      />
    )
  );
};
