import React, { forwardRef } from 'react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';
type ResizableProps = {
  children: React.ReactNode;
  onResize?: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
  onResizing?: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
  left?: boolean;
  right?: boolean;
  width: number;
  height: number;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'children' | 'onResize' | 'width' | 'height'
>;

const ResizableDiv = forwardRef<ResizableBox, ResizableProps>(
  (
    {
      children,
      onResize,
      left,
      right,
      width,
      height,
      onResizing,
      ...props
    }: Partial<ResizableProps>,
    ref
  ) => {
    const handles = [];
    // if (left) {
    //   handles.push('w');
    // }
    if (right) {
      handles.push('e');
    }
    return (
      <ResizableBox
        resizeHandles={handles}
        axis="x"
        handleSize={[5, 5]}
        width={width}
        height={height}
        ref={ref}
        onResizeStop={onResize}
        onResize={onResizing}
        {...props}
      >
        {children}
      </ResizableBox>
    );
  }
);

export default ResizableDiv;
