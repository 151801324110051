import React from 'react';
import useMainRef from 'hooks/useMainRef';
import { SelectedEmployees } from '../../staff/widgets/EmployeeSelector';
import { AllocationEntry } from 'apis/flex/projects';
import DraggableItem from 'components/common/DraggableItem';
import { AnimatePresence, motion } from 'framer-motion';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import useShifts from '../../shifts/useShifts';
import { domainConfigs } from 'components/notification/config';
import Skeleton from 'react-loading-skeleton';
import DragHandle from 'components/common/DragHandle';

export default ({
  allocation,
  index,
  readOnly
}: {
  allocation: AllocationEntry;
  index: number;
  readOnly?: boolean;
}) => {
  const id =
    allocation.employeeId.toString() + '-' + allocation.shiftId.toString();
  return (
    <DraggableItem
      dragHandle
      draggableId={id}
      index={index}
      isDragDisabled={!!readOnly}
    >
      {prov => (
        <div
          // layout="position"
          // layoutId={id}
          ref={prov.innerRef}
          {...prov.draggableProps}
          className="d-flex align-items-center"
        >
          <DragHandle
            className="me-2"
            // style={{ verticalAlign: 'middle' }}
            dragHandleProps={prov.dragHandleProps}
          />
          <div className="flex-1">
            <EmployeeShift
              employeeId={allocation.employeeId}
              shiftId={allocation.shiftId}
            />
          </div>
        </div>
      )}
    </DraggableItem>
  );
};
export const EmployeeShift = ({
  employeeId,
  shiftId,
  ...rest
}: {
  employeeId: number;
  shiftId: number;
} & Partial<SelectedDomainItemsProps>) => {
  const { data: shift } = useShifts({
    id: shiftId,
    select: d => d[0]
  });
  return (
    <div>
      <SelectedEmployees
        show
        {...rest}
        size="md"
        ids={[employeeId]}
        info={{
          shift: shift ? (
            domainConfigs['shift'].format?.(shift)?.label
          ) : (
            <Skeleton width={100} />
          )
        }}
      />
    </div>
  );
};
