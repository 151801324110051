import React from 'react';
import UserIcon from 'components/app/users/widgets/UserIcon';
import Flex from './Flex';
import { formatRelative } from 'date-fns';
import { motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import classNames from 'classnames';
const AuthorStamp = ({
  date,
  userId,
  showIcon,
  className,
  label = 'Created by',
  showDate,
  justify = 'start'
}: {
  date?: string | Date;
  userId?: number;
  showIcon?: boolean;
  className?: string;
  label?: string | false;
  showDate?: boolean;
  justify?: 'start' | 'center' | 'end';
}) => {
  return (
    !!userId && (
      <div className={classNames(className, 'mt-2')}>
        {label && (
          <div className="d-inline-block font-base fs--1 fw-medium text-500 me-1">
            {label}
          </div>
        )}
        <motion.div whileHover={'hover'} className="d-inline-block">
          <Flex
            alignItems={'center'}
            justifyContent={justify}
            wrap={'wrap'}
            className="gap-2"
          >
            <UserIcon
              showIcon={showIcon}
              size="m"
              showName
              hideJobTitle
              nameClass={'text-600 font-base mb-0 me-1'}
              id={userId}
            />
            {!!date && (
              <motion.span
                initial={{ opacity: 0, x: -100 }}
                transition={transitions.lightBounce}
                variants={{ hover: { opacity: 1, x: 0 } }}
                animate={showDate ? 'hover' : undefined}
                className="fw-medium text-600 font-base fs--1"
                style={{ lineHeight: 1.2 }}
              >
                {formatRelative(new Date(date), new Date())}
              </motion.span>
            )}
          </Flex>
        </motion.div>
      </div>
    )
  );
};

export default AuthorStamp;
