import React from 'react';
import DomainDetail from 'components/common/DomainDetail';
import useTargetGroups from '../useTargetGroups';
import { DetailCardProps } from 'components/common/detail/DetailCard';
import { TargetGroup } from 'apis/flex/projects';
import DomainTags from 'components/common/DomainCustomTags';
import ProjectCostCenterSelector from '../costs/ProjectCostCenterSelector';
import WithFormValues from 'components/wizard/WithFormValues';
import QuestionnaireSelector from '../questionnaires/QuestionnaireSelector';
import RpuTable from './RpuTable';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { useWatch } from 'react-hook-form';
import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import DetailWizard from 'components/common/detail/DetailWizard';
import { ImmutableInput, NamePrefix } from 'components/common/DomainInput';
import { ProjectSelector, QuotePicker } from '../ProjectDetail';
import TargetGroupResourceGroups from './TargetGroupResourceGroup';
export const SelectedTargetGroups =
  getSelectedDomainItems<TargetGroup>('target-group');
const Parent = () => {
  const quoteId = useWatch({ name: 'quoteId' });
  return quoteId ? (
    <ImmutableInput component={QuotePicker} name="quoteId" label="Quote" />
  ) : (
    <ImmutableInput
      component={ProjectSelector}
      name="projectId"
      label="Project"
    />
  );
};
const Info = () => {
  const data = useWatch();
  const projectId = data?.projectId;
  return (
    <>
      {/* <Parent /> */}
      <WizardInput name="name" />
      <QuestionnaireSelector
        label="Questionnaire"
        filter={{ projectId }}
        name="questionnaireId"
      />
      <Row>
        <Col xs={6}>
          <Input
            name="startDate"
            type="date"
            pluginProps={{ futureOnly: true }}
          />
        </Col>
        <Col xs={6}>
          <Input
            name="endDate"
            type="date"
            pluginProps={{ futureOnly: true }}
            registerProps={{
              validate: (v, d) =>
                !v ||
                !d?.startDate ||
                new Date(v) > new Date(d.startDate) ||
                'End date must be after start date'
            }}
          />
        </Col>
        <Col xs={12} lg={4}>
          <DomainTags domain="target-group" />
        </Col>
        <Col xs={12} lg={4}>
          <DomainTags
            domain="target-group"
            tagField="audiences"
            tagIdField="audienceId"
            tableName="ProjectAudiences"
          />
        </Col>
        <Col xs={12} lg={4}>
          <DomainTags
            domain="target-group"
            tagField="methodologies"
            tagIdField="methodologyId"
            tableName="ProjectMethodologies"
          />
        </Col>
      </Row>
    </>
  );
};
const Input = (props: WizardInputProps<TargetGroup>) => {
  return <WizardInput {...props} />;
};
const targetGroupForms: DetailCardProps[] = [
  {
    id: 'info',
    title: 'Info',
    children: <Info />
  },
  {
    id: 'resources',
    title: 'Resources',
    children: (
      <>
        {/* <DependancySelect
          name="resourceGroups"
          localKey="targetGroupId"
          foreignKey="resourceGroupId"
        >
          {props => (
            <ResourceGroupSelector
              {...props}
              multiple={false}
              label="Resource Group"
            />
          )}
        </DependancySelect> */}
        <TargetGroupResourceGroups name="resourceGroups" />
        <Row>
          <Col xs={12} lg={6}>
            <Input
              name="unitOfWork"
              type="select"
              label="Target type"
              options={['interview', 'hour', 'day', 'sale', 'input']}
            />
          </Col>
          <Col xs={12} lg={6}>
            <WithFormValues fields={['unitOfWork']}>
              {values =>
                !!values.unitOfWork && (
                  <Input
                    name="target"
                    type="number"
                    suffix={values.unitOfWork + 's'}
                  />
                )
              }
            </WithFormValues>
          </Col>
          <Col xs={12} lg={6}>
            <Input
              name="targetPerHour"
              type="number"
              suffix="ph"
              formControlProps={{ step: 0.01 }}
            />
          </Col>
        </Row>
      </>
    )
  },
  {
    id: 'sampleCard',
    title: 'Sample',
    children: (
      <Row>
        <Col xs={12} lg={6}>
          <Input
            name="sampleType"
            type="select"
            options={['freefind', 'provided']}
          />
        </Col>
        <Col xs={12} lg={6}>
          <Input
            name="expectedSampleStrikeRate"
            type="number"
            registerProps={{ required: false }}
            formControlProps={{ step: 0.01 }}
            suffix=":1"
          />
        </Col>
      </Row>
    )
  },
  {
    id: 'costCentersCard',
    title: 'Cost Centers',
    children: (
      <>
        <ProjectCostCenterSelector
          label="Revenues"
          instruction="Select which project cost center(s) will provide this target group's revenue. This will be used to calculate the target group's revenue and profitability."
        />
        <RpuTable />
      </>
    )
  }
];
export const TargetGroupWizard = ({
  defaultValues,
  namePrefix = 'targetGroup.',
  onFinished
}: {
  defaultValues?: Partial<TargetGroup>;
  namePrefix: NamePrefix;
  onFinished: () => void;
}) => {
  return (
    <DetailWizard
      onFinished={onFinished}
      defaultValues={defaultValues}
      cards={targetGroupForms}
      namePrefix={namePrefix}
    />
  );
};
export default () => {
  const { projectId: rawId } = useParams();
  const projectId = rawId && !isNaN(Number(rawId)) ? Number(rawId) : undefined;
  return (
    <DomainDetail
      defaultValues={{ projectId }}
      domain="target-group"
      crudHook={useTargetGroups}
      cards={targetGroupForms}
    />
  );
};
export const TargetGroupSelector =
  getDomainItemSelector<TargetGroup>('target-group');
