import React from 'react';
import { Button, Col, ModalBody, Row } from 'react-bootstrap';
import useResourceStatus from './hooks/useResourceStatus';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faClock,
  faFile,
  faTimes,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import DetailCard from 'components/common/detail/DetailCard';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { TrainingResource } from 'apis/flex/hr';
import FileIcon from 'components/files/FileIcon';
import useResourceValues from './hooks/useResourceValues';
import { formatDuration } from 'date-fns';
import { ResponsiveModal } from 'components/common/Modals';
import ResourcePage from './wizard/ResourcePage';
import InlineButton from 'components/common/InlineButton';
import Divider from 'components/common/Divider';
export const StatusBadge = ({ children, icon, variant, description = '' }) => {
  return (
    <>
      <SoftBadge bg={variant} className="mb-3 fs-2">
        <Flex justifyContent={'center'}>
          <FontAwesomeIcon icon={icon} className="me-2" /> {children}
        </Flex>
        {description && (
          <div className="d-block fs-0 mt-3 fw-normal">{description}</div>
        )}
      </SoftBadge>
    </>
  );
};
const ItemRow = ({ icon, label, value }) => {
  return (
    <Row className={'mt-1 align-items-center fs--1'}>
      <Col className="text-center" xs={1}>
        <FontAwesomeIcon icon={icon} />
      </Col>
      <Col className="flex-grow-1">
        {label}: <b>{value || <i>N/A</i>}</b>
      </Col>
    </Row>
  );
};
export default ({
  resource,
  index,
  review,
  onBegin,
  onReview,
  isLoading
}: {
  resource: TrainingResource;
  index: number;
  review: boolean;
  onBegin: () => void;
  onReview: () => void;
  isLoading?: boolean;
}) => {
  const { isComplete, isPending, isFailed } = useResourceStatus({
    index
  });
  const { getValue } = useResourceValues({ index });
  const attempts = getValue('quizAttempts') || [];
  const extraAttempts = getValue('extraAttempts') || 0;
  const attemptsLeft =
    resource.allowedAttempts + extraAttempts - attempts?.length;
  // console.log(attempts);
  const [showMaterial, setShowMaterial] = React.useState(false);
  return (
    <DetailCard
      animate={false}
      id="resource"
      colProps={{ xs: 12 }}
      title={resource.name}
      isLoading={isLoading}
      subtitle={
        <div dangerouslySetInnerHTML={{ __html: resource.description }} />
      }
      className={classNames({
        'badge-soft-success': isComplete,
        'badge-soft-danger': isFailed,
        'badge-soft-info': isPending
      })}
    >
      <Row className="align-items-center">
        <Col xs={12} className="text-center">
          {isComplete ? (
            <StatusBadge variant="success" icon={faCheck}>
              Completed
            </StatusBadge>
          ) : isPending ? (
            <StatusBadge variant="info" icon={faClock}>
              Approval pending
            </StatusBadge>
          ) : (
            isFailed && (
              <>
                <StatusBadge
                  variant="danger"
                  description={getValue('failedReason')}
                  icon={faTimes}
                >
                  Failed
                </StatusBadge>
              </>
            )
          )}
        </Col>
        {!isFailed && (
          <Col className="d-flex justify-content-center pb-5">
            <Row className="align-items-center justify-content-center w-100">
              <Col
                lg={6}
                className="d-none d-lg-flex p-3 px-4 align-items-center justify-content-center"
              >
                <FileIcon
                  height={60}
                  url={resource.url}
                  file={
                    resource.fileId ||
                    resource.videoId ||
                    (resource.videoUrl && { type: 'video' })
                  }
                />
              </Col>
              <Col
                lg={6}
                xs={9}
                className="d-flex flex-column align-items-start"
              >
                <ItemRow icon={faFile} label="Type" value={resource.type} />
                <ItemRow
                  icon={faClock}
                  label="Expected duration"
                  value={
                    resource.expectedDurationMins
                      ? formatDuration({
                          minutes: resource.expectedDurationMins
                        })
                      : 'Unknown'
                  }
                />
                <ItemRow
                  icon={faCheckDouble}
                  label="Requires approval"
                  value={resource.autoApprove ? 'No' : 'Yes'}
                />
                <ItemRow
                  icon={faCheckSquare}
                  label="Confirmation"
                  value={resource.confirmationTypes.join(', ')}
                />
                <ItemRow
                  icon={faUserCircle}
                  label="Attempts remaining"
                  value={
                    <span
                      className={classNames({
                        'text-danger': attemptsLeft === 1
                      })}
                    >
                      {attemptsLeft}
                    </span>
                  }
                />
              </Col>
            </Row>
          </Col>
        )}
        <Flex className="pb-3 w-100" justifyContent={'center'}>
          {(!isFailed && (isComplete || isPending)) || review ? (
            <Button variant="link" onClick={onReview}>
              Review
            </Button>
          ) : !isFailed ? (
            <Button onClick={onBegin}>
              {attempts?.length ? 'Retry' : 'Begin'}
            </Button>
          ) : (
            <div className="text-center fs--1">
              <Divider className="fw-semi-bold mt-5 mb-3" bg={'danger-subtle'}>
                What do I do now?
              </Divider>
              <div className="p-3">
                <p>
                  We've informed your manager that you might need some extra
                  guidance. Please be patient. They will be in touch soon.
                </p>
                <p className="mb-4">
                  In the meantime, please review the training materials below
                  thoroughly
                </p>
                {/* <div className="p-4"> */}
                <ResourcePage resource={resource} />
                {/* </div> */}
              </div>
            </div>
          )}
        </Flex>
      </Row>
      <ResponsiveModal
        wide
        show={showMaterial}
        onHide={() => setShowMaterial(false)}
      >
        <ModalBody>
          <ResourcePage resource={resource} />
        </ModalBody>
      </ResponsiveModal>
    </DetailCard>
  );
};
