import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/browser';
import useTrainingCourses from '../hooks/useTrainingCourses';
import DetailPage, { useDetailPage } from 'components/common/detail/DetailPage';
import {
  TrainingCourse,
  TrainingResource,
  EmployeeTrainingCourse,
  EmployeeTrainingResource
} from 'apis/flex/hr';
import { Card, Col } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import useUserTraining, {
  useEmployeeTrainingResources
} from '../hooks/useUserTraining';
import Flex from 'components/common/Flex';
import FileIcon from 'components/files/FileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/training.jpg';
import {
  faCheckCircle,
  faClock,
  faLock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import AuthorStamp from 'components/common/AuthorStamp';
import { useNavigate } from 'react-router-dom';
import CustomTabs from 'components/common/CustomTabs';
import ResourceWizard from './wizard/ResourceWizard';
import FrontPage from './FrontPage';
import ReviewWizard from './wizard/ReviewWizard';
import { useGuard } from 'hooks/useGuard';
import ErrorExpired from 'components/errors/ErrorExpired';
import { useEmote } from 'widgets/EmoteProvider';
import { getDomainHome } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import DetailPageIntro from 'components/common/DetailPageIntro';
import { sentryInstance } from 'index';
import useResourceValues from './hooks/useResourceValues';
import { apiError } from 'apis/errors';
import Error404 from 'components/errors/Error404';

export const ResourcePreview = ({ resource }) => {
  return (
    <Flex className={'mb-0'} alignItems={'center'} justifyContent={'start'}>
      <div className="file-thumbnail">
        <FileIcon
          url={resource.url}
          file={
            resource.fileId ||
            resource.videoId ||
            (resource.videoUrl && { type: 'video' })
          }
        />
      </div>
      <div className="ms-3">
        <h6 className="mb-0 text-900 fw-semi-bold">{resource.name}</h6>
        {/* <AuthorStamp
          showIcon={false}
          userId={resource.createdBy}
          date={resource.createdDate}
        /> */}
      </div>
    </Flex>
  );
};

const Resource = ({
  resource,
  index,
  review,
  isSelf
}: {
  resource: TrainingResource;
  index: number;
  /*
   only to be set to true if it's a manager reviewing a trainee's session. Not to be confused with
   where a trainee is reviewing their own session
  **/
  review: boolean;
  isSelf: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isTraineeReview, setIsTraineeReview] = useState(false);
  // const { submit } = useDetailPage();
  const handleTraineeReview = () => {
    setIsTraineeReview(true);
    setIsActive(true);
  };
  const { upsert, upsertSelf, isUpserting } = useEmployeeTrainingResources({
    silent: true,
    noReturnOnChange: true,
    onSaveError: apiError
  });
  const { getResource, setValue } = useResourceValues({
    index
  });
  const handleFinish = () => {
    if (isSelf) {
      setIsActive(false);
      upsertSelf(getResource(), data => {
        setValue('id', data.id);
        setIsActive(false);
      });
    } else {
      upsert(getResource(), data => {
        setValue('id', data.id);
        setIsActive(false);
      });
    }
  };
  return (
    <div>
      {/* <div className="position-absolute top-0 start-0 w-100 h-100 z-1 bg-black opacity-75" />
    <div> */}
      {isActive ? (
        isTraineeReview || review ? (
          <ReviewWizard
            resource={resource}
            index={index}
            review={review}
            onFinished={() => {
              setIsTraineeReview(false);
              setIsActive(false);
            }}
          />
        ) : (
          <ResourceWizard
            resource={resource}
            index={index}
            onFinished={handleFinish}
          />
        )
      ) : (
        <FrontPage
          isLoading={isUpserting}
          resource={resource}
          index={index}
          review={review}
          onBegin={() => setIsActive(true)}
          onReview={handleTraineeReview}
        />
      )}
      {/* </div> */}
    </div>
  );
};
const getFirstUnpassedResource = (
  resources: TrainingResource[],
  userResources: EmployeeTrainingResource[]
) => {
  const lookup = new Map(userResources.map(r => [r.resourceId, r]));
  return resources
    .sort((a, b) => a.index - b.index)
    .find(r => !lookup.get(r.id)?.approvedDate);
};
const Resources = ({
  course,
  resources,
  review,
  setActiveResource,
  activeResource,
  isLoading,
  isSelf
}: {
  course: TrainingCourse;
  review?: boolean;
  resources: TrainingResource[];
  activeResource?: number;
  setActiveResource: (name: number) => void;
  isLoading?: boolean;
  isSelf?: boolean;
}) => {
  const { resetField, getValues } = useFormContext();
  useEffect(() => {
    const inputs = getValues('resources');
    if (!inputs) {
      const lookup = inputs?.reduce(
        (acc, cur) => ({ ...acc, [cur.resourceId]: cur }),
        {}
      );
      resetField('resources', {
        defaultValue: resources?.map(r => ({
          resourceId: r.id,
          confirmationText:
            'I confirm I have read and understood the information presented herein.',
          ...(lookup ? lookup[r.id] : {})
        })),
        keepDirty: false
      });
    }
  }, [resources]);

  return (
    <Col xs={12}>
      <CustomTabs
        isLoading={isLoading}
        activeKey={activeResource}
        setActiveKey={setActiveResource}
        items={resources?.map((resource, i) => ({
          title: r => (
            <>
              {resource.name}{' '}
              {r && i > 0 && course.enforceOrder && !r[i - 1]?.approvedDate && (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              )}
            </>
          ),
          formField: `resources`,
          disabled: r =>
            r && i > 0 && course.enforceOrder && !r[i - 1]?.approvedDate,
          tabClass: r => {
            if (!r || !r[i]) return;
            return r[i]?.approvedDate
              ? 'text-success'
              : r[i]?.failedDate
              ? 'text-danger'
              : r[i]?.finishedDate
              ? 'text-info'
              : null;
          },
          icon: r => {
            if (!r || !r[i]) return;
            return r[i]?.approvedDate
              ? faCheckCircle
              : r[i]?.failedDate
              ? faTimesCircle
              : r[i]?.finishedDate
              ? faClock
              : null;
          },
          id: resource.id,
          content: (
            <Resource
              review={review}
              resource={resource}
              index={i}
              isSelf={isSelf}
            />
          )
        }))}
      />
    </Col>
  );
};
const TrainingClient = ({
  courseId,
  userId,
  userCourseId,
  review
}:
  | {
      courseId: number;
      userId: number;
      userCourseId?: never;
      review?: boolean;
    }
  | {
      courseId?: never;
      userId?: never;
      userCourseId: number;
      review?: boolean;
    }) => {
  const { data: userTraining, isLoading: userLoading } =
    useUserTraining<EmployeeTrainingCourse>({
      filters: { courseId, userId },
      id: userCourseId,
      useFilter: !userCourseId && !!courseId && !!userId,
      select: d => d[0],
      staleTime: Infinity,
      noReturnOnChange: true
    });
  const courseIdToUse = useMemo(
    () => courseId || userTraining?.courseId,
    [courseId, userTraining?.courseId]
  );
  const { data, isLoading: trainingLoading } =
    useTrainingCourses<TrainingCourse>({
      id: courseIdToUse,
      select: d => d[0],
      staleTime: Infinity
    });
  const userResourceLookup = new Map(
    userTraining?.resources?.reverse().map(r => [r.resourceId, r])
  );
  const mappedUserResources = useMemo(
    () =>
      data?.resources?.map(r => ({
        resourceId: r.id,
        ...userResourceLookup.get(r.id)
      })) || [],
    [data?.resources, userTraining?.resources]
  );
  const defaultValues = {
    courseId: courseIdToUse,
    ...userTraining,
    resources: data?.resources?.map(r => ({
      resourceId: r.id
    }))
  };
  const nav = useNavigate();
  const { celebrate } = useEmote();
  const user = useUser();
  const handleSave = (d, a, vals: EmployeeTrainingCourse) => {
    const firstUnpassedResource = getFirstUnpassedResource(
      data?.resources,
      vals?.resources
    );
    if (firstUnpassedResource) {
      setActiveResource(firstUnpassedResource.id);
    }
    const navto = getDomainHome('employee-training', user, userTraining);
    if (vals?.resources?.every(r => r.approvedDate)) {
      celebrate({ whenDone: () => nav(navto), message: 'Course completed!' });
    } else {
      if (vals?.resources?.every(r => r.finishedDate)) {
        nav(navto);
      }
    }
  };
  const pageData = useMemo(
    () => ({
      ...userTraining,
      courseId: courseIdToUse,
      resources: mappedUserResources
    }),
    [userTraining, mappedUserResources, courseIdToUse]
  );
  const [activeResource, setActiveResource] = useState<number>();
  const { canEdit } = useGuard({
    roles: ['training-course'],
    itemIds: [courseId]
  });
  const userLookup = new Map(mappedUserResources?.map(r => [r.resourceId, r]));
  const replay = Sentry.getReplay();
  useEffect(() => {
    if (review) return;
    if (replay) {
      replay.start();
    }
  }, [replay]);
  // console.log('resources', pageData?.resources, { mappedUserResources, data });
  return (
    <DetailPage
      readOnly
      domain="training-course"
      reset={!mappedUserResources?.some(r => r?.failedDate)}
      data={pageData}
      background={{
        fieldName: 'profileBackground',
        disabled: true,
        defaultImg: coverSrc
      }}
      isSelf={!canEdit}
      isLoading={trainingLoading || userLoading}
      title={data?.name}
      createdBy={userTraining?.userId}
      createdDate={userTraining?.lastSubmittedDate}
      defaultValues={defaultValues}
      bottombar={'scroll'}
      afterSave={handleSave}
      tags={[
        userTraining?.expiryDate &&
        new Date(userTraining?.expiryDate) < new Date()
          ? { bg: 'warning', text: 'Expired' }
          : pageData?.resources?.every(t => t.approvedDate)
          ? { bg: 'success', text: 'Completed' }
          : pageData?.resources?.some(t => t.failedDate)
          ? { bg: 'danger', text: 'Failed' }
          : { bg: 'info', text: 'In Progress' }
      ]}
    >
      {data?.description && <DetailPageIntro description={data?.description} />}
      {userTraining?.expiryDate &&
      new Date(userTraining?.expiryDate) < new Date() &&
      !review ? (
        <Col className="mt-4 mb-2">
          <ErrorExpired message="This course has expired. You will need to enroll in the course again to continue learning." />
        </Col>
      ) : (
        <Resources
          course={data}
          isLoading={trainingLoading || userLoading}
          review={review && !!canEdit}
          resources={data?.resources?.sort((a, b) => a.index - b.index)}
          setActiveResource={setActiveResource}
          activeResource={activeResource}
          isSelf={!canEdit}
        />
      )}
      {!data && !trainingLoading && !userLoading && <Error404 />}
    </DetailPage>
  );
};
export default TrainingClient;
