import React, {
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useTransition
} from 'react';
import { Button, Dropdown, Form, Offcanvas } from 'react-bootstrap';
import Flex from '../Flex';
import PropTypes from 'prop-types';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import LoadingButton from '../LoadingButton';
import { ProcessedColumnDef, useAdvanceTable } from './AdvanceTableProvider';
import WizardInput, {
  WizardInputOptions,
  WizardInputProps
} from 'components/wizard/WizardInput';
import { camelToSentence } from 'helpers/utils';
import { useQuery } from '@tanstack/react-query';
import FormPicker from '../customForms/widgets/FormPicker';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';
import TrainingCoursePicker from 'components/app/hr/training/widgets/TrainingCoursePicker';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { RoleDomain } from 'apis/flex/users';
import {
  getRuleInputType,
  getRuleOptions,
  isRuleMultiple
} from 'helpers/validation/rules';
import SoftBadge from '../SoftBadge';
import FalconCloseButton from '../FalconCloseButton';
import classNames from 'classnames';
import { CustomQuestionRules } from '../customForms/Editor/CustomFormQuestionEditor';
import { getDomainItemSelector } from '../DomainItemSelector';
import { EventPrefix } from 'apis/flex/notifications';
import useTimeout from 'hooks/useTimeout';
import Search from '../Search';
import { CustomRule } from 'helpers/validation/validate';

const ActionButtons = ({
  onSubmit,
  onReset,
  submitting,
  resetting
}: {
  onSubmit: () => void;
  onReset: () => void;
  submitting: boolean;
  resetting: boolean;
}) => {
  const [submitClicked, startSubmitting] = useTransition();
  const [resetClicked, startResetting] = useTransition();
  const handleApplyClick = () => {
    startSubmitting(() => {
      onSubmit();
    });
  };
  const handleResetClick = () => {
    startResetting(() => {
      onReset();
    });
  };
  return (
    <Flex justifyContent={'end'} className={'gap-1'}>
      <LoadingButton
        loading={submitClicked || submitting}
        onClick={handleApplyClick}
        variant="primary"
      >
        Apply
      </LoadingButton>
      <LoadingButton
        loading={resetClicked || resetting}
        variant="secondary"
        onClick={handleResetClick}
      >
        Reset
      </LoadingButton>
    </Flex>
  );
};
ActionButtons.propTypes = {
  setResetting: PropTypes.func,
  setSubmitting: PropTypes.func,
  submitting: PropTypes.bool,
  resetting: PropTypes.bool
};

const convertCustomInputTypes = (
  type,
  { aggregation, domain }: { aggregation?: boolean; domain?: RoleDomain }
) => {
  return aggregation ? 'number' : domain ? 'multiSelect' : type;
};
export const FilterTypeInput = ({
  column,
  getFilterName,
  active,
  includeTypes,
  excludeTypes,
  ...rest
}: {
  includeTypes?: string[];
  excludeTypes?: string[];
  column: {
    inputType: string;
    options?: WizardInputOptions;
    domain?: RoleDomain;
  };
  active?: boolean;
  getFilterName: (type: string) => string;
} & Partial<WizardInputProps>) => {
  const aggregation = useWatch({ name: getFilterName('aggregation') });
  const columnFilterType = convertCustomInputTypes(column.inputType, {
    aggregation,
    domain: column.domain
  });
  const typedCol = { ...column, inputType: columnFilterType };
  const types = getRuleOptions(typedCol);
  const { setValue } = useFormContext();
  const isActive = useWatch({ name: getFilterName('active') }) || active;
  const type = useWatch({ name: getFilterName('type') });
  useEffect(() => {
    if (!isActive) {
      setValue(getFilterName('type'), null);
    } else if (!type) {
      setValue(getFilterName('type'), types[0].value);
    }
  }, [isActive]);
  return (
    <>
      <WizardInput
        type="select"
        options={types.filter(
          t =>
            (!includeTypes || includeTypes.includes(t.value)) &&
            !excludeTypes?.includes(t.value)
        )}
        name={getFilterName('type')}
        hideLabel
        disabled={!isActive}
        registerProps={{
          required: !!isActive
        }}
        pluginProps={{ size: 'sm' }}
        {...rest}
      />
    </>
  );
};
const domainPickers = {
  form: FormPicker,
  user: UserSelector,
  'training-course': TrainingCoursePicker,
  contract: ContractPicker
};
export const FilterValueInput = ({
  column,
  controlProps = { step: 1 },
  data = [],
  getFilterName,
  active,
  size = 'sm',
  variables,
  ...rest
}: {
  column: {
    id: string;
    inputType: string;
    filter?: (props: any) => ReactNode;
    options?: WizardInputOptions;
    optionsQuery?: any;
    domain?: EventPrefix;
  };
  active?: boolean;
  controlProps?: any;
  data?: any;
  getFilterName: (type: string) => string;
  size?: string | 'sm';
  variables?: Record<string, any>;
} & Partial<WizardInputProps>) => {
  const isActive = useWatch({ name: getFilterName('active') }) || active;
  const aggregation = useWatch({ name: getFilterName('aggregation') });
  const columnFilterType = convertCustomInputTypes(column.inputType, {
    aggregation,
    domain: column.domain
  });
  const typedCol = { ...column, inputType: columnFilterType };
  const inputType = typedCol.inputType;
  const filterType = useWatch({ name: getFilterName('type') });
  const { setValue } = useFormContext();
  const filteredInputType = getRuleInputType(inputType, filterType);
  const { data: optionsData, isLoading: optionsLoading } = useQuery<any[]>({
    enabled: !!typedCol.optionsQuery,
    ...(typedCol.optionsQuery || {})
  });
  // let options: any[] = typedCol.options || optionsData;
  // if (
  //   Array.isArray(filteredInputType) &&
  //   typeof filteredInputType !== 'string'
  // ) {
  //   options = filteredInputType;
  // }
  const pluginProps: Record<string, any> = {
    step: 1,
    min: 0,
    max: 1
  };
  if (filteredInputType === 'range') {
    if (inputType === 'score') {
      pluginProps.step = 1;
      pluginProps.min = 0;
      pluginProps.max = 100;
    } else {
      pluginProps.step = controlProps.step || 1;
      pluginProps.min = 0;
      pluginProps.max = Math.max(
        5,
        Math.max(
          ...data.filter(d => !isNaN(d[typedCol.id])).map(d => d[typedCol.id])
        )
      );
    }
  }

  const val = useWatch({ name: getFilterName('value') });
  const inputProps = {
    name: getFilterName('value'),
    hideLabel: filteredInputType !== 'checkbox',
    loading: optionsLoading && typedCol.optionsQuery,
    disabled: !isActive,
    formControlProps: controlProps,
    registerProps: { required: false },
    pluginProps: {
      ...pluginProps,
      variant: isActive ? 'primary' : 'secondary'
    },
    label: filteredInputType === 'checkbox' ? (val ? 'True' : 'False') : null,
    ...rest
  };
  const DomainPicker: ReturnType<typeof getDomainItemSelector> =
    getDomainItemSelector(typedCol.domain) || domainPickers[typedCol.domain];
  const options =
    typedCol.options ||
    optionsData ||
    (Array.isArray(filteredInputType) && filteredInputType) ||
    [];
  if (filteredInputType === null) {
    return (
      <div
        {...{
          ...{ className: 'mb-3 position-relative' },
          ...rest?.formGroupProps
        }}
      ></div>
    );
  }
  return (
    <>
      {val?.startsWith?.('_$') ? (
        <div
          {...controlProps}
          {...rest?.formGroupProps}
          className={classNames(
            rest?.formGroupProps?.className,
            controlProps.className,
            'form-control'
          )}
        >
          <SoftBadge bg="info" className="p-2 fs--1 d-flex align-items-center">
            {val.slice(2)}
            <FalconCloseButton
              size="sm"
              onClick={() => setValue(getFilterName('value'), '')}
              className={'fs--2 p-0 ms-2'}
            />
          </SoftBadge>
        </div>
      ) : typedCol.filter ? (
        typedCol.filter(inputProps)
      ) : DomainPicker ? (
        <DomainPicker
          multiple={isRuleMultiple(filterType)}
          {...inputProps}
          // {...inputProps.pluginProps}
          compact
          size="xs"
        />
      ) : (
        <WizardInput
          type={
            Array.isArray(filteredInputType) || options.length
              ? 'multiSelect'
              : filteredInputType
          }
          options={options}
          {...inputProps}
          pluginProps={{ size, ...inputProps.pluginProps }}
          endEl={
            variables && (
              <VariablesMenu
                variables={variables}
                onSelect={k => setValue(getFilterName('value'), '_$' + k)}
              />
            )
          }
        />
      )}
    </>
  );
};
const VariablesMenu = ({
  variables,
  children,
  onSelect
}: {
  variables: Record<string, any>;
  children?: ReactNode;
  onSelect: (key: string, val: any) => void;
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className="text-nowrap btn-link">
        {children || 'Vars'}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
        {variables &&
          Object.keys(variables)?.map((k, i) => (
            <Dropdown.Item key={i} onClick={() => onSelect(k, variables[k])}>
              {k}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
FilterValueInput.propTypes = {
  column: PropTypes.object,
  isActive: PropTypes.bool,
  controlProps: PropTypes.object,
  data: PropTypes.array,
  getFilterName: PropTypes.func
};
export const Filter = ({ column, data, name }) => {
  const { setValue, setFocus } = useFormContext();
  const getFilterName = type => name + '.' + type;
  const isActive = useWatch({ name: getFilterName('active') });
  return (
    <Flex alignItems={'end'} wrap={'wrap'} className={'mb-3'}>
      <label className="form-label w-100">
        {column.header || camelToSentence(column.id)}
      </label>
      <Flex
        alignItems={'center'}
        wrap={'wrap'}
        className={'w-100 position-relative'}
      >
        <WizardInput
          type="switch"
          hideLabel
          registerProps={{ required: false }}
          name={getFilterName('active')}
          formGroupProps={{
            style: { width: '10%' },
            className: 'my-auto mb-auto'
          }}
        />
        <FilterTypeInput
          getFilterName={getFilterName}
          column={column}
          formGroupProps={{
            style: { width: '39%' },
            className: 'm-auto mb-auto'
          }}
        />
        <FilterValueInput
          formGroupProps={{
            className: 'w-50 m-auto mb-auto'
          }}
          getFilterName={getFilterName}
          column={column}
          controlProps={column.filterControlProps}
          data={data}
        />
        {!isActive && (
          <div
            className="position-absolute w-100 h-100 bg-300 bg-opacity-10"
            onClick={() => {
              console.log('overlay clicked');
              setValue(getFilterName('active'), true);
              setTimeout(() => setFocus(getFilterName('value')), 500);
            }}
          ></div>
        )}
      </Flex>
    </Flex>
  );
};
Filter.propTypes = {
  column: PropTypes.object,
  data: PropTypes.array,
  index: PropTypes.number
};
export const convertTableFiltersToFormFields = filters => {
  return (filters || []).reduce(
    (a, b) => ({
      ...a,
      //dots will create hierarchies in the form, which we don't want
      [b.id.replaceAll('.', '#')]: b.value || {
        value: '',
        type: '',
        active: false
      }
    }),
    {}
  );
};
export const convertFormFieldsToTableFilters = (
  vals: FilterFormValues
): TableFilters => {
  return Object.keys(vals).map(k => ({
    id: k.replaceAll('#', '.'),
    value: {
      ...vals[k],
      active:
        k === 'remoteFilter'
          ? vals[k].value?.some(v => v?.some(v => v.question)).length > 0
          : !!vals[k]?.type
    }
  }));
};
export type TableFilters = {
  id: string;
  value: {
    active: boolean;
    value: any;
    type: string;
  };
}[];
export type FilterFormValues = Record<
  string,
  {
    value: any;
    type: string;
    active: boolean;
  }
>;
export const TableFilterProvider = ({ children, replace = false }) => {
  const { filters, setPagination, pagination, setFilters, getAllFlatColumns } =
    useAdvanceTable();
  const columnFilters = useMemo(
    () =>
      getAllFlatColumns()
        .filter(c => c.columnDef.enableColumnFilter !== false)
        .reduce(
          (a, b) => ({
            ...a,
            [b.columnDef.id.replaceAll('.', '#')]: {
              value: '',
              type: '',
              active: false
            }
          }),
          {} as FilterFormValues
        ),
    [getAllFlatColumns]
  );
  const remoteFilters = {
    remoteFilter: {
      value: [
        [
          {
            type: '',
            value: '',
            question: ''
          }
        ]
      ],
      type: 'custom',
      active: false
    }
  };
  const defaultValues = { ...columnFilters, ...remoteFilters };
  const methods = useForm<FilterFormValues, any, FilterFormValues>({
    defaultValues
    // values: filters
  });
  useEffect(() => {
    if (filters.length > 0) {
      console.log('resetting pagination due to filters change', filters);
      setPagination({ ...pagination, pageIndex: 0 });
    }
    methods.reset({
      ...defaultValues,
      ...convertTableFiltersToFormFields(filters)
    });
  }, [filters]);
  const handleSubmit = (done, err) =>
    methods.handleSubmit(vals => {
      if (replace) {
        setFilters(convertFormFieldsToTableFilters(vals));
      } else {
        setFilters(f => {
          const dedupe = new Map(
            [...f, ...convertFormFieldsToTableFilters(vals)].map(v => [v.id, v])
          );
          return [...dedupe.values()];
        });
      }
      done();
    }, err);
  const reset = () => {
    setFilters([]);
  };
  return (
    <FormProvider {...methods} handleSubmit={handleSubmit} reset={reset}>
      {children}
    </FormProvider>
  );
};
const DefaultFilters = ({ columns, data }) => {
  return (
    <Search
      className="mb-3"
      autoFocus
      debounceMs={0}
      data={columns}
      keys={['header', 'id']}
    >
      {results =>
        results.map(col => (
          <Filter name={col.id} column={col} key={col.id} data={data} />
        ))
      }
    </Search>
  );
};
const FilterForm = ({ onSubmit, isLoading, columns, children }) => {
  const { getPrePaginationRowModel } = useAdvanceTable();
  const startSubmitting = useTransition()[1];
  const data = useMemo(
    () => getPrePaginationRowModel().rows.map(r => r.original),
    [getPrePaginationRowModel]
  );
  const handleFilterChange = () => {
    setSubmitting(false);
    setResetting(false);
    onSubmit();
  };
  const [submitting, setSubmitting] = useState<boolean>();
  const [resetting, setResetting] = useState<boolean>();
  const { handleSubmit, reset } = useFormContext();
  const handleFormSubmit = () => {
    startSubmitting(() => {
      handleSubmit(handleFilterChange, console.error)();
    });
  };
  const handleReset = () => {
    reset({});
    handleFilterChange();
  };
  return (
    <>
      <Form
        action="()=>{}"
        onSubmit={e => {
          e.preventDefault();
          handleFormSubmit();
        }}
        className="overflow-y-scroll"
        style={{ height: 'calc(100% - 38px)' }}
      >
        {children({ columns, data })}
        <Button className="d-none" type="submit"></Button>
      </Form>
      <div className="">
        <ActionButtons
          onSubmit={handleFormSubmit}
          onReset={handleReset}
          {...{
            resetting: resetting || isLoading,
            submitting: submitting || isLoading
          }}
        />
      </div>
    </>
  );
};
const FilterProvider = ({
  onSubmit,
  children,
  remoteFilters
}: {
  onSubmit: () => void;
  remoteFilters?: { filter: CustomRule; isLoading?: boolean };
  children: (props: {
    columns: ProcessedColumnDef<any, unknown>[];
    data: any[];
  }) => JSX.Element;
}) => {
  // const { data } = useAdminData();
  // const { adminFilters, filterColumns } = useAdminFilters();
  const { getAllFlatColumns } = useAdvanceTable();
  const fields = getAllFlatColumns()
    .map(col => ({ ...col.columnDef, id: col.id.replaceAll('.', '#') }))
    .filter(
      col =>
        col.filter !== false &&
        !col.isSelect &&
        !col.isAction &&
        col.enableColumnFilter !== false
    );
  const [shouldClose, setShouldClose] = useState(false);
  const { set } = useTimeout();
  const handleSubmit = () => {
    if (remoteFilters) {
      //wait for loading to start so useEffect below doesn't close prematurely
      // console.log('waiting to set shouldClose...', {
      //   remoteFilters,
      //   shouldClose
      // });
      set(() => {
        // console.log('setting shouldClose', { remoteFilters, shouldClose });
        setShouldClose(true);
      }, 100);
    } else {
      onSubmit();
    }
  };
  useEffect(() => {
    // console.log('souldClose', { remoteFilters, shouldClose });
    if (shouldClose && !remoteFilters?.isLoading) {
      onSubmit();
      setShouldClose(false);
    }
  }, [remoteFilters?.isLoading, shouldClose]);
  return (
    <TableFilterProvider replace>
      <FilterForm
        onSubmit={handleSubmit}
        isLoading={remoteFilters?.isLoading}
        columns={fields}
      >
        {children}
      </FilterForm>
    </TableFilterProvider>
  );
};
export const AdvanceTableFilters = ({
  filterShow,
  setFilterShow
}: {
  filterShow: boolean;
  setFilterShow: (filterShow: boolean) => void;
}) => {
  function handleClose() {
    setFilterShow(false);
  }
  return (
    <Offcanvas
      className="tour-filters-body"
      show={filterShow}
      onHide={handleClose}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pe-0">
        <FilterProvider onSubmit={handleClose}>
          {({ columns, data }) => (
            <DefaultFilters columns={columns} data={data} />
          )}
        </FilterProvider>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
AdvanceTableFilters.propTypes = {
  filterShow: PropTypes.bool,
  setFilterShow: PropTypes.func
};
export const AdvanceTableCrudFilters = ({
  filterShow,
  setFilterShow,
  remoteFilters
}: {
  filterShow: boolean;
  setFilterShow: (filterShow: boolean) => void;
  remoteFilters?: any;
}) => {
  function handleClose() {
    setFilterShow(false);
  }
  return (
    <Offcanvas
      className="tour-filters-body"
      show={filterShow}
      onHide={handleClose}
      placement={'end'}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pe-0">
        <FilterProvider remoteFilters={remoteFilters} onSubmit={handleClose}>
          {({ columns }) => (
            //    .map(col => (
            //   <Filter name={col.id} column={col} key={col.id} data={data} />
            // ))
            <CustomQuestionRules
              compact
              name="remoteFilter.value"
              placeholder={'Field...'}
              excludeTypes={['allin', '!allin', '>count', '<count']}
              questions={columns.map(c => ({
                ...c.meta,
                id: c.id,
                value: c.id,
                label: c.meta.header || c.header,
                inputType: c.meta.inputType
              }))}
            />
          )}
        </FilterProvider>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
