import { EventPrefix } from 'apis/flex/notifications';
import { domainConfigs } from 'components/notification/config';
import React from 'react';
import TagSelector from './TagSelector';
import DependancySelect from './DependancySelect';
import { WizardInputProps } from 'components/wizard/WizardInput';
import { camelToSentence } from 'helpers/utils';

export default ({
  domain,
  tagField = 'tags',
  tagIdField = 'tagId',
  tableName,
  localKey,
  ...rest
}: {
  domain: EventPrefix;
  tableName?: string;
  tagField?: string;
  tagIdField?: string;
  localKey?: string;
  canCreate?: boolean;
} & Omit<WizardInputProps, 'type' | 'name'>) => {
  const config = domainConfigs[domain];
  return (
    <DependancySelect
      name={tagField}
      foreignKey={tagIdField}
      localKey={localKey || config.foreignKey}
    >
      {props => (
        <TagSelector
          {...props}
          label={camelToSentence(tagField)}
          tableName={tableName || config.sqlTable}
          {...rest}
          registerProps={{
            required: false,
            ...rest?.registerProps,
            ...props?.registerProps
          }}
        />
      )}
    </DependancySelect>
  );
};
