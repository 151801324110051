import DetailPage from 'components/common/detail/DetailPage';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTickets, { useClosedTickets, useOpenTickets } from './useTickets';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, FormLabel } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';
import useTicketComments from './useTicketComments';
import useMediaForm from 'hooks/useMediaForm';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { Ticket, TicketComment } from 'apis/flex/support';
import TicketComments from './TicketComments';
import UserSelector from '../users/widgets/selector/UserSelector';
import { Guard, useGuard } from 'hooks/useGuard';
import TagSelector from 'components/common/TagSelector';
import TicketSelector from './TicketSelector';
import CustomTooltip from 'components/common/Tooltip';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import { truncate } from 'lodash';
import SettingsBox from 'components/common/SettingsBox';
import { AiIconButton } from 'components/common/AIFormAssistant';
import { getTextFromHtml, removeHtmlTags } from 'helpers/strings';
import DomainTags from 'components/common/DomainTags';
import DomainDetail from 'components/common/DomainDetail';
const TicketReply = ({ ticket }: { ticket: Ticket }) => {
  const [showForm, setShowForm] = useState(false);
  const { add, isAdding } = useTicketComments<TicketComment>({
    afterSave: () => {
      methods.reset({});
      setShowForm(false);
    }
  });
  const methods = useMediaForm<TicketComment>();
  const suggestions = ticket?.comments.length
    ? ticket.comments[ticket.comments.length - 1].suggestedResponses
    : ticket?.suggestedResponses;
  const { canEdit } = useGuard({ roles: ['ticket'], itemIds: [ticket?.id] });
  const user = useUser();
  const suggestionBox = (
    <>
      {suggestions &&
        canEdit &&
        user.id != ticket?.createdBy &&
        suggestions.length > 0 && (
          <SettingsBox
            title={<>Suggested responses</>}
            className="w-100 d-flex flex-wrap gap-3"
          >
            {suggestions.map((suggestion, i) => (
              <CustomTooltip
                key={suggestion}
                content={
                  <div dangerouslySetInnerHTML={{ __html: suggestion }} />
                }
              >
                <div>
                  <AiIconButton
                    onClick={() => {
                      methods.setValue('comment', suggestion);
                      setShowForm(true);
                    }}
                    className="rounded-pill"
                  >
                    {truncate(getTextFromHtml(suggestion), { length: 100 })}
                  </AiIconButton>
                </div>
              </CustomTooltip>
            ))}
          </SettingsBox>
        )}
    </>
  );
  return (
    <FormProvider {...methods}>
      {!showForm && suggestionBox}
      {showForm && (
        <div className="my-3 text-start">
          <h5 className="mb-3">Reply</h5>
          <WizardInput
            type="texteditor"
            name="comment"
            pluginProps={{ height: 200 }}
            hideLabel
          />
          {suggestionBox}
          <WizardInput
            type="file"
            multiple
            name="files"
            registerProps={{ required: false }}
            label="Attachments"
          />
          <div className="text-end p-2">
            <Button
              size="sm"
              variant="link"
              className="me-2"
              onClick={() => {
                setShowForm(false);
                methods.reset({});
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isAdding}
              onClick={methods.handleSubmit(vals => {
                add({
                  ticketId: ticket?.id,
                  ...vals
                });
              }, console.error)}
              size="sm"
              variant="falcon-primary"
            >
              Send
            </LoadingButton>
          </div>
        </div>
      )}
      {!showForm && (
        <div>
          <IconButton
            size="sm"
            variant="falcon-default"
            icon="reply"
            onClick={() => setShowForm(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">Reply</span>
          </IconButton>
        </div>
      )}
    </FormProvider>
  );
};
export const ticketTypes = ['Feature request', 'Bug/error', 'Other'];
export const ticketPriorities = ['Low', 'Medium', 'High', 'Emergency'];
const TicketCommonForm = () => {
  return (
    <>
      <WizardInput
        type="select"
        name="priority"
        registerProps={{ required: false }}
        options={ticketPriorities}
      />
      <WizardInput type="select" name="type" options={ticketTypes} />
    </>
  );
};
export const ticketStatusOptions = [
  { label: 'To do', value: 'To do' },
  { label: 'Working on it', value: 'Doing' },
  { label: 'Completed', value: 'Done' },
  { label: 'Closed (not actioned)', value: 'Closed' },
  { label: 'Merged', value: 'Merged' }
];
const Sidebar = () => {
  const agentId = useWatch({ name: 'agentId' });
  const { setValue } = useFormContext();
  const user = useUser();
  const [choose, setChoose] = useState(false);
  return (
    <Guard show roles={['ticket.edit']} itemIds={[useWatch({ name: 'id' })]}>
      <WizardInput type="text" name="title" />
      {!agentId && !choose ? (
        <>
          <FormLabel className="mt-2 d-block">Support agent</FormLabel>
          <ButtonGroup className="mb-3 form-control">
            <Button
              variant="falcon-primary"
              className="w-50"
              onClick={() => {
                setValue('agentId', user.id);
              }}
            >
              <span className="d-none d-md-block">Assign to me</span>
              <span className="d-md-none">Me</span>
            </Button>
            <Button
              className="w-50"
              variant="falcon-primary"
              onClick={() => setChoose(true)}
            >
              <span className="d-none d-md-block">Assign to someone else</span>
              <span className="d-md-none">Someone else</span>
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <UserSelector
          label="Support agent"
          name="agentId"
          registerProps={{ required: false }}
        />
      )}
      <TicketCommonForm />
      <WizardInput
        type="select"
        name="status"
        registerProps={{ required: false }}
        options={ticketStatusOptions}
      />
      <Merged />
      <WizardInput
        registerProps={{ required: false }}
        type="checkbox"
        name="notifyCreator"
        label="Notify creator on updates"
      />
      <DomainTags domain="ticket" label="Tags" />
    </Guard>
  );
};
const Merged = () => {
  const status = useWatch({ name: 'status' });
  const ticketId = useWatch({ name: 'id' });
  return (
    status === 'Merged' && (
      <TicketSelector
        label="Merged to"
        filter={[{ first: 'id', negate: true, second: ticketId }]}
        name="mergedTicketId"
      />
    )
  );
};
export default () => {
  const { id } = useParams();
  const { data, isLoading, upsert, isUpserting } = useTickets({
    id,
    select: d => d[0]
  });
  const nav = useNavigate();
  // const dataIndex = allTickets?.findIndex(d => d.id === Number(id));
  // const nextId = allTickets?.[dataIndex + 1]?.id;
  // const data = allTickets?.[dataIndex];
  const { canEdit } = useGuard({ roles: ['ticket'], itemIds: [Number(id)] });
  return (
    <DomainDetail<Ticket>
      domain="ticket"
      // allData={allTickets}
      // data={data}
      tags={[
        {
          text: data?.status,
          bg:
            data?.status === 'Done'
              ? 'success'
              : data?.status === 'Doing'
              ? 'primary'
              : 'secondary'
        }
      ]}
      // baseUrl={'/support/tickets/' + (status || '')}
      // itemId={Number(id)}
      title={'Ticket #' + id}
      description={
        <>
          <MergedTickets />
          {canEdit && (
            <div className="d-flex flex-column">
              <h6>
                Source page:{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data?.currentPage}
                >
                  {data?.currentPage}
                  <FontAwesomeIcon icon="external-link-alt" className="ms-1" />
                </a>
              </h6>
              <h6>
                Work item:{' '}
                {data?.workItemId ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://dev.azure.com/matt0580/Flex/_workitems/edit/${data?.workItemId}/`}
                  >
                    #{data?.workItemId}
                    <FontAwesomeIcon
                      icon="external-link-alt"
                      className="ms-1"
                    />
                  </a>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6>
                Event info:{' '}
                <span dangerouslySetInnerHTML={{ __html: data?.eventInfo }} />
              </h6>
            </div>
          )}
        </>
      }
      // domain="ticket"
      // isLoading={isLoading}
      // isSaving={isUpserting}
      // onSave={(vals, done) => upsert(vals, done)}
      // afterSave={d => {
      //   if (!data?.id) {
      //     nav('/support/tickets/' + d.id);
      //   } else {
      //     nav('/support/tickets/' + (status ? status + '/' : '') + nextId);
      //   }
      // }}
      sidebar={<Sidebar />}
    >
      <DetailCard
        id="comments"
        title={
          <h5 className="mb-0">
            <FontAwesomeIcon icon="envelope" className="me-2" />
            {data?.title}
          </h5>
        }
        buttons={<TicketReply ticket={data} />}
      >
        <TicketComments ticket={data} comments={data?.comments || []} />
      </DetailCard>
    </DomainDetail>
  );
};
const MergedTickets = () => {
  const ticketId = useWatch({ name: 'id' });
  const { data: tickets } = useTickets({
    useFilter: !!ticketId,
    filters: { first: 'mergedTicketId', second: ticketId }
  });
  const user = useUser();
  return (
    !!tickets?.length && (
      <div>
        <h6>
          Merged tickets:{' '}
          {tickets.map(t => (
            <CustomTooltip key={t.id} content={t.title}>
              <a
                target="_blank"
                rel="noreferrer"
                className="me-2"
                href={getDomainItemUrl('ticket', user, t)}
              >
                #{t.id}
              </a>
            </CustomTooltip>
          ))}
        </h6>
      </div>
    )
  );
};
