import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Card, CardProps, Col, Row } from 'react-bootstrap';
import icon from 'assets/img/animated-icons/office1.json';
import classNames from 'classnames';
import Lottie from 'lottie-react';

export default ({
  className,
  ...rest
}: { error?: Error; button?: ReactNode } & CardProps) => {
  return (
    <div className="vh-100 d-flex p-4">
      <Card className={classNames('text-center m-auto', className)} {...rest}>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <Lottie animationData={icon} />
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
              <h3>Down for maintenance</h3>
              <p className="lead">
                Flex is currently undergoing scheduled maintenance
              </p>
              <p>
                It should only be a few minutes, so please check back shortly
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
