import React, { useEffect } from 'react';
import useResourceValues from '../hooks/useResourceValues';
import FormWizard, {
  OnWizardScreenout,
  OnWizardSubmit
} from 'components/wizard/FormWizard';
import { CheckboxContent, SignatureContent } from './ResourceConfirmations';
import CustomFormClient from 'components/common/customForms/CustomFormClient';
import {
  faCheckSquare,
  faGraduationCap,
  faSignature
} from '@fortawesome/free-solid-svg-icons';
import { TrainingResource } from 'apis/flex/hr';
import ResourcePage from './ResourcePage';
import { Button, Card, CloseButton } from 'react-bootstrap';
import Screenedout from 'components/wizard/Screenedout';
const WizardWithQuiz = ({
  resource,
  index,
  onSubmit,
  stepsBefore,
  stepsAfter,
  onScreenout,
  ...rest
}) => {
  // console.log(useWatch());
  return (
    <CustomFormClient
      formId={resource.formId}
      onSubmitted={onSubmit}
      onScreenout={onScreenout}
      pagesAfter={() =>
        stepsAfter.map(step => (
          <FormWizard.Page
            key={step.label}
            icon={step.icon}
            label={step.label}
            onSubmit={step.onSubmit}
          >
            <step.Component resource={resource} index={index} />
          </FormWizard.Page>
        ))
      }
      pagesBefore={() =>
        stepsBefore.map(step => (
          <FormWizard.Page
            key={step.label}
            icon={step.icon}
            label={step.label}
            onSubmit={step.onSubmit}
          >
            <step.Component resource={resource} index={index} />
          </FormWizard.Page>
        ))
      }
      {...rest}
    />
  );
};
export default ({
  resource,
  index,
  onFinished,
  review,
  data
}: {
  resource: TrainingResource;
  index: number;
  onFinished: () => void;
  review?: boolean;
  data?: any;
}) => {
  const { setValue, getValue } = useResourceValues({ index });
  const [isFailed, setIsFailed] = React.useState(false);

  useEffect(() => {
    if (!getValue('startedDate')) {
      setValue('startedDate', new Date());
    }
  }, [getValue, setValue]);
  const handleSubmit: OnWizardSubmit = ({
    data,
    originalData,
    screenoutIds,
    clearStore
  }) => {
    setValue('signature', originalData?.signature);
    setValue('checkbox', originalData?.checkbox);
    const attempts = getValue('quizAttempts') || [];
    const extraAttempts = getValue('extraAttempts') || 0;
    const allowedAttempts = resource.allowedAttempts + extraAttempts;

    //data id will only be returned if a quiz has been included
    if (data?.id) {
      setValue('quizAttempts', attempts.concat({ formResponseId: data?.id }));
    }
    if (screenoutIds?.length) {
      console.log('screened out');
      if (attempts.length + 1 < allowedAttempts) {
        console.log('can try again');
        setIsFailed(true);
        clearStore();
        return;
      }
      console.log('no attempts left');
      setValue('failedDate', new Date());
      setValue('failedBy', resource.createdBy);
      setValue('failedReason', 'Max attempts reached');
      clearStore();
      onFinished();
      return;
    }
    console.log('finished');
    setValue('finishedDate', new Date());
    if (resource.autoApprove) {
      console.log('approved');
      setValue('approvedDate', new Date());
      setValue('approvedBy', resource.createdBy);
    }

    //formResponseId should only be set if quiz response is successful
    console.log('formResponseId', data?.id);
    setValue('formResponseId', data?.id);
    clearStore();
    onFinished();
  };
  const handleScreenout: OnWizardScreenout = ({ submit }) => {
    submit();
  };
  const stepsBefore = [
    {
      label: 'Learn',
      icon: faGraduationCap,
      Component: ResourcePage
    }
  ];
  const confirmationSteps = [];
  if (resource.confirmationTypes.includes('Checkbox')) {
    confirmationSteps.push({
      label: 'Checkbox',
      Component: CheckboxContent,
      icon: faCheckSquare
    });
  }
  if (resource.confirmationTypes.includes('Signature')) {
    confirmationSteps.push({
      label: 'Signature',
      Component: SignatureContent,
      icon: faSignature
    });
  }
  const steps = [...stepsBefore, ...confirmationSteps];
  const Wizard = () =>
    resource.formId ? (
      <WizardWithQuiz
        readOnly={review}
        allowPrev={review || undefined}
        stepsBefore={stepsBefore}
        stepsAfter={confirmationSteps}
        resource={resource}
        index={index}
        onSubmit={handleSubmit}
        onScreenout={handleScreenout}
        data={data}
        recaptcha={false}
      />
    ) : (
      <FormWizard
        readOnly={review}
        allowPrev={review || undefined}
        onSubmit={handleSubmit}
        data={data}
        fieldArray={{
          data: steps,
          label: field => field.label,
          icon: field => field.icon,
          output: field => {
            return <field.Component resource={resource} index={index} />;
          },
          onSubmit: (field, vals, done) =>
            field.onSubmit ? field.onSubmit(done, vals) : done()
        }}
      />
    );
  if (isFailed) {
    return (
      <Card>
        <Screenedout
          height={'20vh'}
          title="Failed"
          description="The answers you gave indicated you might not have understood the resource. Please review the resource and try again"
          button={<Button onClick={onFinished}>Ok</Button>}
        />
      </Card>
    );
  }
  return (
    <div className="position-relative">
      <Wizard />
      <CloseButton
        className="bg-200 btn-close end-0 m-2 p-2 position-absolute rounded-circle top-0"
        onClick={onFinished}
      />
    </div>
  );
};
