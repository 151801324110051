import React from 'react';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import {
  Employee,
  EmployeeTrainingCourse,
  removeEmployeeResourceGroup,
  Screening
} from 'apis/flex/hr';
import useEmployees from './hooks/useEmployees';
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faObjectGroup,
  faObjectUngroup,
  faPassport,
  faTimesCircle,
  faUserClock
} from '@fortawesome/free-solid-svg-icons';
import { NewPrescreeningModal } from '../prescreening/NewPrescreening';
import DomainTable, { DomainTableProps } from 'components/common/DomainTable';
import DomainAvatar from 'components/common/DomainAvatar';
import blankUser from 'assets/img/team/avatar.png';
import useUsers from 'components/app/users/hooks/useUsers';
import { User } from 'components/app/users/types';
import { ResponsiveModal } from 'components/common/Modals';
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'react-bootstrap';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import { SelectedEmployees } from './widgets/EmployeeSelector';
import { DomainForm } from 'components/common/DomainForm';
import DependancySelect from 'components/common/DependancySelect';
import ActionFooter from 'components/common/ActionFooter';
import { FormProvider, useForm } from 'react-hook-form';
import useEmployeeResourceGroups from './hooks/useEmployeeResourceGroups';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'apis/errors';
import usePrescreening from '../prescreening/hooks/usePrescreening';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomTooltip from 'components/common/Tooltip';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import { camelToSentence } from 'helpers/utils';
import DomainBadge from 'components/common/DomainBadge';
import DomainStatusBadge, {
  domainStatusColumn,
  domainStatusText,
  formatDomainStatus,
  StateDef
} from 'components/common/DomainStatusBadge';

type EmployeeWithUser = Employee & { user: User };
export const EmployeesTable = (
  props: Partial<DomainTableProps<Employee, Employee, EmployeeWithUser>>
) => {
  const [showCheck, setShowCheck] = React.useState<number[]>(null);
  const { data: userLookup } = useUsers({
    useFilter: true,
    columns: ['employeeId', 'id', 'newStarterResponseId'],
    select: d => {
      return new Map(d.map(d => [d.employeeId, d]));
    }
  });
  const [showRgSelector, setShowRgSelector] = React.useState(false);
  const [selectedEmployees, setSelectedEmployees] = React.useState<number[]>(
    []
  );
  const [showRmRgModal, setShowRmRgModal] = React.useState(false);
  const screeningStates: Record<string, StateDef<Screening>> = {
    needsApproval: {
      icon: faExclamationTriangle,
      color: 'warning',
      iconUnicode: '26A0'
    },
    notSubmitted: {
      icon: faUserClock,
      color: 'secondary',
      iconUnicode: '1F553',
      authorField: null
    }
  };
  return (
    <>
      <DomainTable
        domain="employee"
        crudHook={useEmployees}
        canClone={false}
        refreshTrigger={userLookup}
        onDataLoaded={d =>
          d.map(d => ({
            ...d,
            user: userLookup?.get(d.id)
          }))
        }
        stateTabs={state => ({
          tableProps: p =>
            state?.includes('started') && {
              columns: [
                ...p.columns,
                {
                  id: 'newStarterResponseId',
                  header: 'New starter form',
                  type: 'boolean',
                  accessorFn: d => !!d.user?.newStarterResponseId,
                  formatter: (_, d) => (
                    <DomainStatusBadge
                      domain="form-response"
                      item={{
                        id: d.user?.newStarterResponseId,
                        state: d.user?.newStarterResponseId
                          ? 'complete'
                          : 'incomplete'
                      }}
                    />
                  )
                }
              ]
            }
        })}
        globalActions={[
          {
            name: 'Request screening',
            actionFn: async rows =>
              setShowCheck(rows.map(row => Number(row.original.id))),
            icon: faPassport
          },
          {
            name: 'Add to resource groups',
            actionFn: async rows => {
              setSelectedEmployees(rows.map(row => Number(row.original.id)));
              setShowRgSelector(true);
            },
            icon: faObjectGroup
          },
          {
            name: 'Remove from resource group',
            actionFn: async rows => {
              setSelectedEmployees(rows.map(row => Number(row.original.id)));
              setShowRmRgModal(true);
            },
            icon: faObjectUngroup
          }
        ]}
        columns={[
          'id',
          { id: 'userId', domain: null },
          {
            id: 'avatar',
            enableColumnFilter: false,
            maxSize: 50,
            header: '',
            formatter: v => (
              <DomainAvatar size="sm" src={v() || blankUser} domain="user" />
            )
          },
          'firstName',
          'surname',
          'onboardDate',
          'email',
          'mobileNumber',
          { id: 'managerId', domain: 'user', header: 'Manager' },
          'departmentId',
          'contractId',
          'jobTitleId',
          {
            id: 'resourceGroups',
            domain: 'resource-group',
            accessorFn: d => d.resourceGroups.map(d => d.resourceGroupId)
          },
          domainStatusColumn('screening', 'employee-screening', {
            states: screeningStates
          }),
          // {
          //   id: 'screening',
          //   accessorFn: d =>
          //     formatDomainStatus({
          //       domain: 'employee-screening',
          //       item: d.screening,
          //       states: screeningStates
          //     }).text,
          //   formatter: (_, d) => {
          //     if (!d.screening) return '';
          //     return (
          //       <DomainStatusBadge
          //         domain="employee-screening"
          //         item={d.screening}
          //         states={screeningStates}
          //       />
          //     );
          //   },
          //   download: (_, v) =>
          //     domainStatusText({
          //       domain: 'employee-screening',
          //       item: v.screening,
          //       states: screeningStates
          //     })
          // },
          domainStatusColumn<EmployeeTrainingCourse, Employee>(
            'onboardingTraining',
            'employee-training',
            {
              itemAccessor: d => d.onboardingTraining?.[0]
            }
          )
        ]}
        {...props}
      >
        <AdvanceTable />
      </DomainTable>
      <NewPrescreeningModal
        show={!!showCheck}
        defaultValues={{ employeeIds: showCheck }}
        setShow={setShowCheck}
      />
      <ResourceGroupModal
        show={showRgSelector}
        setShow={setShowRgSelector}
        employeeIds={selectedEmployees}
        isRemove={false}
      />
      <ResourceGroupModal
        show={showRmRgModal}
        setShow={setShowRmRgModal}
        employeeIds={selectedEmployees}
        isRemove={false}
      />
    </>
  );
};
const ResourceGroupModal = ({ show, setShow, employeeIds, isRemove }) => {
  const methods = useForm();
  const { bulkAdd, isBulkAdding } = useEmployeeResourceGroups({ meta: false });
  const { mutate: remove, isLoading: isRemoving } = useMutation<
    void,
    ApiError,
    { resourceGroupId: number; employeeIds: number[] }
  >({
    mutationFn: ({ resourceGroupId, employeeIds }) =>
      removeEmployeeResourceGroup(resourceGroupId, employeeIds)
  });
  const handleSubmit = methods.handleSubmit(v => {
    if (isRemove)
      remove(
        { resourceGroupId: v.resourceGroupIds[0], employeeIds },
        {
          onSuccess: () => setShow(false)
        }
      );
    bulkAdd(
      employeeIds.flatMap(id =>
        v.resourceGroupIds.map(r => ({ employeeId: id, resourceGroupId: r }))
      ),
      {
        onSuccess: () => setShow(false)
      }
    );
  });
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <ModalHeader closeButton>
        <ModalTitle>
          {isRemove ? 'Remove from' : 'Add to'} resource group
          {isRemove ? 's' : ''}
        </ModalTitle>
      </ModalHeader>
      <FormProvider {...methods}>
        <ModalBody>
          <div>
            <h6>Selected employees</h6>
            <SelectedEmployees ids={employeeIds} />
          </div>
          <ResourceGroupSelector
            multiple={!isRemove}
            name="resourceGroupIds"
            label={isRemove ? 'Resource group' : 'Resource groups'}
          />
        </ModalBody>
      </FormProvider>
      <ModalFooter>
        <ActionFooter
          onCancel={() => setShow(false)}
          isLoading={isRemoving || isBulkAdding}
          onSubmit={handleSubmit}
          submitText={isRemove ? 'Remove' : 'Add'}
          variant={isRemove ? 'danger' : 'primary'}
        />
      </ModalFooter>
    </ResponsiveModal>
  );
};
const Employees = () => {
  return <EmployeesTable />;
};
export default Employees;
