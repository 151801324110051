import React from 'react';
import ApplicantsTable from './applicants/ApplicantsTable';

export default () => {
  return (
    <>
      <ApplicantsTable showAll />
    </>
  );
};
