import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import Flex from 'components/common/Flex';
import FixedScheduleSelector from 'components/app/hr/shifts/fixedSchedules/FixedScheduleSelector';
import { Contract, EmployeeContract } from 'apis/flex/hr';

export const ContractPdf = () => {
  return <WizardInput type="document" name="document" />;
};
export const ContractPay = () => {
  const basicType = useWatch({ name: 'basicPeriod' });
  const minimumWage = useWatch({ name: 'minimumWage' });
  const livingWage = useWatch({ name: 'livingWage' });
  const { setValue } = useFormContext();
  return (
    <>
      <WizardInput
        type="radio"
        name="basicPeriod"
        label="Basic pay type"
        registerProps={{
          required: false
        }}
        options={['hourly', 'monthly', 'annual']}
      />
      {basicType && (
        <>
          <WizardInput
            type="currency"
            name="basicRate"
            registerProps={{
              required: false,
              onChange: () => {
                setValue('minimumWage', false);
                setValue('livingWage', false);
              }
            }}
          />
          <Flex alignItems={'center'}>
            <WizardInput
              type="switch"
              name="minimumWage"
              label="National minimum wage"
              registerProps={{
                required: false,
                onChange: () => {
                  setValue('basicRate', '');
                  setValue('livingWage', false);
                }
              }}
            />
            {minimumWage && (
              <WizardInput
                type="number"
                registerProps={{ required: false }}
                name="wageAddition"
                hideLabel
                formGroupProps={{ className: 'ms-2 p-0 fs--1' }}
                formControlProps={{ min: 0, max: 100 }}
                prefix="+"
                suffix="%"
              />
            )}
          </Flex>
          <Flex alignItems={'center'}>
            <WizardInput
              type="switch"
              name="livingWage"
              label="National living wage"
              registerProps={{
                required: false,
                onChange: () => {
                  setValue('basicRate', '');
                  setValue('minimumWage', false);
                }
              }}
            />
            {livingWage && (
              <WizardInput
                type="number"
                formGroupProps={{ className: 'ms-2 p-0 fs--1' }}
                registerProps={{ required: false }}
                name="wageAddition"
                hideLabel
                formControlProps={{ min: 0, max: 100 }}
                prefix="+"
                suffix="%"
              />
            )}
          </Flex>
        </>
      )}
    </>
  );
};
export const ContractInfo = () => {
  return (
    <>
      <WizardInput type="text" name="name" />
      <WizardInput
        registerProps={{ required: false }}
        type="switch"
        name="isActive"
      />
    </>
  );
};
export const ContractHours = () => {
  const scheduleType = useWatch({ name: 'scheduleType' });
  return (
    <>
      <WizardInput
        type="number"
        name="minWeeklyHours"
        label="Minimum hours p/week"
      />
      <WizardInput
        type="radio"
        name="scheduleType"
        label="Schedule type"
        registerProps={{
          required: false
        }}
        options={[
          {
            value: 'fixed',
            label: 'Fixed',
            description:
              'Employees are required to work the same schedule of hours every week.'
          },
          {
            value: 'flexible',
            label: 'Flexible',
            description:
              'Employees have no fixed shift times. Shifts will be requested by employees on an advance 2-week basis.'
          },
          {
            value: 'semi-flexible',
            label: 'Semi-flexible',
            description:
              'Employees are required to work the same schedule of hours every week, but have the option to work additional hours on an advance 2-week basis.'
          },
          {
            value: 'none',
            label: 'None',
            description:
              'Employees have no working hours. As long as they ahdere to the minimum hours per week, they can work at any time.'
          }
        ]}
      />
      {(scheduleType === 'fixed' || scheduleType === 'semi-flexible') && (
        <FixedScheduleSelector label="Schedule" name="fixedScheduleId" />
      )}
    </>
  );
};
export const isContractFlexible = (contract: Contract | EmployeeContract) => {
  return (
    (contract?.scheduleType === 'flexible' ||
      contract?.scheduleType === 'semi-flexible') &&
    //not ready to release this yet
    false
  );
};
export const ContractForm = () => {
  return (
    <>
      <ContractInfo />
      <ContractPdf />
      <ContractHours />
      <ContractPay />
    </>
  );
};
