import React, { useCallback, useState } from 'react';
import Unallocated, { useUnallocated } from './Unallocated';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useWatch } from 'react-hook-form';
import { ShiftPlan } from 'apis/flex/hr';
import Allocated from './Allocated';
import { FormCheck } from 'react-bootstrap';
import SettingsBox from 'components/common/SettingsBox';
import useShifts from '../../shifts/useShifts';
import Skeleton from 'react-loading-skeleton';
import { areIntervalsOverlapping } from 'date-fns';
import { getDateFromTime } from 'helpers/dates';
import { useInputConfig } from 'components/wizard/InputConfig';

export default () => {
  const props = useInputConfig();
  const readOnly = props?.readOnly;
  const fieldArray = useFieldArray<ShiftPlan, 'allocations'>({
    name: 'allocations'
  });
  const { unallocated, isLoading } = useUnallocated();
  const { data: shiftsLookup, isLoading: shiftsLoading } = useShifts({
    useFilter: true,
    staleTime: Infinity,
    select: shifts => new Map(shifts.map(s => [s.id, s])),
    id: fieldArray.fields
      .map(a => a.shiftId)
      .concat(unallocated.map(a => a.shiftId))
  });
  const shiftPlanId = useWatch({ name: 'id' });
  const matchShift = result => a =>
    a.employeeId === Number(result.draggableId.split('-')[0]) &&
    a.shiftId === Number(result.draggableId.split('-')[1]);
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const employeeAllocated = fieldArray.fields
        .map((a, i) => ({ ...a, index: i }))
        .filter(matchShift(result));
      if (result.destination.droppableId === 'unallocated') {
        let conf = true;
        if (result.source.droppableId === 'allocated') {
          conf = confirm(
            "This will remove all allocations for this employee's shift. Are you sure?"
          );
        }
        if (conf) {
          fieldArray.remove(employeeAllocated.map(a => a.index));
        }
        return;
      }
      if (result.destination.droppableId === 'allocated') {
        let cont = true;
        const employeeUnallocated = unallocated.filter(matchShift(result));
        if (result.source.droppableId === 'unallocated') {
          const employeeAllocated = fieldArray.fields.filter(a =>
            matchShift(result)(a)
          );
          const overlapping = employeeAllocated.filter(a => {
            const overlap = employeeUnallocated.filter(b =>
              areIntervalsOverlapping(
                {
                  start: getDateFromTime(shiftsLookup.get(a.shiftId).startTime),
                  end: getDateFromTime(shiftsLookup.get(a.shiftId).endTime)
                },
                {
                  start: getDateFromTime(shiftsLookup.get(b.shiftId).startTime),
                  end: getDateFromTime(shiftsLookup.get(b.shiftId).endTime)
                }
              )
            );
            return overlap.length > 0;
          });
          if (overlapping.length) {
            cont = confirm(
              'This shift overlaps with ' +
                overlapping.length +
                ' allocated one(s). If you continue, they will be replaced with this one. Continue?'
            );
          }
        }
        if (cont) {
          fieldArray.append(
            employeeUnallocated.map(a => ({
              ...a,
              shiftPlanId,
              isLocked: false
            }))
          );
        }
      }
    },
    [unallocated]
  );
  const [showForecasted, setShowForecasted] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  return (
    <>
      <SettingsBox title="Settings">
        <FormCheck
          label="Show forecasted"
          id="show-forecasted"
          checked={showForecasted}
          onChange={() => setShowForecasted(!showForecasted)}
        />
        <FormCheck
          label="Show preferences"
          id="show-preferences"
          checked={showPreferences}
          onChange={() => setShowPreferences(!showPreferences)}
        />
      </SettingsBox>
      {isLoading || shiftsLoading ? (
        <Skeleton width={'100%'} height={300} count={2} />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <div>
            <Unallocated
              showForecasted={showForecasted}
              showPreferences={showPreferences}
              fieldArray={fieldArray}
              readOnly={readOnly}
            />
            <Allocated {...fieldArray} readOnly={readOnly} />
          </div>
        </DragDropContext>
      )}
    </>
  );
};
