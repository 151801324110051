import React from 'react';
import { useEffect, useState } from 'react';
import { EventName, EventPrefix, Notification } from 'apis/flex/notifications';
import useNotifications, {
  useNotificationEventCounts
} from './useNotifications';
import { CrudFilters } from 'hooks/defaultCrud/useDefaultCrud';
import { ensureArray } from 'helpers/utils';
const hasRunOnItem: number[] = [];
export const useNotificationAction = ({
  domain,
  eventName,
  seen,
  actioned,
  itemId,
  id
}: {
  id?: number | number[];
  eventName?: EventName | EventName[];
  seen?: boolean;
  actioned?: boolean;
  itemId?: number;
  domain?: EventPrefix;
} = {}) => {
  const filters: CrudFilters<Notification> = [
    {
      first: 'eventName',
      second: eventName
    },
    {
      first: 'itemId',
      second: itemId
    },
    {
      first: 'domain',
      second: domain
    }
  ];
  if (seen)
    filters.push({
      first: 'seen',
      second: 'null'
    });
  // if (actioned)
  filters.push({
    first: 'actioned',
    second: 'null'
  });
  const { refetch } = useNotificationEventCounts();
  const { bulkUpdate, data } = useNotifications({
    filters,
    id,
    useFilter: !!(eventName || itemId || id || domain),
    columns: ['actioned', 'seen', 'id', 'domain', 'eventName', 'itemId'],
    silent: true,
    invalidate: ['UserNotifications'],
    afterSave: () => {
      refetch();
    }
  });
  const handleUpdate =
    (field: string) =>
    ({
      id: _id = id,
      eventName: _eventName = eventName,
      itemId: _itemId = itemId,
      domain: _domain = domain
    }: {
      id?: number[] | number;
      eventName?: EventName | EventName[];
      itemId?: number | number[];
      domain?: EventPrefix;
    } = {}) => {
      if (!data) return;
      const ids =
        ensureArray(_id) ||
        data
          .filter(
            i =>
              (!_eventName || ensureArray(_eventName).includes(i.eventName)) &&
              (!_itemId || ensureArray(_itemId).includes(i.itemId)) &&
              (!_domain || ensureArray(_domain).includes(i.domain)) &&
              !hasRunOnItem.includes(i.id) &&
              !i[field]
          )
          .map(i => i.id);
      // console.log('handleUpdate', {
      //   id,
      //   _eventName,
      //   _itemId,
      //   _domain,
      //   ids,
      //   data
      // });
      if (ids?.length === 0) return;
      bulkUpdate({
        ids,
        data: { [field]: new Date() }
      });
      hasRunOnItem.push(...ids);
    };
  const handleSeen = handleUpdate('seen');
  const handleActioned = handleUpdate('actioned');

  return {
    setSeen: handleSeen,
    setActioned: handleActioned
  };
};
export default ({
  eventName,
  seen,
  actioned,
  itemId
}: {
  eventName: EventName | EventName[];
  seen?: boolean;
  actioned?: boolean;
  itemId?: number;
}) => {
  const { setActioned, setSeen } = useNotificationAction({
    eventName,
    seen,
    actioned,
    itemId
  });
  useEffect(() => {
    if (seen) {
      setSeen();
    }
    if (actioned) {
      setActioned();
    }
  }, [seen, actioned, itemId]);
  return <></>;
};
