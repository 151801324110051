import { ErrorComponentLayout } from 'layouts/ErrorLayout';
import React, { useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import useMailerPreferences from './useMailerPreferences';
import LoadingButton from 'components/common/LoadingButton';
import Success from 'components/wizard/Success';
import useEncryptedParams from 'hooks/useEncryptedParams';
import Error500 from 'components/errors/Error500';
import PageLoader from 'components/common/PageLoader';
import { ensureArray } from 'helpers/utils';

export default () => {
  const { data: params, isLoading } = useEncryptedParams();
  const { add, isAdding } = useMailerPreferences({
    afterSave: () => setState('added')
  });
  const [state, setState] = useState<string>();
  const email = ensureArray(params?.email)?.[0];
  const [hasChecked, setHasChecked] = useState(false);
  return (
    <ErrorComponentLayout>
      <Card>
        <Card.Body className="px-4 py-5">
          {/* <Card.Header>
          <Card.Title>Unsubscribe</Card.Title>
        </Card.Header> */}
          {!!isLoading && !!params ? (
            <PageLoader height={400} />
          ) : !!params && !params.email ? (
            <Error500
              error={{
                message: 'Could not get email from params',
                name: 'Name_missing'
              }}
            />
          ) : state === 'added' ? (
            <Success title="Unsubscribed!">
              Your email <b>{email}</b> has successfully unsubscribed. You will
              no longer receive any emails from us.
            </Success>
          ) : (
            <>
              <p>
                You are about to unsubscribe your email address, {email}, from
                receving emails from the Flex platform.
              </p>
              <p className="mb-4">
                <b>
                  This could include emails from your employer, if they use the
                  Flex platform, so please check with them first before
                  continuing.
                </b>
              </p>
              <FormCheck
                type="checkbox"
                className="mb-0"
                id="hasChecked"
                checked={hasChecked}
                onChange={() => setHasChecked(!hasChecked)}
                label="I have confirmed with my employer that I will no longer receive emails from Flex"
              />
              {!state && (
                <LoadingButton
                  variant="danger"
                  disabled={!hasChecked}
                  className="w-100 mt-2"
                  onClick={() =>
                    add({
                      email: email,
                      notificationId: params.notificationId,
                      type: 'blacklist'
                    })
                  }
                  loading={!email || isAdding}
                >
                  Unsubscribe
                </LoadingButton>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </ErrorComponentLayout>
  );
};
