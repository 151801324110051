import PropTypes from 'prop-types';
const Wrapper = ({ condition = true, wrapper, elseWrapper = null, children }) =>
  condition
    ? wrapper(children)
    : elseWrapper
    ? elseWrapper(children)
    : typeof children === 'function'
    ? children()
    : children;
Wrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  falseWrapper: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};
export default Wrapper;
