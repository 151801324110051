import React, { memo, ReactNode, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
  Draggable,
  DraggableProps,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableRubric,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import useMainRef from 'hooks/useMainRef';
import { AllocationEntry } from 'apis/flex/projects';
import DragHandle from './DragHandle';

export default ({
  children,
  index,
  draggableId,
  dragHandle,
  container = (p, c) => (
    <div
      {...p}
      className={classNames(
        { 'd-inline-flex w-100 align-items-center gap-1': !!dragHandle },
        p.className
      )}
    >
      {c}
    </div>
  ),
  whileDraggingProps,
  whileDragging,
  ...rest
}: {
  container?: (props: any, children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode | DraggableProps['children'];
  index: number;
  draggableId: string;
  dragHandle?:
    | boolean
    | ((props: DraggableProvidedDragHandleProps) => ReactNode);
  whileDraggingProps?: (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => { [key: string]: any };
  whileDragging?: (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => ReactNode;
} & Omit<
  DraggableProps,
  'children' | 'index' | 'draggableId' | 'dragHandle'
>) => {
  const invokeChildren = useCallback(
    (provided, s, rubric) =>
      typeof children === 'function' ? children(provided, s, rubric) : children,
    [children]
  );
  const ref = useMainRef();
  const Item = memo(
    ({
      provided,
      snapshot: s,
      rubric
    }: {
      provided: DraggableProvided;
      snapshot: DraggableStateSnapshot;
      rubric?: DraggableRubric;
    }) => {
      const usePortal = s.isDragging;

      const innerChildren = (
        <>
          {dragHandle ? (
            <>
              {/* <> */}
              {typeof dragHandle === 'boolean' ? (
                <DragHandle dragHandleProps={provided.dragHandleProps} />
              ) : (
                dragHandle(provided.dragHandleProps)
              )}
              {invokeChildren(provided, s, rubric)}
              {/* </> */}
            </>
          ) : (
            <div
              {...provided.dragHandleProps}
              className={classNames({
                'cursor-grabbing': s.isDragging,
                'cursor-grab': !s.isDragging
              })}
            >
              {invokeChildren(provided, s, rubric)}
            </div>
          )}
        </>
      );
      const extraProps = s.isDragging ? whileDraggingProps?.(provided, s) : {};
      const allProps = {
        ref: provided.innerRef,
        ...provided.draggableProps,
        ...extraProps,
        style: {
          ...provided.draggableProps.style,
          minWidth: '100px',
          ...extraProps?.style
        },
        className: classNames(
          {
            // 'd-flex': dragHandle === true
          },
          extraProps?.className
        )
      };
      const child = useMemo(
        () =>
          whileDragging && s.isDragging
            ? whileDragging(provided, s)
            : typeof children === 'function'
            ? children(provided, s, rubric)
            : container(allProps, innerChildren),
        [provided, s, children]
      );
      if (!usePortal) {
        return child;
      }

      // if dragging - put the item in a portal
      return createPortal(child, ref.current);
    }
  );
  return (
    <Draggable index={index} draggableId={draggableId} {...rest}>
      {(provided, s, rubric) => {
        return <Item provided={provided} snapshot={s} rubric={rubric} />;
      }}
    </Draggable>
  );
};
