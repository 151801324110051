import { EmployeeShift, EmployeeShiftChange } from 'apis/flex/hr';
import DomainTable, { RemoteDomainTable } from 'components/common/DomainTable';
import React from 'react';
import { CrudFilters } from 'hooks/defaultCrud/useDefaultCrud';
import { DomainForm } from 'components/common/DomainForm';
import WizardInput from 'components/wizard/WizardInput';
import { useGuard } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';
import {
  Action,
  BulkAction
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import { AvailableDayShiftsPicker } from '../ShiftsPicker';
import { formatDateToISO } from 'helpers/dates';
import { AvailableShiftsSelector } from '../ShiftSelector';
import useEmployeeShifts, {
  useEmployeeShiftChanges
} from './useEmployeeShifts';
import EmployeeShiftWizard from './EmployeeShiftWizard';
import { ResponsiveModal } from 'components/common/Modals';
import { Alert, Modal } from 'react-bootstrap';
import { getShiftUnits } from 'helpers/validation/validate';

const shiftChangeAction =
  ({
    canEdit,
    isSelf
  }: {
    canEdit: boolean;
    isSelf: (r: EmployeeShift) => boolean;
  }) =>
  (vals: Partial<EmployeeShiftChange> = {}): BulkAction<EmployeeShift> => {
    return {
      name: r =>
        vals.isToCancel
          ? r.every(r => isSelf(r.original))
            ? 'Request cancellation'
            : 'Cancel Shift'
          : r.every(r => isSelf(r.original))
          ? 'Request change'
          : 'Change Shift',
      show: r => r.every(r => isSelf(r.original)) || canEdit,
      icon: vals.isToCancel ? 'times' : 'sync-alt',
      modalOnClick: ({ rows, hide }) => {
        return (
          <DomainForm<EmployeeShiftChange, EmployeeShiftChange>
            itemId={null}
            onFinished={hide}
            domain="employee-shift-change"
            submitText="Send"
            saveMutator={d =>
              rows.map(row => ({
                ...d,
                employeeId: row.getValue('employeeId'),
                employeeShiftId: row.original.id,
                ...vals
              }))
            }
          >
            {!vals.isToCancel && (
              <>
                <AvailableShiftsSelector
                  name="shiftId"
                  label="Shift"
                  multiple={false}
                  startDate={
                    new Date(
                      Math.min(
                        ...rows.map(row =>
                          new Date(row.getValue<Date>('date')).valueOf()
                        )
                      )
                    )
                  }
                  endDate={
                    new Date(
                      Math.max(
                        ...rows.map(row =>
                          new Date(row.getValue<Date>('date')).valueOf()
                        )
                      )
                    )
                  }
                  availableTo={{
                    employeeId: rows.map(row =>
                      row.getValue<number>('employeeId')
                    ),
                    resourceGroupId: rows.map(row =>
                      row.getValue<number>('resourceGroupId')
                    )
                  }}
                />
              </>
            )}
            <WizardInput
              registerProps={{ required: false }}
              name="reason"
              label="Reason"
              type={
                rows.every(r => isSelf(r.original)) ? 'textarea' : 'crudList'
              }
              pluginProps={{
                crudHook: useEmployeeShiftChanges
              }}
            />
          </DomainForm>
        );
      }
    };
  };

export default ({ filters }: { filters?: CrudFilters<EmployeeShift> }) => {
  const { canEdit } = useGuard({ roles: ['employee'] });
  const { employeeId } = useUser();
  const isSelf = (r: EmployeeShift) => r.employeeId === employeeId;
  const getAction = shiftChangeAction({ canEdit, isSelf });
  const [showModal, setShowModal] = React.useState<boolean | number[]>(false);
  const now = formatDateToISO(new Date());
  return (
    <>
      <RemoteDomainTable
        crudHook={useEmployeeShifts}
        title="Shifts"
        domain="employee-shift"
        canDelete={false}
        canClone={false}
        onNewClick={() => setShowModal(true)}
        onRowClick={row => setShowModal([row.original.id])}
        onEditClick={rows => setShowModal(rows.map(r => r.original.id))}
        tabs={[
          {
            label: 'Upcoming',
            crudFilter: {
              first: 'date',
              comparitor: '>=',
              second: now
            }
          },
          {
            label: 'Past',
            crudFilter: {
              first: 'date',
              comparitor: '<',
              second: now
            }
          }
        ]}
        crudProps={{ filters }}
        columns={[
          'date',
          'resourceGroupId',
          'employeeId',
          'startTime',
          'endTime',
          { id: 'units', header: 'Units' },
          { id: 'breaks', visible: false }
        ]}
        globalActions={[getAction({ isToCancel: true }), getAction()]}
      />
      <ResponsiveModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <EmployeeShiftWizard
            itemId={typeof showModal === 'boolean' ? undefined : showModal}
            onFinished={() => setShowModal(false)}
          />
          <Alert variant="warning">
            When you add or update a shift, any overlapping shifts (those for
            this employee on this date) will be cancelled
          </Alert>
        </Modal.Body>
      </ResponsiveModal>
    </>
  );
};
