import React from 'react';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Ticket, TicketComment } from 'apis/flex/support';
import UserIcon from '../users/widgets/UserIcon';
import { format, formatDistance } from 'date-fns';
import { FileItem } from 'components/files/FileUploader';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import Skeleton from 'react-loading-skeleton';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import usePublicUsers from '../users/hooks/usePublicUsers';

const Mail = ({
  subject,
  message,
  index
}: {
  subject: string;
  message: TicketComment;
  index: number;
}) => {
  const { files, isLoading } = useFileLoader({ value: message.files });
  const user = useUser();
  const { data: createdBy } = usePublicUsers({
    id: message.createdBy,
    select: d => d[0]
  });
  return (
    <div>
      {/* mail header */}
      <div className="d-md-flex d-xxl-flex align-items-center justify-content-between mb-x1">
        <Flex alignItems="center" className="gap-2">
          <Link to={getDomainItemUrl('user', user, createdBy)}>
            <UserIcon
              size="2xl"
              showName
              domain="user"
              id={message.createdBy}
            />
          </Link>
        </Flex>
        <p className="mb-0 fs--2 fs-sm--1 fw-semi-bold">
          {format(new Date(message.createdDate), 'dd/MM/yyyy')}
          <span className="mx-1">|</span>
          <span className="fst-italic">
            {format(new Date(message.createdDate), 'HH:mm') +
              ' (' +
              formatDistance(new Date(message.createdDate), new Date(), {
                addSuffix: true
              }) +
              ')'}
          </span>
          {/* <FontAwesomeIcon icon="star" className="ms-2 text-warning" /> */}
        </p>
      </div>
      {/* mail body */}
      <div className={index !== 0 ? 'mb-5 pb-5 border-bottom' : ''}>
        <h6 className="mb-3 fw-semi-bold text-1000">{subject}</h6>
        <p dangerouslySetInnerHTML={{ __html: message.comment }} />
        {message.files?.length > 0 && (
          <div className="p-x1 bg-light rounded-3 mt-3">
            <div className="d-inline-flex flex-column">
              {isLoading
                ? message.files.map((_, i) => <Skeleton key={i} />)
                : files
                    ?.filter(Boolean)
                    .map(file => <FileItem key={file.id} file={file} />)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TicketComments = ({
  comments,
  ticket
}: {
  comments: TicketComment[];
  ticket: Ticket;
}) => {
  return (
    ticket &&
    comments && (
      <div>
        <Mail
          subject={ticket.title}
          message={{
            comment: ticket.description,
            createdBy: ticket.createdBy,
            createdDate: ticket.createdDate,
            files: ticket.files,
            id: undefined,
            ticketId: ticket.id
          }}
          index={0}
        />
        {comments.length > 0 && (
          <>
            <div className="my-5 position-relative text-center">
              <hr className="position-absolute top-50 border-300 w-100 my-0" />
              <span className="position-relative bg-white dark__bg-card-dark px-3 z-index-1">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="rounded-pill border-300 px-lg-5"
                >
                  {comments.length} Comments
                </Button>
              </span>
            </div>
            {comments
              // .sort((a, b) => a.id - b.id)
              .map((comment, index) => (
                <Mail
                  subject={ticket.title}
                  key={index}
                  message={comment}
                  index={index + 1}
                />
              ))}
          </>
        )}
      </div>
    )
  );
};

export default TicketComments;
