import React, { useEffect } from 'react';
import { Badge, Button, ButtonProps } from 'react-bootstrap';
import Divider from './Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { transitions } from 'helpers/animations';

export default ({
  children,
  label = (expanded: boolean) => `Show ${expanded ? 'Less' : 'More'}`,
  maxHeight = '2rem',
  buttonProps,
  closeOnClick = true
}: {
  children: React.ReactNode;
  label?: (expanded: boolean) => React.ReactNode;
  maxHeight?: number | string;
  //just for compatibility
  /**@deprecated */
  maxCharacters?: number;
  buttonProps?: ButtonProps;
  closeOnClick?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const ref = React.useRef(null);
  const innerRef = React.useRef(null);
  const [isBigger, setBigger] = useState(false);
  const innerHeight = innerRef.current?.offsetHeight;
  //observe size changes in children
  const resizeObserver = React.useRef<ResizeObserver | null>(null);
  useEffect(() => {
    if (!innerRef.current) return;
    resizeObserver.current = new ResizeObserver(() => {
      const refBox = ref.current?.getBoundingClientRect();
      const innerBox = innerRef.current?.getBoundingClientRect();
      const isBigger = innerBox?.height > refBox?.height;
      setBigger(isBigger);
      // console.log(
      //   'isBigger',
      //   ref.current,
      //   innerRef.current,
      //   refBox,
      //   innerBox,
      //   isBigger
      // );
    });
    resizeObserver.current.observe(innerRef.current);
    return () => {
      resizeObserver.current?.disconnect();
    };
  }, [innerRef.current, ref.current]);
  return (
    <>
      <motion.div
        ref={ref}
        onClick={() => {
          if (closeOnClick) setShow(!show);
        }}
        key={'expandable-paragraph'}
        transition={transitions.lightBounce}
        initial={{ height: maxHeight }}
        animate={{ height: show ? innerHeight : maxHeight }}
        style={{ overflow: 'hidden' }}
      >
        <div ref={innerRef}>{children}</div>
      </motion.div>
      {isBigger && (
        <Button
          variant="link"
          size="sm"
          className="fs--2 p-0"
          {...buttonProps}
          onClick={() => setShow(!show)}
        >
          {label(show)}
          <motion.div
            className="d-inline-block ms-1"
            initial={{ rotate: 0 }}
            animate={{ rotate: show ? 180 : 0 }}
          >
            <FontAwesomeIcon icon={faChevronDown} className="fs--2" />
          </motion.div>
        </Button>
      )}
    </>
  );
};
