import React, { ReactNode } from 'react';

// Lazy-loaded imports
const MainLayout = React.lazy(() => import('../layouts/MainLayout'));
// const Recruitment = React.lazy(() => import('components/app/recruitment'));
const Error404 = React.lazy(() => import('components/errors/Error404'));
const Quotes = React.lazy(() => import('components/app/pm/quotes'));
const Error500 = React.lazy(() => import('components/errors/Error500'));

const ReturnToProvider = React.lazy(
  () => import('components/return-to/ReturnToProvider')
);
const ReturnToList = React.lazy(
  () => import('components/return-to/ReturnToProvider')
);
const QuickLinks = React.lazy(() => import('components/common/QuickLinks'));

const PreviewPopout = React.lazy(
  () => import('components/common/customForms/Editor/CustomFormPreview')
);
const Landing = React.lazy(() => import('components/app/landing/Landing'));
const CustomFormResponses = React.lazy(
  () => import('components/common/customForms/Reporting/CustomFormResponses')
);
const Logout = React.lazy(
  () => import('components/authentication/card/Logout')
);
const ErrorLayout = React.lazy(() => import('layouts/ErrorLayout'));
const Error401 = React.lazy(() => import('components/errors/Error401'));
const Error403 = React.lazy(() => import('components/errors/Error403'));
const ViewUser = React.lazy(() => import('components/app/users/detail'));
const CustomFormPage = React.lazy(
  () => import('components/common/customForms/CustomFormPage')
);
// const Applicants = React.lazy(
//   () => import('components/app/recruitment/applicants')
// );
const NotificationRedirect = React.lazy(
  () => import('components/notification/NotificationRedirect')
);
const MyProfile = React.lazy(() => import('components/app/users/MyProfile'));
const GuidedTourProvider = React.lazy(
  () => import('components/tours/TourProvider')
);
const Contracts = React.lazy(
  () => import('components/app/documents/contracts/Contracts')
);
// const Courses = React.lazy(() => import('components/app/hr/training/courses'));
// const EditCourse = React.lazy(
//   () => import('components/app/hr/training/courses/detail/EditCourse')
// );
// const TrainingClientPage = React.lazy(
//   () => import('components/app/hr/training/client/TrainingClientPage')
// );
const TrainingAttempts = React.lazy(
  () => import('components/app/hr/training/courses/TrainingAttempts')
);
// const MyTraining = React.lazy(
//   () => import('components/app/hr/training/courses/MyTraining')
// );
const Editor = React.lazy(() => import('components/common/customForms/Editor'));
const ReviewAttempt = React.lazy(
  () => import('components/app/hr/training/courses/ReviewAttempt')
);
const PrescreeningTable = React.lazy(
  () => import('components/app/hr/prescreening/PrescreeningTable')
);
const PrescreeningDetail = React.lazy(
  () => import('components/app/hr/prescreening/PrescreeningDetail')
);
const CustomForms = React.lazy(() => import('components/common/customForms'));
const Departments = React.lazy(() => import('components/app/hr/departments'));
const DepartmentDetail = React.lazy(
  () => import('components/app/hr/departments/DepartmentDetail')
);
const JobRoles = React.lazy(() => import('components/app/hr/jobRoles'));
const JobRoleDetail = React.lazy(
  () => import('components/app/hr/jobRoles/JobRoleDetail')
);
const DomainRedirect = React.lazy(
  () => import('components/common/DomainRedirect')
);
const Support = React.lazy(() => import('components/app/support'));
const TicketDetail = React.lazy(
  () => import('components/app/support/TicketDetail')
);
const Mailer = React.lazy(() => import('components/mailer'));
const TemplateDetail = React.lazy(
  () => import('components/mailer/templates/TemplateDetail')
);
const ShortLinkRedirect = React.lazy(
  () => import('components/extra/ShortLinkRedirect')
);

// Non-lazy imports
import * as Sentry from '@sentry/react';
import { RolesAuthRoute } from './RolesAuthRoute';
import PrivateProvider from './PrivateProvider';
import { ModalProvider } from 'hooks/useModal';
import { settings } from '../config';
import companyConfig from 'companyConfig';
import {
  FiBell,
  FiBookOpen,
  FiBriefcase,
  FiCalendar,
  FiCheckSquare,
  FiClock,
  FiDollarSign,
  FiEdit,
  FiFilePlus,
  FiFileText,
  FiFolder,
  FiGrid,
  FiHeart,
  FiHelpCircle,
  FiHome,
  FiKey,
  FiList,
  FiLock,
  FiMonitor,
  FiPlusCircle,
  FiSettings,
  FiSpeaker,
  FiSun,
  FiUser,
  FiUserCheck,
  FiUserPlus,
  FiUsers
} from 'react-icons/fi';
import PipelineFormTester from 'components/app/recruitment/campaigns/create/PipelineFormTester';
import Recruitment from 'components/app/recruitment';
import Applicants from 'components/app/recruitment/campaigns/detail';
import Faq from 'components/documentation/Faq';
import ComingSoon from 'components/extra/ComingSoon';
import OneIDRedirect from 'components/app/hr/prescreening/OneIDRedirect';
import { RoleDomain } from 'apis/flex/users';
import Storage from 'components/app/settings/storage';
import MyTraining from 'components/app/hr/training/courses/MyTraining';
import TrainingClientPage from 'components/app/hr/training/client/TrainingClientPage';
import Courses from 'components/app/hr/training/courses';
import EditCourse from 'components/app/hr/training/courses/detail/EditCourse';
import Unsubscribe from 'components/notification/Unsubscribe';
import Clients from 'components/app/pm/clients';
import ClientDetail from 'components/app/pm/clients/ClientDetail';
import ResourceGroups from 'components/app/pm/projects/resourceGroups';
import ResourceGroupDetail from 'components/app/pm/projects/resourceGroups/ResourceGroupDetail';
import Shifts from 'components/app/hr/shifts';
import ShiftDetail from 'components/app/hr/shifts/ShiftDetail';
import AccessGroups from 'components/app/settings/accessGroups';
import AccessGroupDetail from 'components/app/settings/accessGroups/AccessGroupDetail';
import Projects from 'components/app/pm/projects';
import ProjectDetail from 'components/app/pm/projects/ProjectDetail';
import TargetGroupsTable from 'components/app/pm/projects/targetGroups/TargetGroupsTable';
import TargetGroupDetail from 'components/app/pm/projects/targetGroups/TargetGroupDetail';
import QuestionnairesTable from 'components/app/pm/projects/questionnaires/QuestionnairesTable';
import QuestionnaireDetail from 'components/app/pm/projects/questionnaires/QuestionnaireDetail';
import ApplicantDetail from 'components/app/recruitment/applicants/detail/ApplicantDetail';
import Employees from 'components/app/hr/staff';
import TargetGroups from 'components/app/pm/projects/targetGroups';
import ContractDetail from 'components/app/documents/contracts/detail/ContractDetail';
import Calendars from 'components/app/calendars';
import CalendarDetail from 'components/app/calendars/CalendarDetail';
import AllNotifications from 'components/app/landing/AllNotifications';
import FixedSchedules from 'components/app/hr/shifts/fixedSchedules';
import FixedSchedulesDetail from 'components/app/hr/shifts/fixedSchedules/FixedSchedulesDetail';
import MyCalendar from 'components/app/calendars/MyCalendar';
import MyScreening from 'components/app/hr/prescreening/MyScreening';
import PrivacyPolicy from 'components/pages/privacy-policy/PrivacyPolicy';
import TandC from 'components/pages/tandc/TandC';
import PublicLayout from 'layouts/PublicLayout';
import MyWork from 'components/app/hr/shifts/myWork/MyWork';
import EmployeeShifts from 'components/app/hr/shifts/employeeShifts/EmployeeShifts';
import EmployeeShiftChanges from 'components/app/hr/shifts/employeeShifts/changes/EmployeeShiftChanges';
import EmployeeMedicalTable from 'components/app/hr/staff/EmployeeMedicalTable';
import EmployeeMedicalDetail, {
  MyMedical
} from 'components/app/hr/staff/EmployeeMedicalDetail';
import EmployeePayrollTable from 'components/app/hr/staff/EmployeePayrollTable';
import EmployeePayrollDetail, {
  MyPayroll
} from 'components/app/hr/staff/EmployeePayrollDetail';
import LeaveTable from 'components/app/hr/leave/LeaveTable';
import LeaveDetail from 'components/app/hr/leave/LeaveDetail';
import Leave from 'components/app/hr/leave';
import LeaveTypeTable from 'components/app/hr/leave/LeaveTypeTable';
import LeaveTypeDetail from 'components/app/hr/leave/LeaveTypeDetail';
import UserGuides from 'components/app/support/userGuides/UserGuides';
import UserGuide from 'components/app/support/userGuides/UserGuide';
import EditUserGuide from 'components/app/support/userGuides/EditUserGuide';
import DevDashboard from 'components/app/dev/DevDashboard';
import MyLeave from 'components/app/hr/leave/MyLeave';
import Jobs from 'components/app/recruitment/Jobs';
import { ToastContainer } from 'react-toastify';
import LocationListener from './LocationListener';
import VisitTracker from 'components/app/analytics/VisitTracker';
import { Outlet } from 'react-router-dom';
import Footer, { FeedbackButton } from 'components/footer/Footer';
import Webhooks from 'components/app/settings/webhooks';
import WebhookDetail from 'components/app/settings/webhooks/WebhookDetail';
import PublicProviders from 'PublicProviders';
import ShiftPlanDetail from 'components/app/hr/shiftPlans/ShiftPlanDetail';
import ShiftPlans from 'components/app/hr/shiftPlans';
import Breaks from 'components/app/hr/shifts/breaks';
import BreaksDetail from 'components/app/hr/shifts/breaks/BreaksDetail';
import AllApplicants from 'components/app/recruitment/AllApplicants';

export const coreRoutes = [
  {
    element: <PublicProviders />,
    children: [
      {
        element: (
          <>
            <ToastContainer
              closeButton
              stacked
              icon={false}
              position={'bottom-left'}
              draggablePercent={50}
              containerId={'default'}
              limit={10}
            />
            <ToastContainer
              closeButton
              stacked
              position={'top-right'}
              draggablePercent={50}
              containerId={'notifications'}
              limit={10}
            />
            <LocationListener />
            {/* Remove this for now just because it seems to use a lot of server resource */}
            {/* <VisitTracker /> */}
            <Outlet />
            <Footer />
            <FeedbackButton />
          </>
        ),
        children: [
          {
            path: 's/:hash',
            element: <ShortLinkRedirect />
          },
          {
            path: 'oneid-rtw-redirect',
            element: <OneIDRedirect />
          },
          {
            path: 'unsubscribe',
            element: <Unsubscribe />
          },
          {
            path: 'jobs',
            element: <Jobs />
          },
          {
            path: 'privacy',
            element: (
              <PublicLayout>
                <PrivacyPolicy />
              </PublicLayout>
            )
          },
          {
            path: 'terms',
            element: (
              <PublicLayout>
                <TandC />
              </PublicLayout>
            )
          },
          {
            path: 'previewer',
            children: [
              {
                path: 'form',
                element: <PreviewPopout />
              },
              {
                path: 'form-pipeline/:formId',
                element: <PipelineFormTester />
              }
            ]
          },
          {
            path: 'respond',
            children: [
              {
                index: true,
                element: <CustomFormPage />
              },
              {
                path: ':id',
                element: <CustomFormPage />
              }
            ]
          },
          {
            path: 'logout',
            element: <Logout />
          },
          {
            path: '/',
            fullpath: '/',
            name: 'Home',
            element: (
              <PrivateProvider>
                <ReturnToProvider>
                  <GuidedTourProvider>
                    <ModalProvider>
                      <MainLayout />
                    </ModalProvider>
                    <ReturnToList />
                  </GuidedTourProvider>
                </ReturnToProvider>
              </PrivateProvider>
            ),
            children: [
              {
                index: true,
                element: <Landing />
              },

              {
                path: 'dev',
                roles: ['super'],
                menu: false,
                element: <DevDashboard />
              },
              {
                path: 'notifications/:id/:ids/:isMultiple?',
                element: <NotificationRedirect />
              },
              {
                path: 'domain/:domain/:id',
                element: <DomainRedirect />
              },
              {
                path: 'my',
                name: 'My HR',
                icon: <FiUser />,
                description:
                  'Everything related to you as an employee and user, including profile management, your work schedule, HR and more.',
                children: [
                  {
                    index: true,
                    element: <></>
                  },
                  {
                    name: 'Profile',
                    path: 'profile',
                    description: 'View and manage your user profile',
                    icon: <FiUser />,
                    element: <MyProfile />
                  },
                  {
                    menu: false,
                    name: 'Notifications',
                    path: 'notifications',
                    description: 'View all your notifications',
                    icon: <FiBell />,
                    element: <AllNotifications />
                  },
                  {
                    name: 'Schedule',
                    path: 'schedule',
                    description:
                      'View and manage your calendar events and schedule',
                    icon: <FiCalendar />,
                    element: <MyCalendar />
                  },
                  {
                    name: 'Payroll',
                    path: 'payroll',
                    description:
                      'Review and manage your payroll record, including your banking details.',
                    icon: <FiDollarSign />,
                    element: <MyPayroll />
                  },
                  {
                    name: 'Medical',
                    path: 'medical',
                    description:
                      'Review and manage your medical record, including current medications, allergies and emergency contacts.',
                    icon: <FiHeart />,
                    element: <MyMedical />
                  },
                  {
                    name: 'Screening',
                    path: 'screening',
                    description:
                      'Your outstanding and recent screening requests',
                    icon: <FiUserCheck />,
                    element: <MyScreening />
                  },
                  {
                    name: 'Work',
                    path: 'work',
                    description:
                      'Review and manage your recent and upcoming shifts, tell us your availability, see your productivity and more.',
                    icon: <FiDollarSign />,
                    element: <MyWork />
                  },
                  {
                    name: 'Leave',
                    path: 'leave',
                    description:
                      'Review and manage your recent and upcoming leave for sickness and holidays, make new leave requests and more.',
                    icon: <FiSun />,
                    children: [
                      {
                        index: true,
                        element: <MyLeave />
                      },
                      {
                        name: 'Request leave',
                        path: 'new',
                        element: <LeaveDetail />
                      },
                      {
                        name: 'View',
                        path: ':id',
                        element: <LeaveDetail />
                      }
                    ]
                  },
                  {
                    name: 'Training',
                    path: 'training',
                    description:
                      'View and complete training courses you are enrolled in, and enroll in new training courses',
                    children: [
                      {
                        index: true,
                        element: <MyTraining />
                      },
                      {
                        name: 'Take course',
                        path: ':userCourseId',
                        element: <TrainingClientPage />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'forms',
                name: 'Forms',
                roles: ['form'],
                description:
                  'Create and manage all forms used across the company',
                icon: <FiCheckSquare />,
                menu: false,
                children: [
                  {
                    index: true,
                    element: <CustomForms />
                  },
                  {
                    path: 'new',
                    name: 'Create a form',
                    icon: <FiPlusCircle />,
                    roles: ['form.create'],
                    element: <Editor />
                  },
                  {
                    path: ':id/responses',
                    name: 'Responses',
                    // roles: ['Forms'],
                    element: <CustomFormResponses />
                  },
                  {
                    path: ':id',
                    name: 'Edit',
                    roles: ['form.edit'],
                    element: <Editor />
                  }
                ]
              },
              {
                name: 'Resourcing',
                path: 'resourcing',
                icon: <FiGrid />,
                description:
                  'Manage resourcing for projects, employees and teams',
                roles: ['shift-plan', 'resource-group'],
                children: [
                  { index: true, element: <></> },
                  {
                    name: 'Shift Plans',
                    description:
                      'Manage, edit and release upcoming planned shifts including project/target group/employee allocations',
                    path: 'shift-plans',
                    roles: ['shift-plan'],
                    children: [
                      {
                        index: true,
                        element: <ShiftPlans />
                      },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <ShiftPlanDetail />
                      },
                      {
                        path: ':id',
                        name: 'View',
                        element: <ShiftPlanDetail />
                      }
                    ]
                  },

                  {
                    name: 'Resource Groups',
                    description:
                      'Manage the types of resources that can be utilised by projects',
                    path: 'resource-groups',
                    roles: ['resource-group'],
                    children: [
                      { index: true, element: <ResourceGroups /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <ResourceGroupDetail />
                      },
                      {
                        path: ':resourceGroupId',
                        name: 'View',
                        element: <ResourceGroupDetail />
                      }
                    ]
                  }
                ]
              },
              {
                name: 'HR',
                icon: <FiUsers />,
                path: 'hr',
                roles: [
                  'contract',
                  'training-course',
                  'recruitment-campaign',
                  'employee',
                  'contract'
                ],
                description:
                  'Recruitment, training, documentation, employee shift patterns and more..',
                children: [
                  {
                    index: true,
                    element: <></>
                  },
                  {
                    name: 'Contracts',
                    path: 'contracts',
                    roles: ['contract'],
                    icon: <FiFileText />,
                    description:
                      'Manage new and current staff contracts, including pay rates, working hours, signatures and more..',
                    children: [
                      {
                        index: true,
                        element: <Contracts />
                      },
                      {
                        name: 'New',
                        path: 'new',
                        icon: <FiFilePlus />,
                        description: 'Create a new contract',
                        roles: ['contract.create'],
                        element: <ContractDetail />
                      },
                      {
                        name: 'View',
                        path: ':contractId',
                        // description: 'View',
                        roles: ['contract'],
                        element: <ContractDetail />
                      }
                    ]
                  },
                  {
                    name: 'Training',
                    path: 'training',
                    roles: ['training-course'],
                    description:
                      'Train new skills, review HR and policy documents, administrate training courses and more..',
                    children: [
                      {
                        index: true,
                        element: <></>
                      },

                      {
                        name: 'Courses',
                        description: 'Manage new and current training courses',
                        path: 'courses',
                        roles: ['training-course'],
                        icon: <FiBookOpen />,
                        children: [
                          {
                            index: true,
                            element: <Courses />
                          },
                          {
                            name: 'New',
                            path: 'new',
                            icon: <FiBookOpen />,
                            roles: ['training-course.create'],
                            description: 'Create a new training course',
                            element: <EditCourse />
                          },
                          {
                            name: 'Edit',
                            path: ':id',
                            roles: ['training-course.edit'],
                            element: <EditCourse />
                          }
                        ]
                      },
                      {
                        name: 'Sessions',
                        path: 'sessions',
                        description:
                          "Review trainees' training sessions across all courses",
                        icon: <FiClock />,
                        children: [
                          {
                            index: true,
                            element: <TrainingAttempts />
                          },
                          {
                            name: 'Review',
                            path: ':id',
                            description:
                              "Review a user's training session, approve or fail their submissions",
                            element: <ReviewAttempt />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Recruitment',
                    icon: <FiUserPlus />,
                    path: 'recruitment',
                    roles: ['recruitment-campaign'],
                    fullpath: '/recruitment',
                    description:
                      'Review new applications, set new campaigns running, administrate academies and more..',
                    // element:<div></div>,
                    children: [
                      {
                        index: true,
                        name: 'Campaigns',
                        exact: true,
                        element: <Recruitment />
                      },
                      {
                        path: 'applicants',
                        name: 'All Applicants',
                        element: <AllApplicants />
                      },
                      {
                        path: ':campaignId',
                        name: 'View',
                        children: [
                          { index: true, element: <Applicants /> },
                          {
                            path: 'applicants/:applicantId',
                            name: 'View applicant',
                            element: <ApplicantDetail />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Time & Attendance',
                    roles: ['shift'],
                    path: 'ta',
                    description:
                      'Manage employee shifts, shift patterns, absence, lateness, leave and attendance',
                    icon: <FiClock />,
                    children: [
                      { index: true, element: <></> },
                      {
                        name: 'Shift Patterns',
                        description:
                          'Manage the shifts and shift patterns employees can work',
                        path: 'patterns',
                        roles: ['shift'],
                        children: [
                          { index: true, element: <Shifts /> },
                          {
                            path: 'new',
                            name: 'Create',
                            element: <ShiftDetail />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <ShiftDetail />
                          },
                          {
                            path: 'schedules',
                            name: 'Fixed schedules',
                            domain: 'fixed-schedule',
                            description:
                              'Manage schedules of fixed working hours available for employees on fixed or semi-flexible contracts',
                            children: [
                              { index: true, element: <FixedSchedules /> },
                              {
                                path: 'new',
                                name: 'Create',
                                element: <FixedSchedulesDetail />
                              },
                              {
                                path: ':id',
                                name: 'View',
                                element: <FixedSchedulesDetail />
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name: 'Breaks',
                        path: 'breaks',
                        roles: ['break'],
                        description:
                          'Manage the different paid breaks that can be allowed during shifts',
                        children: [
                          { index: true, element: <Breaks /> },
                          {
                            path: 'new',
                            name: 'Create',
                            element: <BreaksDetail />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <BreaksDetail />
                          }
                        ]
                      },
                      {
                        name: 'Leave',
                        path: 'leave',
                        roles: ['leave-request'],
                        description:
                          "Manage employees' outstanding and recent holiday requests, as well as sickness and other leave",
                        children: [
                          { index: true, element: <Leave /> },
                          {
                            path: 'new',
                            name: 'Create',
                            element: <LeaveDetail />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <LeaveDetail />
                          },
                          {
                            path: 'types',
                            name: 'Types',
                            roles: ['leave-type'],
                            children: [
                              { index: true, element: <LeaveTypeTable /> },
                              {
                                path: 'new',
                                name: 'Create',
                                element: <LeaveTypeDetail />
                              },
                              {
                                path: ':id',
                                name: 'View',
                                element: <LeaveTypeDetail />
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name: 'Shifts',
                        description:
                          "Manage employees' upcoming booked and requested shifts, as well as past shifts",
                        path: 'shifts',
                        roles: ['employee'],
                        children: [
                          { index: true, element: <EmployeeShifts /> },
                          // { path: 'new', name: 'Create', element: <ComingSoon /> },
                          // { path: ':id', name: 'View', element: <ComingSoon /> },
                          {
                            path: 'changes',
                            name: 'Shift changes',
                            domain: 'shift-change',
                            description:
                              'Manage shift changes that employees have requested',
                            children: [
                              {
                                index: true,
                                element: <EmployeeShiftChanges />
                              },
                              {
                                path: 'new',
                                name: 'Create',
                                element: <ComingSoon />
                              },
                              {
                                path: ':id',
                                name: 'View',
                                element: <ComingSoon />
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Company',
                    path: 'company',
                    roles: ['department', 'job-title', 'employee'],
                    icon: <FiHome />,
                    exact: true,
                    description:
                      'Manage the company structure, including departments, job roles and employees',
                    children: [
                      {
                        index: true,
                        element: <></>
                      },
                      {
                        name: 'Departments',
                        roles: ['department'],
                        path: 'departments',
                        icon: <FiUsers />,
                        children: [
                          {
                            index: true,
                            element: <Departments />
                          },
                          {
                            path: 'new',
                            name: 'Create',
                            roles: ['department.create'],
                            icon: <FiPlusCircle />,
                            element: <DepartmentDetail />
                          },
                          {
                            path: ':id',
                            name: 'Edit',
                            roles: ['department.edit'],
                            icon: <FiEdit />,
                            element: <DepartmentDetail />
                          }
                        ]
                      },
                      {
                        name: 'Job Roles',
                        path: 'roles',
                        roles: ['job-title'],
                        icon: <FiSettings />,
                        children: [
                          {
                            index: true,
                            element: <JobRoles />
                          },
                          {
                            path: 'new',
                            name: 'Create',
                            roles: ['job-title.create'],
                            icon: <FiPlusCircle />,
                            element: <JobRoleDetail />
                          },
                          {
                            path: ':id',
                            name: 'Edit',
                            roles: ['job-title.edit'],
                            icon: <FiEdit />,
                            element: <JobRoleDetail />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Staff',
                    path: 'staff',
                    icon: <FiUsers />,

                    roles: ['employee'],
                    description: 'Manage current and past staff members',
                    children: [
                      {
                        index: true,
                        element: <Employees />
                      },
                      {
                        path: 'medical',
                        description: 'View and update employee medical records',
                        name: 'Employee medical',
                        roles: ['employee-medical-record'],
                        children: [
                          {
                            index: true,
                            element: <EmployeeMedicalTable />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <EmployeeMedicalDetail />
                          }
                        ]
                      },
                      {
                        path: 'payroll',
                        description:
                          'View and update employee payroll and banking records',
                        name: 'Employee payroll',
                        roles: ['employee-payroll-record'],
                        children: [
                          {
                            index: true,
                            element: <EmployeePayrollTable />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <EmployeePayrollDetail />
                          }
                        ]
                      },
                      {
                        path: 'screening',
                        description:
                          'View and manage employee screening checks such as identity and right to work',
                        name: 'Employee screening',
                        roles: ['employee-screening'],
                        children: [
                          {
                            index: true,
                            element: <PrescreeningTable />
                          },
                          {
                            path: ':id',
                            name: 'View screening',
                            element: <PrescreeningDetail />
                          }
                        ]
                      },
                      {
                        path: ':id',
                        name: 'View',
                        children: [{ index: true, element: <ViewUser /> }]
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Fieldwork',
                icon: <FiList />,
                path: 'fieldwork',
                roles: ['project', 'client', 'quote'],
                description:
                  'Call centre, project management, suppliers, clients and more..',
                children: [
                  {
                    index: true,
                    element: <></>
                  },
                  {
                    name: 'Quotes',
                    description: 'Manage new and current project quotations',
                    path: 'quotes',
                    roles: ['quote'],
                    children: [
                      { index: true, element: <Quotes /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <ComingSoon />
                      },
                      { path: ':id', name: 'View', element: <ComingSoon /> }
                    ]
                  },

                  {
                    name: 'Project Management',
                    path: 'pm',
                    roles: ['project'],
                    description:
                      'Manage new and current projects, create or commission projects, assign project teams and more...',

                    children: [
                      { index: true, element: <Projects /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <ProjectDetail />
                      },
                      {
                        path: ':projectId',
                        name: 'View',
                        children: [
                          { index: true, element: <ProjectDetail /> },
                          {
                            name: 'Target Groups',
                            path: 'target-groups',
                            children: [
                              { index: true, element: <TargetGroups /> },
                              {
                                path: 'new',
                                name: 'Create',
                                element: <TargetGroupDetail />
                              },
                              {
                                path: ':targetGroupId',
                                name: 'View',
                                element: <TargetGroupDetail />
                              }
                            ]
                          },
                          {
                            path: 'questionnaires',
                            name: 'Questionnaires',
                            children: [
                              {
                                index: true,
                                element: <QuestionnairesTable />
                              },
                              {
                                path: 'new',
                                name: 'Create',
                                element: <QuestionnaireDetail />
                              },
                              {
                                path: ':questionnaireId',
                                name: 'View',
                                element: <QuestionnaireDetail />
                              }
                            ]
                          }
                        ]
                      },
                      {
                        roles: ['project.admin'],
                        name: 'Team Roles',
                        description:
                          'Manage the roles that users can play in project management',
                        path: 'roles',
                        children: [
                          { index: true, element: <ComingSoon /> },
                          {
                            path: 'new',
                            name: 'Create',
                            element: <ComingSoon />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <ComingSoon />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Clients',
                    roles: ['client'],
                    description:
                      'Manage new and current clients, as well as client contacts',
                    path: 'clients',
                    children: [
                      { index: true, element: <Clients /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <ClientDetail />
                      },
                      { path: ':id', name: 'View', element: <ClientDetail /> }
                    ]
                  }
                ]
              },
              {
                name: 'Settings',
                icon: <FiSettings />,
                path: 'settings',
                roles: ['company'],
                description:
                  "Manage your company's app configuration and settings",
                children: [
                  {
                    index: true
                  },
                  {
                    name: 'Storage',
                    path: 'storage',
                    icon: <FiFolder />,
                    description:
                      'View all files on the system, manage storage space, download and delete files',
                    children: [{ index: true, element: <Storage /> }]
                  },
                  {
                    name: 'Permissions',
                    path: 'permissions',
                    icon: <FiKey />,
                    description:
                      'Manage user permissions by configuring access roles, and custom permission settings',
                    children: [
                      {
                        index: true,
                        element: <></>
                      },
                      {
                        name: 'Access Groups',
                        path: 'groups',
                        roles: ['access-group'],
                        children: [
                          { index: true, element: <AccessGroups /> },
                          {
                            path: 'new',
                            name: 'Create',
                            element: <AccessGroupDetail />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            element: <AccessGroupDetail />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Notifications',
                    path: 'notifications',
                    icon: <FiBell />,
                    description:
                      'Manage how notifications go out, including email templates and channels',
                    children: [
                      {
                        index: true,
                        element: <Mailer />
                      },
                      {
                        path: 'templates',
                        name: 'Templates',
                        icon: <FiFileText />,
                        children: [
                          {
                            name: 'Create template',
                            path: 'new',
                            element: <TemplateDetail />
                          },
                          {
                            name: 'Edit template',
                            path: ':id',
                            element: <TemplateDetail />
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: 'Calendars',
                    path: 'calendars',
                    icon: <FiCalendar />,
                    description:
                      'Manage the calendars used by projects, users, and more. Perform bulk changes on calendars and calendar events/tasks. Review calendar permissions and share calendars with users.',
                    children: [
                      {
                        index: true,
                        element: <Calendars />
                      },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <CalendarDetail />
                      },
                      {
                        path: ':calendarId',
                        name: 'View',
                        element: <CalendarDetail />
                      }
                    ]
                  },
                  {
                    name: 'Webhooks',
                    path: 'webhooks',
                    roles: ['webhook'],
                    description:
                      'Configure and manage webhooks for external integrations',
                    children: [
                      {
                        index: true,
                        element: <Webhooks />
                      },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <WebhookDetail />
                      },
                      {
                        path: ':id',
                        name: 'View',
                        element: <WebhookDetail />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'support',
                name: 'Support',
                description:
                  'Get help from our support team, view your ongoing tickets, and use knowledgebase articles and company process documents',
                children: [
                  {
                    name: 'Tickets',
                    path: 'tickets',
                    children: [
                      {
                        index: true,
                        element: <Support />
                      },
                      {
                        path: ':status',
                        children: [
                          {
                            path: ':id',
                            name: 'View ticket',
                            element: <TicketDetail />
                          }
                        ]
                      },
                      {
                        path: ':id',
                        name: 'View ticket',
                        element: <TicketDetail />
                      }
                    ]
                  },
                  {
                    name: 'Documentation',
                    path: 'documentation',
                    children: [
                      { index: true, element: <></> },
                      {
                        name: 'FAQ',
                        description:
                          'Answers to the questions we get asked the most about with Flex',
                        icon: <FiHelpCircle />,
                        path: 'faq',
                        element: <Faq />
                      },
                      {
                        name: 'Flex knowledgebase',
                        description:
                          'Process documents, help files, training, wiki and how-to guides for ' +
                          companyConfig.companyName,
                        icon: <FiHelpCircle />,
                        path: 'knowledgebase',
                        // dev: true,
                        // element: <ComingSoon />
                        children: [
                          {
                            index: true,
                            element: <UserGuides />
                          },
                          {
                            path: ':id',
                            name: 'View',
                            children: [
                              {
                                path: 'edit',
                                name: 'Edit',
                                roles: ['user-guide.edit'],
                                element: <EditUserGuide />
                              },
                              {
                                index: true,
                                element: <UserGuide />
                              }
                            ]
                          },
                          {
                            path: 'new',
                            name: 'Create',
                            roles: ['user-guide.create'],
                            element: <EditUserGuide />
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: '*',
                element: <Error404 />
              }
            ]
          },
          {
            path: '*',
            element: <ErrorLayout />,
            children: [
              { path: '404', element: <Error404 backBtn={false} /> },
              { path: '401', element: <Error401 /> },
              { path: '403', element: <Error403 /> }
            ]
          }
        ]
      }
    ]
  }
];
export type Route = {
  name: string;
  path: string;
  element: ReactNode;
  roles?: string[];
  icon?: ReactNode;
  description?: string;
  children?: Route[];
  color?: string;
  domain?: RoleDomain;
  index?: boolean;
};
const colors = new Set(['primary', 'danger', 'info', 'warning', 'success']);
let colorEnum = colors.values();
const nextColor = () => {
  let color = colorEnum.next();
  if (color.done) {
    colorEnum = colors.values();
    color = colorEnum.next();
  }
  return color.value;
};
const filterRoutesRecursively = (
  children,
  inheritedRoles = null,
  inheritedDomain = null
) => {
  return children.map(route => {
    const getDomain = (...str) => {
      return str
        .find(s =>
          Object.keys(settings.domains).find(
            d => d?.toLowerCase() === s?.toLowerCase()
          )
        )
        ?.toLowerCase();
    };
    const domain = getDomain(
      route.name,
      //remove the last letter
      route.name?.slice(0, -1),
      route.name + 's',
      route.domain,
      ...(route?.roles || []),
      inheritedDomain,
      ...(inheritedRoles || [])
    );
    const domainObj = settings.domains[domain];
    const color = domainObj?.variant || nextColor();
    const iconByName =
      route.name === 'Create' ? (
        <FiPlusCircle />
      ) : route.name === 'View' ? (
        <FiEdit />
      ) : route.name === 'Edit' ? (
        <FiEdit />
      ) : null;
    const icon = route.icon || iconByName || domainObj?.icon;
    // console.log(
    //   route,
    //   domainObj,
    //   'icon for ',
    //   route.name,
    //   domain,
    //   ' is ',
    //   icon
    // );
    const newRoute = {
      ...route,
      roles: route.roles || inheritedRoles,
      domain,
      icon,
      element:
        route.dev && process.env.NODE_ENV !== 'development' ? (
          <ComingSoon />
        ) : (
          route.element
        )
    };
    if (newRoute.index) {
      newRoute.element = (
        <>
          {newRoute.element}
          <QuickLinks />
        </>
      );
    }
    if (newRoute.children) {
      newRoute.children = filterRoutesRecursively(
        [...newRoute.children],
        newRoute.roles,
        newRoute.domain
      );
      return {
        ...newRoute,
        element: !!newRoute.element && (
          <RolesAuthRoute roles={newRoute.roles} route={newRoute.element} />
        ),
        handle: { ...newRoute, color, domain },
        color,
        icon,
        domain,
        errorElement: <Sentry.ErrorBoundary fallback={<Error500 />} />
      };
    }
    return {
      ...newRoute,
      handle: { ...newRoute, color, icon, domain },
      element: !!newRoute.element && (
        <RolesAuthRoute route={newRoute.element} roles={newRoute.roles} />
      ),
      errorElement: <Sentry.ErrorBoundary fallback={<Error500 />} />,
      color,
      domain,
      icon
    };
  });
};
export const getRoutes = () => {
  return filterRoutesRecursively([...coreRoutes]);
};
const recursivelyRemoveDisallowed = (children, isAllowed) => {
  return children
    .filter(r => isAllowed(r.roles))
    .map(route => {
      if (route.children) {
        route.children = recursivelyRemoveDisallowed(route.children, isAllowed);
      }
      return route;
    });
};
const recursivelyRemoveEmpty = children => {
  const removed = children
    .filter(r => {
      // console.log('filtering empty', r);
      return !r.children || r.children.filter(c => !c.index).length > 0;
    })
    .map(route => {
      // console.log('passed remove filter', route);
      if (route.children) {
        route.children = recursivelyRemoveEmpty(route.children);
      }
      return route;
    });
  // console.log('returning removed', children, removed);
  return removed;
};
export const getRolesRoutes = isAllowed => {
  return recursivelyRemoveEmpty(
    recursivelyRemoveDisallowed(getRoutes(), isAllowed)
  );
};

export default [coreRoutes];
