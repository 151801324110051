import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import useResizeObserver from 'hooks/useResizeObserver';
import Resizable from 'components/common/Resizable';
import {
  FormProvider,
  UseFieldArrayReturn,
  useForm,
  useWatch
} from 'react-hook-form';
import { useResourceGroupSchedule } from 'components/app/pm/projects/resourcing/useResourcePlan';
import { ShiftPlan, ShiftPlanAllocation } from 'apis/flex/hr';
import { isSameDay } from 'helpers/dates';
import useTargetGroups from 'components/app/pm/projects/useTargetGroups';
import MouseFollower from 'components/common/MouseFollower';
import { ResponsiveModal } from 'components/common/Modals';
import { Modal } from 'react-bootstrap';
import ActionFooter from 'components/common/ActionFooter';
import IconButton from 'components/common/IconButton';
import { faCut, faLock } from '@fortawesome/free-solid-svg-icons';
import WizardInput from 'components/wizard/WizardInput';
import { SelectedEmployeeShifts } from '../../shifts/employeeShifts/EmployeeShiftWizard';
import classNames from 'classnames';
import { domainConfigs } from 'components/notification/config';
import { EmployeeShift } from './UnallocatedItem';
import {
  DragDropContext,
  DraggableProvided,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import DraggableItem from 'components/common/DraggableItem';
import DragHandle from 'components/common/DragHandle';
import CustomTooltip from 'components/common/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type EmployeeRow = {
  employeeId: number;
  shiftId: number;
  allocations: (ShiftPlanAllocation & { masterIndex: number })[];
};
const useTargetGroupSchedule = () => {
  const [date, resourceGroupId] = useWatch<
    ShiftPlan,
    ['date', 'resourceGroupId']
  >({ name: ['date', 'resourceGroupId'] });
  const { data: schedule, isLoading: scheduleLoading } =
    useResourceGroupSchedule(resourceGroupId);
  const scheduleForDate = schedule?.find(s => isSameDay(s.date, date));
  const { data: targetGroups, isLoading } = useTargetGroups({
    id: scheduleForDate?.jobs?.map(j => j.targetGroupId),
    staleTime: Infinity
  });
  const tgs = useMemo(
    () =>
      !targetGroups
        ? []
        : scheduleForDate?.jobs?.map(j => ({
            ...j,
            targetGroup: targetGroups?.find(t => t.id === j.targetGroupId)
          })),
    [schedule, targetGroups]
  );
  return {
    data: tgs,
    isLoading: scheduleLoading || isLoading
  };
};
export default ({
  readOnly,
  ...fieldArray
}: { readOnly?: boolean } & UseFieldArrayReturn<ShiftPlan, 'allocations'>) => {
  const { fields, replace } = fieldArray;
  const perEmployeeShift = useMemo(
    () =>
      fields.reduce<EmployeeRow[]>((a, b, i) => {
        if (
          !a.find(
            aa => aa.employeeId === b.employeeId && aa.shiftId === b.shiftId
          )
        ) {
          a.push({
            employeeId: b.employeeId,
            allocations: [],
            shiftId: b.shiftId
          });
        }
        const index = a.findIndex(aa => aa.employeeId === b.employeeId);
        a[index].allocations.push({
          ...b,
          masterIndex: i,
          units: Number(b.units)
        });
        return a;
      }, []),
    [fields]
  );
  const withId = useMemo(
    () => perEmployeeShift.map((p, i) => ({ ...p, id: i })),
    [perEmployeeShift]
  );
  const updateFields = (
    ...updatedFields: ({ id?: string; index?: number } & Partial<
      Omit<ShiftPlanAllocation, 'id'>
    >)[]
  ) => {
    const inserts = updatedFields.filter(f => !f.id);
    const updates = updatedFields.filter(f => f.id);
    const toUpdate = fields.map<any>((f, i) => {
      const update = updates.find(u => u.id === f.id);
      if (update) {
        return {
          ...f,
          ...update
        };
      }
      return f;
    });
    for (let i = 0; i < inserts.length; i++) {
      const insert = inserts[i];
      if (insert.index) {
        toUpdate.splice(insert.index, 0, insert);
      } else {
        toUpdate.push(insert);
      }
    }

    replace(toUpdate.filter(f => f.units > 0));
  };
  const handleUpdate = ({ masterIndex, units, targetGroupId, isLocked }) => {
    const update: any = {};
    if (isLocked !== undefined) {
      update.isLocked = isLocked;
    }
    if (targetGroupId !== undefined) {
      update.targetGroupId = targetGroupId;
    }
    if (units !== undefined) {
      update.units = units;
    }
    const original = fields[masterIndex];
    const updated = {
      ...original,
      ...update
    };
    // reallocate other allocations in shift
    const allForEmployee = fields.filter(
      (f, i) =>
        updated.employeeId === f.employeeId && updated.shiftId === f.shiftId
    );
    const others = allForEmployee.filter((f, i) => i !== masterIndex);
    const spread = (units: number) => {
      if (units <= 0) return [];
      const arr = [];
      let allocated = 0;
      const perItem = units / others.length;
      for (let i = 0; i < others.length; i++) {
        let rounded = Math.round(perItem * 4) / 4;
        // console.log('applying spread to other #', i, rounded, {
        //   perItem,
        //   units,
        //   allocated
        // });
        const remaining = units - (allocated + rounded);
        const done = () => {
          allocated += rounded;
          const remaining = units - allocated;
          if (i === others.length - 1 && remaining > 0) {
            rounded = rounded + 0.25;
          }
          arr.push(rounded);
        };
        if (remaining === 0) {
          done();
          break;
        }
        if (remaining < 0.25) {
          rounded = rounded + 0.25;
          done();
          break;
        }
        done();
      }
      return arr;
    };
    const totalUnits = allForEmployee.reduce((a, b) => a + b.units, 0);
    const toAdd = original.units - units;
    const toRemove = units - original.units;
    const toAdds = spread(toAdd);
    const toRemoves = spread(toRemove);
    updateFields(
      {
        ...updated
      },
      ...others.map((f, i) => ({
        id: f.id,
        units:
          units < totalUnits
            ? f.units - (toRemoves[i] || 0) + (toAdds[i] || 0)
            : 0
      }))
    );
  };
  const handleSplit = (masterIndex: number, units: number) => {
    const current = fieldArray.fields[masterIndex];
    updateFields(
      {
        ...current,
        id: null,
        index: masterIndex + 1,
        units: current.units - units
      },
      {
        ...current,
        units: units
      }
    );
  };
  const handleReorder = (oldIndex: number, newIndex: number) => {
    fieldArray.move(oldIndex, newIndex);
  };
  const unitsWidth = useMemo(
    () =>
      perEmployeeShift.reduce((a, b) => {
        const employeeUnits = Math.ceil(
          b.allocations.reduce((a, b) => a + b.units * 4, 0)
        );
        if (employeeUnits > a) {
          return employeeUnits;
        }
        return a;
      }, 0),
    [perEmployeeShift]
  );
  const getId = allocation =>
    allocation.employeeId.toString() + '-' + allocation.shiftId.toString();
  const columns = useMemo(
    () => [
      {
        id: 'employeeId',
        size: 200,
        formatter: (v, d, r) => {
          return (
            // <DraggableItem
            //   draggableId={getId(d)}
            //   key={getId(d)}
            //   index={r.index}
            // >
            //   <motion.div layout="position" layoutId={getId(d)}>
            <EmployeeShift employeeId={d.employeeId} shiftId={d.shiftId} />
            //   </motion.div>
            // </DraggableItem>
          );
        }
      },
      {
        id: 'allocation',
        accessorFn: d => d.allocations.length,
        download: (v, d) =>
          d.allocations.map(a => a.targetGroupId + ': ' + a.units).join(', '),
        formatter: (v, d) => (
          <AllocationRow
            onUpdate={handleUpdate}
            onSplit={handleSplit}
            data={d}
            key={d.id}
            unitsWidth={unitsWidth}
            width={'calc(70vw - 200px)'}
            onReorder={handleReorder}
            readOnly={readOnly}
          />
        )
      }
    ],
    [readOnly, unitsWidth, fieldArray.fields]
  );
  const draggable = useCallback(
    row => ({
      draggableId: getId(row.original),
      isDragDisabled: !!readOnly
    }),
    [readOnly]
  );
  return (
    <AdvanceTableProvider data={withId} columns={columns}>
      <AdvanceTable droppableId="allocated" draggable={draggable} />
    </AdvanceTableProvider>
  );
};
const AllocationRow = ({
  data,
  unitsWidth,
  onUpdate,
  onSplit,
  width: rowWidth,
  onReorder,
  readOnly
}: {
  readOnly?: boolean;
  data: EmployeeRow & { id: number };
  unitsWidth?: number;
  onUpdate: (props: {
    masterIndex: number;
    units?: number;
    targetGroupId?: number;
    isLocked: boolean;
  }) => void;
  onSplit?: (masterIndex: number, units: number) => void;
  width: number | string;
  onReorder: (oldIndex: number, newIndex: number) => void;
}) => {
  const getTotalUnits = (shiftId: number) =>
    data.allocations
      .filter(a => a.shiftId === shiftId)
      .reduce((a, b) => a + Number(b.units), 0);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useResizeObserver(ref, box => {
    if (box && box.width !== width) {
      setWidth(box.width);
    }
  });
  const [notches, setNotches] = useState([]);
  useEffect(() => {
    const n = Array.from(
      { length: unitsWidth + 1 },
      (_, i) => (i / unitsWidth) * width
    );
    if (n[1] !== notches[1] && !!n[1] && width > 0) {
      setNotches(n);
    }
  }, [unitsWidth, width]);
  const getNearestNotch = (leftPosition: number) => {
    const { index } = notches.reduce(
      (closest, notch, index) => {
        const distance = Math.abs(notch - leftPosition);
        return distance < closest.distance ? { index, distance } : closest;
      },
      { index: -1, distance: Infinity }
    );
    return index;
  };
  const getNearestNotchX = leftPosition => {
    const notchIndex = getNearestNotch(leftPosition);
    return notches[notchIndex];
  };
  const getAbsoluteNotchX = absoluteLeft => {
    const boxLeft = ref.current?.getBoundingClientRect().left;
    const left = absoluteLeft - boxLeft;
    const notchIndex = getNearestNotch(left);
    const notch = notches[notchIndex];
    return notch + boxLeft;
  };
  const clickNotch = useRef(0);
  const handleSplit = (masterIndex, index) => () => {
    const allocsToTheLeft = data.allocations.filter((_, ii) => ii < index);
    const clickedUnit =
      clickNotch.current - allocsToTheLeft.reduce((a, b) => a + b.units * 4, 0);
    onSplit(masterIndex, clickedUnit / 4);
  };
  const handleDrag = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId === destination.droppableId) {
      if (source.index === destination.index) return;
      const oldMasterIndex = Number(result.draggableId);
      const newMasterIndex = data.allocations[destination.index].masterIndex;
      onReorder(oldMasterIndex, newMasterIndex);
    }
  };
  const renderBlock = useCallback(
    (i: number, a: (typeof data.allocations)[number]) =>
      (provided: DraggableProvided) =>
        (
          <div
            {...provided.draggableProps}
            // style={{ pointerEvents: readOnly ? 'none' : undefined }}
            ref={provided.innerRef}
          >
            <AllocationBlock
              data={a}
              width={notches[1] * a.units * 4}
              index={i}
              totalCount={data.allocations.length}
              onUpdate={p => onUpdate({ ...p, masterIndex: a.masterIndex })}
              onSplit={handleSplit(a.masterIndex, i)}
              totalUnits={getTotalUnits(a.shiftId)}
              getNearestNotchX={getAbsoluteNotchX}
              dragHandleProps={provided.dragHandleProps}
            />
          </div>
        ),
    [notches]
  );
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable
        direction="horizontal"
        droppableId={'allocationRow-' + data.id}
        type={'row'}
        isDropDisabled={!!readOnly}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className="w-100 d-flex "
            {...provided.droppableProps}
          >
            <div
              ref={ref}
              style={{ width: rowWidth }}
              className="position-relative"
            >
              <MouseFollower
                className="bg-300 d-flex w-100 rounded-3"
                followerProps={position => {
                  const nearestNotch = getNearestNotch(position.x);
                  const nearestNotchX = getNearestNotchX(position.x);
                  // console.log('nearestNotch', nearestNotch, {
                  //   nearestNotchX,
                  //   position,
                  //   notches,
                  //   unitsWidth
                  // });
                  return {
                    className: classNames(
                      'border border-dashed border-2 d-block z-2',
                      {
                        'd-none':
                          nearestNotch === 0 ||
                          nearestNotch === notches.length - 1 ||
                          readOnly
                      }
                    ),
                    style: {
                      top: 0,
                      width: 1,
                      height: '100%',
                      left: nearestNotchX,
                      pointerEvents: 'none'
                    }
                  };
                }}
                onClick={pos => {
                  clickNotch.current = getNearestNotch(pos.x);
                }}
                follower={<div />}
              >
                <div
                  className={classNames({
                    'd-none': !!snapshot.draggingFromThisWith
                  })}
                >
                  {provided.placeholder}
                </div>
                {data.allocations.map((a, i) => (
                  <DraggableItem
                    draggableId={a.masterIndex.toString()}
                    key={a.id}
                    index={i}
                  >
                    {renderBlock(i, a)}
                  </DraggableItem>
                ))}
              </MouseFollower>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
const AllocationBlock = ({
  data,
  width,
  // totalWidth,
  index,
  totalCount,
  // unitsWidth,
  onUpdate,
  onSplit,
  totalUnits,
  getNearestNotchX,
  dragHandleProps
}: {
  data: ShiftPlanAllocation & { masterIndex: number };
  width: number;
  // totalWidth: number;
  index: number;
  totalCount: number;
  onUpdate: (props: {
    units?: number;
    targetGroupId?: number;
    isLocked: boolean;
  }) => void;
  onSplit?: () => void;
  totalUnits: number;
  getNearestNotchX: (leftPosition: number) => number;
  dragHandleProps?: any;
}) => {
  //   const { allocate } = useAllocationActions(fieldArray);

  const [show, setShow] = useState<boolean>();

  const { data: tgs, isLoading } = useTargetGroupSchedule();
  const selectedTg = tgs?.find(tg => tg.targetGroupId === data.targetGroupId);
  const ref = useRef(null);
  // const [rWidth, setRWidth] = useState(0);
  // useEffect(() => {
  //   if (width !== rWidth) {
  //     setRWidth(width);
  //   }
  // }, [width]);
  const [ghostWidth, setGhostWidth] = useState(0);
  const [ghostUnits, setGhostUnits] = useState(0);
  const handleClick = e => {
    // e.stopPropagation();
    // e.preventDefault();
    setShow(true);
  };
  return (
    <Resizable
      left={index > 0}
      right={index < totalCount - 1}
      width={width}
      height={60}
      onResize={() => {
        onUpdate({ units: ghostUnits, isLocked: data.isLocked });
        setGhostWidth(0);
      }}
      onResizing={(e: any, { node }) => {
        if (!node.getBoundingClientRect) return;
        const nodeX = e.x;
        const nearestNotchX = getNearestNotchX(nodeX);
        const boxX = ref.current.getBoundingClientRect().left;
        const targetWidth = Math.max(50, nearestNotchX - boxX);
        setGhostWidth(targetWidth);
        const widthPerUnit = width / data.units;
        const ghostUnits = targetWidth / widthPerUnit;
        setGhostUnits(Math.min(totalUnits, Math.round(ghostUnits * 4) / 4));
      }}
      className="position-relative cursor-grab rounded-3"
      style={{
        marginLeft: 1,
        marginRight: 1
      }}
    >
      <div
        className="px-1 h-100 btn btn-falcon-default d-flex"
        ref={ref}
        style={{
          width: `100%`
        }}
        onClick={handleClick}
        // style={{ boxShadow: 'inset 0 0 12px 0px #00000021' }}
      >
        <DragHandle
          dragHandleProps={dragHandleProps}
          className="me-1 h-100"
          horizontal
          style={{
            verticalAlign: 'middle'
          }}
        />
        <div className="flex-1 position-relative h-100">
          <div className="position-absolute top-0 start-0 ps-1 fw-light lh-1 fs--1 w-100 d-flex flex-column align-items-start justify-content-start">
            {data.isLocked && (
              <FontAwesomeIcon icon={faLock} className="text-800" />
            )}
            <div>{Number(data.units)} hrs</div>
            <div
              className="overflow-hidden w-100 text-start"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {!!selectedTg?.targetGroup &&
                domainConfigs['target-group'].format(selectedTg?.targetGroup)
                  ?.label}
            </div>
          </div>
        </div>
      </div>
      {!!ghostWidth && (
        <div
          className="position-absolute top-0 start-0 h-100 z-2 btn btn-primary"
          style={{
            width: ghostWidth
          }}
        >
          <div>{ghostUnits} hrs</div>
        </div>
      )}
      <BlockModal
        tgs={tgs}
        data={data}
        show={show}
        setShow={setShow}
        onSplit={onSplit}
        onUpdate={onUpdate}
        maxUnits={totalUnits}
        isLoading={isLoading}
        minUnits={totalCount === 1 ? totalUnits : 0.25}
      />
    </Resizable>
  );
};
const BlockModal = ({
  data,
  show,
  setShow,
  onSplit,
  onUpdate,
  maxUnits,
  tgs,
  isLoading,
  minUnits
}: {
  data: ShiftPlanAllocation;
  show: boolean;
  setShow: (show: boolean) => void;
  onSplit: () => void;
  onUpdate: (vals: {
    units?: number;
    targetGroupId?: number;
    isLocked: boolean;
  }) => void;
  maxUnits: number;
  minUnits: number;
  tgs?: ReturnType<typeof useTargetGroupSchedule>['data'];
  isLoading: boolean;
}) => {
  const handleSplit = () => {
    // methods.handleSubmit(vals => {
    onSplit();
    setShow(false);
    // })();
  };
  const methods = useForm({
    values: {
      ...data
      // targetGroupId: data.targetGroupId
    },
    defaultValues: {
      units: 0,
      isLocked: data.isLocked
      // targetGroupId: tgs?.[0]?.targetGroupId
    }
  });
  const handleDone = () => {
    methods.handleSubmit(vals => {
      setShow(false);
      onUpdate(vals);
    })();
  };
  useEffect(() => {
    if (show) {
      methods.setFocus('units', { shouldSelect: true });
    }
  }, [show]);
  return (
    <ResponsiveModal
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <SelectedEmployeeShifts ids={[data.employeeShiftId]} />
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <WizardInput
            name={'targetGroupId'}
            type="select"
            loading={isLoading}
            options={(tgs || []).map(tg => ({
              value: tg.targetGroupId,
              label: tg.targetGroup.name
            }))}
            label="Target Group"
          />
          <WizardInput
            name={'units'}
            label="Units"
            type="number"
            formControlProps={{
              step: 0.25,
              max: maxUnits,
              min: minUnits
            }}
          />
          <WizardInput
            name={'isLocked'}
            label={
              <>
                <FontAwesomeIcon icon={faLock} className="me-1" /> Lock
              </>
            }
            instruction="Will protect this allocation from being changed during auto-allocation."
            type="checkbox"
          />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <ActionFooter
          onSubmit={handleDone}
          onCancel={() => setShow(false)}
          submitText="Done"
          disabled={false}
        >
          <IconButton
            onClick={handleSplit}
            variant="falcon-primary"
            icon={faCut}
          >
            Split
          </IconButton>
        </ActionFooter>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
