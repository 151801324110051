import React, { useEffect, useMemo, useState } from 'react';
import {
  Col,
  Button,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  CloseButton,
  Fade
} from 'react-bootstrap';
import { ResponsiveModal } from '../Modals';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import {
  Cell,
  flexRender,
  Header as HeaderType,
  Row as RowType
} from '@tanstack/react-table';
import SettingsBox from '../SettingsBox';
import CustomTooltip from '../Tooltip';
import ExpandableParagraph from '../ExpandableParagraph';
import { motion, AnimatePresence } from 'framer-motion';
import Expandable from '../Expandable';
import { createPortal } from 'react-dom';
import useMainRef from 'hooks/useMainRef';
export default ({
  onFinished,
  rows,
  headers: __headers
}: {
  onFinished: () => void;
  headers: HeaderType<any, unknown>[];
  rows: RowType<any>[];
}) => {
  const [rowIndex, setRowIndex] = useState(0);
  // const { getHeaderGroups } = useAdvanceTable();
  const methods = useForm();
  const cells = useMemo(
    () =>
      rows[rowIndex]
        ?.getVisibleCells()
        .filter(c => !(c.column.columnDef as any).isAction) || [],
    [rowIndex, rows]
  );
  const actionCell = useMemo(
    () =>
      rows[rowIndex]
        ?.getVisibleCells()
        .find(c => (c.column.columnDef as any).isAction),
    [rowIndex, rows]
  );
  const [lastIndex, setLastIndex] = useState(-1);
  const mainRef = useMainRef();
  return (
    <Card style={{ zIndex: 1021 }}>
      {createPortal(
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          className="position-fixed top-0 end-0 w-100 h-100 bg-1000"
          style={{ zIndex: 1020 }}
          onClick={onFinished}
        />,
        mainRef.current
      )}
      <CardHeader className="d-flex justify-content-between align-items-center">
        <CardTitle>
          Reviewing {rowIndex + 1} of {rows.length}
        </CardTitle>
        <CloseButton onClick={onFinished} />
      </CardHeader>
      <CardBody>
        <FormProvider {...methods}>
          <ReviewerInfo
            lastIndex={lastIndex}
            rowIndex={rowIndex}
            headers={__headers}
            cells={cells}
          />
        </FormProvider>
      </CardBody>
      <CardFooter className="d-flex justify-content-between">
        <Button
          variant="falcon-primary"
          onClick={() => {
            setLastIndex(rowIndex);
            setRowIndex(r => Math.max(r - 1, 0));
          }}
          disabled={rowIndex === 0}
          size="sm"
        >
          Prev
        </Button>
        <div>
          {flexRender(
            actionCell?.column.columnDef.cell,
            actionCell?.getContext()
          )}
        </div>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            setLastIndex(rowIndex);
            setRowIndex(r => Math.min(r + 1, rows.length - 1));
          }}
          disabled={rowIndex === rows.length - 1}
        >
          Next
        </Button>
      </CardFooter>
    </Card>
  );
};
const ReviewerInfo = ({
  headers: __headers,
  cells,
  rowIndex,
  lastIndex
}: {
  headers: HeaderType<any, unknown>[];
  cells: Cell<any, unknown>[];
  rowIndex: number;
  lastIndex: number;
}) => {
  const vals = useWatch({ name: 'headers' });
  const [selectedHeaders, setSelectedHeaders] = useState<
    HeaderType<any, unknown>[]
  >([]);
  useEffect(() => {
    if (!vals || !Object.keys(vals).length) return;
    const headers = __headers.filter(h => vals?.includes(h.id));
    if (headers.length === selectedHeaders.length) return;
    setSelectedHeaders(headers);
  }, [vals, selectedHeaders]);
  const options = useMemo(
    () =>
      __headers
        .filter(
          h =>
            h.column.columnDef.header &&
            typeof h.column.columnDef.header === 'string'
        )
        .map((h, j) => ({
          value: h.id,
          label: h.column.columnDef.header as string
        })),
    [__headers]
  );
  // console.log(options);
  return (
    <>
      <Expandable
        activeCount={selectedHeaders.length}
        label={() => 'Select columns'}
        defaultExpanded
      >
        <WizardInput
          type="checkbox"
          name={'headers'}
          hideLabel
          formControlProps={{ rows: 10 }}
          options={options}
        />
      </Expandable>
      <div className="overflow-hidden mt-4 mb-3 border px-3 pb-0 pt-4 rounded">
        <AnimatePresence mode="wait">
          <motion.div
            className="row"
            id={rowIndex.toString()}
            // layoutId={rowIndex.toString()}
            key={rowIndex.toString()}
            initial={{ opacity: 0, y: lastIndex > rowIndex ? -150 : 150 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: lastIndex > rowIndex ? 150 : -150 }}
            // layout
          >
            {/* <Row> */}
            {/* <PageSlider
            direction={lastIndex > rowIndex ? 'backward' : 'forward'}
            motionKey={rowIndex}
          > */}
            {/* <div> */}
            {selectedHeaders
              ?.filter(h => !!h.column.columnDef.header)
              .map((h, i) => (
                <ReviewerCell key={h.id} cells={cells} header={h} />
              ))}
            {/* </div>
          </PageSlider> */}
            {/* </Row> */}
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};
const ReviewerCell = ({
  cells,
  header: h
}: {
  cells: Cell<any, unknown>[];
  header: HeaderType<any, unknown>;
}) => {
  const cell = useMemo(
    () => cells.find(c => c.column.columnDef.id === h.id),
    [cells]
  );
  const mainRef = useMainRef();
  return (
    <Col xs={12} md={6} xl={4} className="overflow-auto" style={{ height: 90 }}>
      <CustomTooltip
        content={
          h.column.columnDef.meta?.headerTooltip || h.column.columnDef.header
        }
      >
        <div
          style={{
            whiteSpace: 'nowrap',
            maxWidth: 150
          }}
          className="text-truncate h6"
        >
          {flexRender(h.column.columnDef.header, h.getContext())}
        </div>
      </CustomTooltip>
      <div className="ms-3" style={{ verticalAlign: 'middle', zIndex: 99999 }}>
        {flexRender(cell?.column.columnDef.cell, cell?.getContext())}
      </div>
    </Col>
  );
};
