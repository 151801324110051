import React from 'react';
import CustomTooltip from './Tooltip';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';
import { EventPrefix } from 'apis/flex/notifications';
import { format } from 'date-fns';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faClock,
  faMehBlank,
  faQuestionCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { camelToSentence } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stamp } from './Stamp';
import { CrudField, DefaultCrudData } from 'hooks/defaultCrud/useDefaultCrud';
export type StateDef<TData> = {
  icon: IconProp;
  color: string;
  dateField?: CrudField<TData, string | Date>;
  authorField?: CrudField<TData, number>;
  /** E.g. '2714' to display '✔' */
  iconUnicode?: string;
};
export type DomainStatusProps<TData = any> = {
  domain: EventPrefix;
  item: TData;
  states?: Record<string, StateDef<TData>>;
  addedDateField?: CrudField<TData, string | Date>;
  approvedDateField?: CrudField<TData, string | Date>;
  rejectedDateField?: CrudField<TData, string | Date>;
  addedByField?: CrudField<TData, number>;
  approvedByField?: CrudField<TData, number>;
  rejectedByField?: CrudField<TData, number>;
};
const DomainStatusBadge = <TData extends Record<string, any>>(
  props: DomainStatusProps<TData>
) => {
  const { date, authorId, text, icon, color } = formatDomainStatus(props);
  const user = useUser();
  if (!text) return null;
  return (
    <CustomTooltip
      wide
      content={
        <Stamp
          date={date}
          by={authorId}
          label={text}
          icon={icon}
          variant={color}
        />
      }
    >
      <div
        className="d-flex align-items-center link"
        onClick={e => {
          e.stopPropagation();
          window.open(getDomainItemUrl(props.domain, user, props.item));
        }}
      >
        {icon && (
          <FontAwesomeIcon icon={icon} className={`text-${color} me-2`} />
        )}
        {text}
      </div>
    </CustomTooltip>
  );
};
export default DomainStatusBadge;
export const formatDomainStatus = <TData extends Record<string, any>>({
  item,
  approvedByField,
  addedByField,
  addedDateField,
  approvedDateField,
  rejectedDateField,
  rejectedByField,
  states
}: DomainStatusProps<TData>) => {
  if (!item)
    return {
      text: '',
      date: '',
      iconUnicode: ''
    };
  const state = item.state;
  let authorId =
    item.approvedBy ||
    item.acceptedBy ||
    (approvedByField && item[approvedByField]) ||
    item.rejectedBy ||
    item.failedBy ||
    (rejectedByField && item[rejectedByField]) ||
    item.createdBy ||
    item.addedBy ||
    (addedByField && item[addedByField]);
  let date =
    item.approvedDate ||
    item.acceptedDate ||
    (approvedDateField && item[approvedDateField]) ||
    item.rejectedDate ||
    item.failedDate ||
    (rejectedDateField && item[rejectedDateField]) ||
    item.submittedDate ||
    item.createdDate ||
    item.addedDate ||
    (addedDateField && item[addedDateField]);
  let icon: IconProp;
  let color;
  let iconUnicode;
  switch (state) {
    case 'approved':
    case 'accepted':
    case 'valid':
    case 'complete':
    case 'done':
      icon = faCheckCircle;
      color = 'success';
      iconUnicode = '2714';
      break;
    case 'rejected':
    case 'failed':
    case 'invalid':
    case 'incomplete':
    case 'notDone':
      icon = faTimesCircle;
      color = 'danger';
      iconUnicode = '274C';
      break;
    case 'pending':
    case 'inProgress':
      icon = faClock;
      color = 'secondary';
      iconUnicode = '1F553';
      authorId = null;
      break;
    case 'notStarted':
      icon = faMehBlank;
      color = 'secondary';
      iconUnicode = '1F553';
      authorId = null;
      break;
    default:
      icon = states?.[state]?.icon || faQuestionCircle;
      color = states?.[state]?.color || 'secondary';
      date = states?.[state]?.dateField
        ? format(new Date(item[states[state].dateField]), 'dd/MM/yyyy HH:mm')
        : date;
      // console.log("state author",state, states?.[state], states?.[state]?.authorField);
      authorId =
        states?.[state]?.authorField === null
          ? null
          : states?.[state]?.authorField
          ? item[states[state].authorField]
          : authorId;
      iconUnicode = states?.[state]?.iconUnicode;
      break;
  }
  const text = camelToSentence(state);
  const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : '';
  return {
    text,
    icon,
    color,
    formattedDate,
    date,
    authorId,
    iconUnicode
  };
};
export const domainStatusText = <TData extends Record<string, any>>(
  props: DomainStatusProps<TData>
) => {
  const { text, date, iconUnicode } = formatDomainStatus(props);
  return (
    (iconUnicode
      ? String.fromCodePoint(parseInt(iconUnicode, 16)) + '  '
      : '') +
    (text + (date ? ' (' + date + ')' : ''))
  );
};
export const domainStatusColumn = <
  TData extends Record<string, any>,
  TTableData extends Record<string, any>
>(
  id: string,
  domain: EventPrefix,
  {
    itemAccessor,
    ...options
  }: Partial<DomainStatusProps<TData>> & {
    itemAccessor?: (data: TTableData) => TData;
  } = {},
  dataKey: string & keyof TData = id
) => {
  return {
    id,
    accessorFn: d =>
      formatDomainStatus({
        domain,
        item: itemAccessor?.(d) || d[dataKey],
        ...options
      }).text,
    formatter: (_, d) => {
      if (!d[dataKey]) return '';
      return (
        <DomainStatusBadge
          domain={domain}
          item={itemAccessor?.(d) || d[dataKey]}
          {...options}
        />
      );
    },
    download: (_, d) =>
      domainStatusText({
        domain,
        item: itemAccessor?.(d) || d[dataKey],
        ...options
      })
  };
};
