import { useQuery } from "@tanstack/react-query";
import { customFormQuestionsApi } from "apis/flex/customForms";
import useArray from "hooks/useArray";
import { useMemo } from "react";
export default ({
  formId,
  select,
  enabled: __enabled,
  ...rest
}) => {
  const id = useArray(formId, (i) => i?.toString());
  const enabled = useMemo(() => {
    return !!id?.length && !!__enabled;
  }, [id, __enabled]);
  const { data, error, isLoading } = useQuery({
    queryKey: ["CustomForms", "CustomFormQuestions", id],
    queryFn: async () => {
      return await customFormQuestionsApi.get({
        first: "formId",
        second: id
      });
    },
    enabled,
    select,
    staleTime: Infinity
    // ...rest
  });
  return { data, error, isLoading };
};
