import { trainingApi } from "apis/flex/hr";
import {
  defaultCrudHookBuilder
} from "hooks/defaultCrud/useDefaultCrud";
export default defaultCrudHookBuilder("UserTraining", trainingApi.userCourses);
export const useEmployeeTrainingResources = defaultCrudHookBuilder(
  "EmployeeTrainingResources",
  trainingApi.userResources,
  { invalidate: ["UserTraining"] }
);
