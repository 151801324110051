import { EventPrefix } from 'apis/flex/notifications';
import { domainConfigs } from 'components/notification/config';
import { DefaultCrudData } from 'hooks/defaultCrud/useDefaultCrud';
import { GuardRole, useGuard } from 'hooks/useGuard';
import { useUser } from 'hooks/useUser';
import React from 'react';
import {
  FormProvider,
  useForm,
  UseFormReturn,
  useWatch
} from 'react-hook-form';
import QueryStateDisplay from './QueryStateDisplay';
import { FormPlaceholder } from 'components/wizard/FormWizard';
import ActionFooter from './ActionFooter';
import InputConfig from 'components/wizard/InputConfig';
import { WizardInputProps } from 'components/wizard/WizardInput';
import Error403 from 'components/errors/Error403';
import { RoleAction } from 'apis/flex/users';
import useArray from 'hooks/useArray';
import {
  callIfFunction,
  ensureArray,
  getCommonDataValues
} from 'helpers/utils';
import DetailWizard from './detail/DetailWizard';
export type DomainFormProps<TData, TFormData> = {
  domain: EventPrefix;
  itemId: number | number[];
  onFinished?: () => void;
  loadMutator?: (data: Partial<TData>) => TFormData;
  /** Returning an array will add multiple items. It will have no effect on updates, who will just use the first item */
  saveMutator?: (data: TFormData) => Partial<TData | TData[]>;
  children?:
    | React.ReactNode
    | ((props: {
        data: Partial<TData>;
        isLoading: boolean;
        submit: (done?: () => void, error?: (err: any) => void) => void;
        cancel: () => void;
        methods: UseFormReturn<TFormData, any, undefined>;
        showButtons: () => void;
        hideButtons: () => void;
      }) => React.ReactNode);
  inputConfig?: Partial<WizardInputProps>;
  guardRoles?: GuardRole[];
  selfView?: boolean;
  selfEdit?: boolean;
  submitText?: string;
  fields?: (keyof TFormData & string)[];
  isLoading?: boolean;
};

export const DomainForm = <TData extends DefaultCrudData, TFormData = TData>({
  domain,
  itemId,
  isLoading: _isLoading,
  onFinished,
  loadMutator = d => d as unknown as TFormData,
  saveMutator: __saveMutator,
  children,
  inputConfig,
  guardRoles,
  selfView,
  selfEdit,
  submitText,
  fields
}: DomainFormProps<TData, TFormData>) => {
  const itemIds = useArray(itemId);
  const configs = domainConfigs[domain];
  const {
    data: items,
    bulkUpdate,
    bulkAdd,
    isBulkAdding,
    isBulkUpdating,
    isLoading,
    queryError
  } = configs.crudHook<TData[]>({
    // id: itemId,
    // select: d => d[0],
    filters: { id: itemIds },
    useFilter: !!itemIds?.length,
    afterSave: () => onFinished?.()
  });
  const {
    employeeId: myEmpId,
    id: userId,
    employeeMedicalId,
    employeePayrollId
  } = useUser();
  const values = getCommonDataValues(items);
  const methods = useForm<TFormData>({
    values: loadMutator(values),
    //this should ensure we only send updates to those properties with form inputs
    // otherwise, it will try to update the entire domain item on any change.
    //If there are fields specified, no need to unregister
    shouldUnregister: !fields
  });
  const saveMutator = (vals: TFormData) => {
    const stripped = Object.keys(vals).reduce<TFormData>((acc, k) => {
      if (fields?.includes(k as keyof TFormData & string) || !fields)
        acc[k] = vals[k];
      return acc;
    }, {} as TFormData);
    return __saveMutator?.(stripped) || stripped;
  };
  const isSelf =
    (itemIds?.every(id => id === myEmpId) && domain === 'employee') ||
    (itemIds?.every(id => id === userId) && domain === 'user') ||
    (itemIds?.every(id => id === employeeMedicalId) &&
      domain === 'employee-medical-record') ||
    (itemIds?.every(id => id === employeePayrollId) &&
      domain === 'employee-payroll-record');
  const handleSubmit = (done?: () => void, error?: (err: any) => void) =>
    methods.handleSubmit(
      vals => {
        if (itemIds?.length) {
          bulkUpdate(
            {
              ids: itemIds,
              data: ensureArray(saveMutator(vals))[0],
              isSelf
            },
            {
              onSuccess: done,
              onError: error
            }
          );
        } else {
          bulkAdd(ensureArray(saveMutator(vals)), {
            onSuccess: done,
            onError: error
          });
        }
      },
      err => {
        error?.(err);
        console.log(err);
      }
    )();
  const { canEdit: _canEdit, canView: _canView } = useGuard({
    roles: guardRoles || [domain],
    itemIds: itemIds
  });
  const canEdit = _canEdit || (isSelf && selfEdit);
  const canView = _canView || (isSelf && (selfView || selfEdit));
  // console.log('auth check', {
  //   domain,
  //   canEdit,
  //   canView,
  //   isSelf,
  //   selfEdit,
  //   selfView,
  //   itemId,
  //   itemIds
  // });
  const [shouldShowButtons, showButtons] = React.useState(true);
  return !canView ? (
    <Error403 permissions={guardRoles || [domain + '.view']} />
  ) : (
    <QueryStateDisplay
      LoadingComponent={FormPlaceholder}
      isLoading={isLoading || _isLoading}
      error={queryError}
    >
      <FormProvider {...methods}>
        <InputConfig readOnly={!canEdit} {...inputConfig}>
          {typeof children === 'function'
            ? callIfFunction(children, {
                data: values,
                isLoading,
                submit: handleSubmit,
                cancel: onFinished,
                methods,
                showButtons: () => showButtons(true),
                hideButtons: () => showButtons(false)
              })
            : children}
        </InputConfig>
      </FormProvider>
      {shouldShowButtons && (
        <ActionFooter
          onSubmit={() => handleSubmit()}
          onCancel={onFinished}
          isLoading={isBulkAdding || isBulkUpdating}
          submitText={submitText || itemIds?.length ? 'Save' : 'Add'}
        />
      )}
    </QueryStateDisplay>
  );
};
export const DomainWizard = <
  TData extends DefaultCrudData = any,
  TFormValues = TData
>({
  domain,
  itemId,
  children,
  ...rest
}: {
  domain: EventPrefix;
  itemId?: number | number[];
  children: React.ReactNode;
} & Partial<DomainFormProps<TData, TFormValues>>) => {
  return (
    <DomainForm<TData, TFormValues> domain={domain} itemId={itemId} {...rest}>
      {({ methods, hideButtons, submit }) => (
        <DetailWizard
          freeNav={false}
          onFinished={v => {
            // methods.reset(v.data);
            // console.log('resetting to', v.data);
            setTimeout(() => {
              // console.log('submitting with vals', methods.getValues());
              submit(v.done, v.err);
            }, 200);
          }}
          onRender={() => hideButtons()}
        >
          {children}
        </DetailWizard>
      )}
    </DomainForm>
  );
};
