import { getDomainItemEvents } from "apis/flex/helpers";
import { useInfiniteQuery } from "@tanstack/react-query";
export default ({
  domain,
  itemId,
  actions = ["added", "changed", "removed", "loggedin"],
  authorIds,
  pageSize = 10,
  enabled = true,
  extraFilters,
  customFilters
}) => {
  return useInfiniteQuery({
    queryKey: [
      "DomainEventsPaged",
      domain,
      itemId,
      actions,
      authorIds,
      extraFilters,
      customFilters
    ],
    enabled: !!(enabled && (domain || authorIds?.length) && (itemId || authorIds?.length)),
    queryFn: ({ pageParam = 1 }) => getDomainItemEvents(
      {
        domain,
        itemId,
        actions,
        authorIds,
        extraFilters,
        customFilters
      },
      { page: pageParam, pageSize }
    ),
    staleTime: 1e3 * 30,
    getNextPageParam: (lastPage, allPages) => lastPage.length === 10 ? allPages.length + 1 : void 0
  });
};
