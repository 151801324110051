import DetailPage, {
  BottomBarAction,
  DetailPageProps,
  Tag
} from 'components/common/detail/DetailPage';
import React, { ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DefaultCrudData,
  defaultCrudHookBuilder,
  UseCrudProps
} from 'hooks/defaultCrud/useDefaultCrud';
import { EventPrefix } from 'apis/flex/notifications';
import { domainToSentence } from './DomainTimeline';
import { domainConfigs } from 'components/notification/config';
import ActionListener from 'components/notification/ActionListener';
import DomainCalendar from './DomainCalendar';
import { callIfFunction } from 'helpers/utils';
import Error403 from 'components/errors/Error403';
import Error500 from 'components/errors/Error500';
import { useGuard } from 'hooks/useGuard';
import useTags from 'hooks/useTags';
import {
  convertRulesFromForm,
  convertRulesToForm,
  FormAvailabilityRule
} from './DomainAvailabilityRules';
import { getErrorCode } from 'apis/errors';
import { getDomainHome } from 'hooks/useDomainRouter';
import { useUser } from 'hooks/useUser';

export default <
  TData extends DefaultCrudData & {
    id?: number;
    name?: string;
    createdBy?: number;
    createdDate?: Date;
    modifiedDate?: Date;
    modifiedBy?: number;
  } = any,
  TMutated extends { availabilityRules?: any[] } = any
>({
  domain,
  crudHook: passedCrudHook,
  children,
  crudProps,
  actions,
  avatar,
  background,
  isSelf: __isSelf,
  itemId,
  title,
  isLoading,
  description,
  tags,
  saveMutator: __saveMutator,
  loadMutator: __loadMutator,
  readOnly,
  ...rest
}: {
  children?: React.ReactNode;
  domain: EventPrefix;
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: UseCrudProps<TData, TData>;
  actions?: BottomBarAction[] | ((data: TData) => BottomBarAction[]);
  isSelf?: boolean | ((data: TData) => boolean);
  title?: string | ((data: TData) => string);
  description?: ReactNode | ((data: TData) => ReactNode);
  tags?: Tag[] | ((data: TData) => Tag[]);
} & Partial<
  Omit<
    DetailPageProps<TData, TMutated>,
    'allData' | 'data' | 'baseUrl' | 'actions' | 'title' | 'isSelf' | 'tags'
  >
>) => {
  const d = useParams();
  const config = domainConfigs[domain];
  const crudHook = passedCrudHook || config.crudHook<TData>;
  const rawId = itemId || d.id || d[config.foreignKey];
  const id = rawId && !isNaN(Number(rawId)) ? Number(rawId) : undefined;
  const {
    data,
    isLoading: dataLoading,
    upsert,
    isUpserting,
    queryError,
    meta
  } = crudHook({
    id,
    select: d => d?.[0],
    noReturnOnChange: false,
    ...crudProps
  });
  const saveMutator = (data: TMutated) => {
    const custom = __saveMutator?.(data) || (data as unknown as TData);
    if (meta?.availabilityRules) {
      const availabilityRules = convertRulesFromForm(
        custom.id,
        data.availabilityRules
      );
      return { ...custom, availabilityRules };
    }
    return custom;
  };
  const loadMutator = (data: TData) => {
    const custom = __loadMutator?.(data) || (data as unknown as TMutated);
    if (meta?.availabilityRules) {
      custom.availabilityRules = convertRulesToForm(data.availabilityRules);
    }
    return custom;
  };
  const nav = useNavigate();
  const itemName = data
    ? config.format?.(data)?.label ||
      data?.name ||
      `${domainToSentence(domain)} #${id}`
    : null;
  const { canView } = useGuard({
    roles: domain && [domain],
    itemIds: id && [id]
  });
  const isSelf = callIfFunction(__isSelf, data);
  const user = useUser();
  if (!canView && !!domain && !!itemId && !isSelf)
    return <Error403 permissions={[domain + '.view']} />;
  return (
    <DetailPage
      readOnly={callIfFunction(readOnly, data)}
      version={data?.version}
      title={
        callIfFunction(title, data) ||
        (data
          ? config.format?.(data)?.label ||
            data?.name ||
            'Editing ' + domainToSentence(domain)
          : 'New ' + domainToSentence(domain))
      }
      tags={(callIfFunction(tags, data) || []).concat(
        data?.tags?.map(t => t.tagId) || []
      )}
      description={callIfFunction(description, data)}
      data={data}
      isSelf={isSelf}
      isLoading={isLoading || dataLoading}
      onSave={(v, d) => upsert(v, d, isSelf)}
      isSaving={isUpserting}
      afterSave={() => {
        nav(getDomainHome(domain, user, data));
      }}
      domain={domain}
      itemId={id}
      createdBy={data?.modifiedBy || data?.createdBy}
      createdDate={data?.modifiedDate || data?.createdDate}
      actions={callIfFunction(actions, data)}
      authItemCollection={domain}
      authItemId={id}
      background={
        background && {
          authItemCollection: domain,
          authItemId: id,
          ...background
        }
      }
      avatar={
        avatar && {
          authItemCollection: domain,
          authItemId: id,
          ...avatar
        }
      }
      loadMutator={loadMutator}
      saveMutator={saveMutator}
      {...rest}
    >
      {getErrorCode(queryError) === 403 ? (
        <Error403 error={queryError} />
      ) : queryError ? (
        <Error500 error={queryError} />
      ) : (
        <>
          <ActionListener eventName={`${domain}-added`} actioned itemId={id} />
          {children}
          <DomainCalendar title={itemName} domain={domain} itemId={id} />
        </>
      )}
    </DetailPage>
  );
};
