import React, { ReactNode } from 'react';
import { Button, Card, Dropdown, Modal } from 'react-bootstrap';
import Flex from './Flex';
import IconButton, { IconButtonProps } from './IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import DomainIcon from './DomainIcon';
import { BottomBarAction } from './detail/DetailPage';
import Attention from './Attention';
import Wrapper from 'helpers/Wrapper';
import { ModalConfirm, ResponsiveModal } from './Modals';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { callIfFunction } from 'helpers/utils';
import { apiPromise } from 'apis/errors';
export const resolveOptionalBooleanProperty = (value: any) =>
  typeof value === 'undefined'
    ? false
    : typeof value === 'boolean'
    ? value
    : value();
export const ActionButton = ({
  action,
  ...rest
}: { action: BottomBarAction } & Partial<IconButtonProps>) => {
  const [showComponent, setShowComponent] = React.useState(false);
  // console.log(
  //   'actionbutton',
  //   action.label,
  //   action,
  //   action.loading,
  //   resolveOptionalBooleanProperty(action.loading)
  // );
  const [showModal, setShowModal] = React.useState(false);
  console.log('attention check', action.attention);
  return (
    <>
      <Attention
        {...rest}
        trigger={action.attention}
        setTrigger={action.setAttention}
      >
        <Wrapper
          condition={!!action.confirm}
          wrapper={c => (
            <ModalConfirm
              body={action.confirm}
              onConfirm={done => action.fn(done)}
              disabled={resolveOptionalBooleanProperty(action.disabled)}
              variant={action.variant}
            >
              {c}
            </ModalConfirm>
          )}
        >
          <IconButton
            loading={resolveOptionalBooleanProperty(action.loading)}
            variant={action.variant || 'falcon-default'}
            icon={action.icon}
            iconAlign="left"
            onClick={() => {
              if (action.confirm) return;
              if (action.modal) {
                setShowModal(true);
                return;
              }
              if (action.fn) {
                const promise = action.fn(() => {
                  setShowComponent(false);
                  setShowModal(false);
                });
                if (
                  promise instanceof Promise &&
                  action.loading === undefined
                ) {
                  apiPromise(promise);
                }
              }
              if (action.component) {
                setShowComponent(!showComponent);
              }
            }}
            disabled={resolveOptionalBooleanProperty(action.disabled)}
            className={'rounded-pill'}
            breakpoint="md"
            size={action.size}
            {...rest}
          >
            {action.label}
          </IconButton>
          {showComponent && action.component()}
          {action.modal && (
            <ResponsiveModal
              show={showModal}
              onHide={() => setShowModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{action.label}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {action.modal({ hide: () => setShowModal(false) })}
              </Modal.Body>
            </ResponsiveModal>
          )}
        </Wrapper>
      </Attention>
    </>
  );
};
export const ActionButtons = ({
  actions,
  drop = 'up',
  maxShown = 10,
  children
}: {
  actions: BottomBarAction[];
  drop?: DropDirection;
  maxShown?: number;
  children?: ReactNode;
}) => {
  const toShow = actions.filter(a =>
    a.show === undefined || typeof a.show === 'boolean'
      ? a.show !== false
      : a.show()
  );
  const submit = toShow.filter(a => a.isSubmit);
  const primaries = toShow.filter(a => a.isPrimary && !a.isSubmit);
  const nonPrimaries = toShow.filter(a => !(a.isPrimary || a.isSubmit));
  const buttons = submit
    .concat(primaries)
    .concat(nonPrimaries)
    .slice(0, maxShown);
  const others = submit.concat(primaries).concat(nonPrimaries).slice(maxShown);
  return (
    <Flex className="gap-1" justifyContent={'end'}>
      {buttons.map((button, i) => (
        <ActionButton
          // size={breakpoints.down('lg') ? 'sm' : null}
          key={i}
          action={button}
        />
      ))}
      {others.length > 0 && (
        <Dropdown drop={drop}>
          {children || (
            <Dropdown.Toggle
              // size={breakpoints.down('lg') ? 'sm' : null}
              className={
                'rounded-pill d-flex align-items-center justify-content-center h-100 dropdown-caret-none'
              }
              variant="falcon-default"
            >
              <FontAwesomeIcon icon="ellipsis-h" />
            </Dropdown.Toggle>
          )}
          <Dropdown.Menu className="py-1">
            {others.map((action, i) => (
              <ActionButton
                className="w-100 text-start shadow-none white-space-nowrap flex-nowrap"
                justify="start"
                // size={breakpoints.down('lg') ? 'sm' : null}
                key={i}
                action={action}
                breakpoint="xs"
                variant={'falcon-' + (action.variant || 'default')}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Flex>
  );
};
const BottomBar = ({
  avatar,
  title,
  actions,
  isLoading,
  show = true,
  domain = null,
  maxShown
}: {
  avatar?: string;
  title?: string;
  actions?: BottomBarAction[];
  isLoading?: boolean;
  show?: boolean;
  domain?: string;
  maxShown?: number;
}) => {
  return (
    <Card
      className={classNames(
        `bottom-bar pb-3 pt-1 rounded-0 ${show ? 'show' : 'hide'}`
      )}
    >
      <Card.Body className="py-2 px-2 d-flex justify-content-between align-items-center w-100">
        <div className="d-flex pe-2 align-items-center gap-2">
          {isLoading ? (
            <Skeleton className="avatar-2xl rounded-circle me-2" />
          ) : avatar ? (
            <img
              className="avatar-2xl rounded-circle me-2"
              src={avatar}
              alt=""
            />
          ) : (
            !!domain && <DomainIcon domain={domain} />
          )}

          {isLoading ? (
            <Skeleton width={200} />
          ) : (
            <span className="fw-semi-bold mb-0 text-800 text-word-break">
              {title}
            </span>
          )}
        </div>
        {isLoading ? (
          <Skeleton width={200} height={35} />
        ) : (
          <ActionButtons actions={actions} maxShown={maxShown} />
        )}
      </Card.Body>
    </Card>
  );
};
export default BottomBar;
