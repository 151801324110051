import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import blankUser from 'assets/img/team/avatar.png';
import CustomTooltip from 'components/common/Tooltip';
import UserIcon from './UserIcon';
import usePublicUsers from '../hooks/usePublicUsers';

export const UserIconsTooltip = ({ ids }) => (
  <div className="text-start">
    {ids
      ?.filter((d, i) => i < 3)
      .map(id => <UserIcon showName hideJobTitle key={id} id={id} />)
      .concat(
        ids.length > 3 && (
          <h6 key={'more'} className="fw-semi-bold fs--1 text-1000">
            +{ids.length - 3} more
          </h6>
        )
      )}
  </div>
);
UserIconsTooltip.propTypes = {
  ids: PropTypes.array
};
export type AvatarSize = 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
const UserIcons = ({
  ids,
  size,
  className
}: {
  ids: number[];
  size?: AvatarSize;
  className?: string;
}) => {
  const filteredIds = ids?.filter(id => !!id);
  const { data: users, isLoading } = usePublicUsers({
    useFilter: !!filteredIds?.length,
    enabled: !!filteredIds?.length,
    filters: { id: filteredIds }
  });
  const avatarSrc = users?.map(u => u.avatar || blankUser);
  return (
    <CustomTooltip content={<UserIconsTooltip ids={filteredIds} />}>
      <div className={className}>
        <Avatar
          src={(filteredIds?.length > 0 && avatarSrc) || blankUser}
          className={`status-offline`}
          mediaClass={'border border-3 rounded-circle border-200 bg-100'}
          isLoading={!!filteredIds?.length && isLoading}
          size={size}
        />
      </div>
    </CustomTooltip>
  );
};
export default UserIcons;
