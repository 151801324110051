import {
  faGripHorizontal,
  faGripLinesVertical,
  faGripVertical
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import React, { HTMLAttributes } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export default ({
  dragHandleProps,
  iconProps,
  horizontal,
  vertical,
  ...props
}: {
  dragHandleProps: DraggableProvidedDragHandleProps;
  iconProps?: FontAwesomeIconProps;
  vertical?: boolean;
  horizontal?: boolean;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...dragHandleProps} {...props}>
      <FontAwesomeIcon
        className="text-400"
        icon={horizontal ? faGripLinesVertical : faGripVertical}
        size="1x"
      />
    </div>
  );
};
