import { useFormContext } from "react-hook-form";
export default ({
  index,
  onlyOnce: onlyOnceMain
} = {}) => {
  const { setValue, getValues } = useFormContext();
  const setResourceValue = (fieldName, value, onlyOnce = onlyOnceMain, _index = index) => {
    const current = getValues(`resources.${_index}.${String(fieldName)}`);
    if (!current || !onlyOnce) {
      setValue(`resources.${_index}.${String(fieldName)}`, value, {
        shouldDirty: true
      });
    }
  };
  return {
    setValue: setResourceValue,
    getValues,
    getResource: (_index = index) => ({
      userCourseId: getValues(`id`),
      ...getValues(`resources.${_index}`)
    }),
    getValue: (fieldName, _index = index) => getValues(`resources.${_index}.${fieldName}`)
  };
};
