import { ScrollListener } from 'components/common/InfiniteScroller';
import { useNotificationAction } from 'components/notification/ActionListener';
import Notification, {
  NotificationPlaceholder
} from 'components/notification/Notification';
import { useGroupedUserNotifications } from 'components/notification/useNotifications';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default () => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useGroupedUserNotifications();
  const notifications = data?.pages?.flat() || [];
  const allData = data?.pages?.flat() || [];
  const { setSeen, setActioned } = useNotificationAction({
    eventName: notifications.map(d => d.eventName)
  });
  // State

  useEffect(() => {
    const unseenNotifications = allData.filter(d => !d.seen);
    setSeen({ eventName: unseenNotifications.map(d => d.eventName) });
  }, [data]);

  const markAllRead = e => {
    e.preventDefault();
    setActioned({
      eventName: notifications.map(d => d.eventName)
    });
  };
  const handleNotificationClick = notification => {
    return e => {
      e.preventDefault();
      setActioned({ id: [notification.id] });
    };
  };
  // const listRef = useRef(null);
  // const handleScroll = isFetchingNextPage => () => {
  //   const container = listRef.current;
  //   if (
  //     container.scrollTop + container.clientHeight === container.scrollHeight &&
  //     !isFetchingNextPage
  //   ) {
  //     fetchNextPage();
  //   }
  // };
  // useEffect(() => {
  //   listRef.current?.addEventListener(
  //     'scroll',
  //     handleScroll(isFetchingNextPage)
  //   );
  //   return () =>
  //     listRef.current?.removeEventListener(
  //       'scroll',
  //       handleScroll(isFetchingNextPage)
  //     );
  // }, [listRef.current, isFetchingNextPage]);
  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col sm="auto">
            <h5 className="mb-1 mb-md-0">Your Notifications</h5>
          </Col>
          <Col sm="auto" className="fs--1">
            <Link
              to="#!"
              onClick={markAllRead}
              className="font-sans-serif ms-2 ms-sm-3"
            >
              Mark all as read
            </Link>
            <div className="font-sans-serif ms-2 ms-sm-3"></div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0 overflow-y-auto" style={{ maxHeight: '70vh' }}>
        {isFetching && !isFetchingNextPage && !data?.pages?.[0]?.length
          ? [0, 0, 0, 0].map((_, i) => <NotificationPlaceholder key={i} />)
          : null}
        {notifications.map((notification, i) => (
          <div onClick={handleNotificationClick(notification)} key={i}>
            {/* <Notification notification={notification} key={notification.id} /> */}
            <Notification className="fs-0" notification={notification}>
              {notification.latestMessage?.title}
            </Notification>
          </div>
        ))}
        <ScrollListener
          fetchNextPage={fetchNextPage}
          canFetch={!!data?.pages?.[0]?.length}
          isFetching={isFetchingNextPage || isFetching}
        >
          <NotificationPlaceholder />
        </ScrollListener>
      </Card.Body>
    </Card>
  );
};
