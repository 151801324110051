import React, { useEffect, useState } from 'react';
import usePrescreening from './hooks/usePrescreening';
import { faFileImage, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { IDCheck, ScreeningDocType } from 'apis/flex/hr';
import IdentityCheck from './IdentityCheck';
import RightToWorkCheck from './RightToWorkCheck';
import CustomTabs from 'components/common/CustomTabs';
import { FileId } from 'apis/types';
import Success from 'components/wizard/Success';
import PageLoader from 'components/common/PageLoader';
import useUrlSearchParams from 'hooks/useUrlSearchParams';

const ParamsTabWatcher = ({ setActiveTab }) => {
  const u = useUrlSearchParams();
  useEffect(() => {
    if (u?.state) {
      const parsed = JSON.parse(u.state);
      if (parsed.checkId) {
        setActiveTab(parsed.checkId);
      }
    }
  }, [u]);
  return <></>;
};

export default ({
  id,
  review,
  onSubmitted = () => null
}: {
  id: number;
  review?: boolean;
  onSubmitted?: () => void;
}) => {
  const { data, upsertSelf, isUpserting } = usePrescreening({
    id,
    select: d => d[0]
  });

  const handleCheckSubmit =
    (check: IDCheck, index: number) =>
    (
      vals: {
        livenessFileId?: FileId;
        shareCode?: string;
        photoId?: FileId;
        fileIds?: FileId[];
        docType?: ScreeningDocType;
        dateOfBirth?: Date;
      },
      done: () => void
    ) => {
      const nextCheck = data?.checks[index + 1]?.id;
      if (review) {
        onSubmitted();
        if (nextCheck) {
          setActiveTab(nextCheck);
        }
        return done();
      }
      upsertSelf(
        {
          ...data,
          checks: data.checks.map(c =>
            c.id === check.id
              ? { ...check, ...vals, submittedDate: new Date() }
              : c
          )
        },
        resp => {
          done();
          if (resp?.checks?.every(c => c.submittedDate)) {
            onSubmitted();
          }
          if (nextCheck) {
            setActiveTab(nextCheck);
          }
        }
      );
    };
  const [activeTab, setActiveTab] = useState(data?.checks[0]?.id);
  return isUpserting ? (
    <PageLoader height="20vh" />
  ) : (
    <>
      <ParamsTabWatcher setActiveTab={setActiveTab} />
      <CustomTabs
        activeKey={activeTab}
        setActiveKey={setActiveTab}
        items={data?.checks.map((check, i) => ({
          id: check.id,
          title: check.checkType,
          tabClass:
            check.submittedDate &&
            (check.approvedDate
              ? 'badge-soft-success'
              : check.rejectedDate
              ? 'badge-soft-danger'
              : 'badge-soft-info'),
          icon: check.checkType === 'Identity' ? faUserSecret : faFileImage,
          content: () => (
            <CheckContent
              check={check}
              review={review}
              onSubmit={handleCheckSubmit(check, i)}
            />
          )
        }))}
      />
    </>
  );
};
const CheckContent = ({
  check,
  review,
  onSubmit
}: {
  check: IDCheck;
  review?: boolean;
  onSubmit?: (data: any, done: () => void) => void;
}) => {
  return check.submittedDate && !review ? (
    <Success
      className="mb-4"
      title="Thank you"
      description={`Your ${check.checkType} documents have been submitted. Please continue with the other checks.`}
    />
  ) : (
    <IDCheckForm
      review={review || !!check.submittedDate}
      check={check}
      onSubmit={onSubmit}
    />
  );
};
export const IDCheckForm = ({
  check,
  onSubmit,
  review = false
}: {
  check: IDCheck;
  onSubmit?: (data: any, done: () => void) => void;
  review?: boolean;
}) => {
  return check.checkType === 'Identity' ? (
    <IdentityCheck review={review} check={check} onSubmit={onSubmit} />
  ) : check.checkType === 'Right to work' ? (
    <RightToWorkCheck review={review} check={check} onSubmit={onSubmit} />
  ) : null;
};
