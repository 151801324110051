import React from 'react';
import { EmployeeShift } from 'apis/flex/hr';
import DetailCard from 'components/common/detail/DetailCard';
import { getDomainInput, ImmutableInput } from 'components/common/DomainInput';
import { EmployeeSelector } from '../../staff/widgets/EmployeeSelector';
import { AvailableEmployeeShiftsSelector } from '../ShiftSelector';
import { useWatch } from 'react-hook-form';
import { DomainWizard } from 'components/common/DomainForm';
import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import { ensureArray } from 'helpers/utils';
const Input = getDomainInput<EmployeeShift>();
type EmployeeShiftFormValues = Omit<Partial<EmployeeShift>, 'employeeId'> & {
  employeeId: number[];
};
export default ({
  itemId,
  onFinished
}: {
  itemId?: number | number[];
  onFinished?: () => void;
}) => {
  return (
    <DomainWizard<
      Partial<EmployeeShift> & { id: number },
      Omit<Partial<EmployeeShift>, 'employeeId'> & { employeeId: number[] }
    >
      domain="employee-shift"
      itemId={itemId}
      onFinished={onFinished}
      loadMutator={d => ({ ...d, employeeId: ensureArray(d.employeeId) })}
      saveMutator={d => {
        return d.employeeId.map(employeeId => ({
          ...d,
          id: null,
          employeeId
        }));
      }}
    >
      <DetailCard id="setup" title="Setup">
        <EmployeeSelector name="employeeId" label="Employee(s)" multiple />
        <Input type="date" name="date" pluginProps={{ futureOnly: true }} />
      </DetailCard>
      <DetailCard id="shift" title="Shift">
        <Shift />
      </DetailCard>
    </DomainWizard>
  );
};
export const EmployeeShiftSelector =
  getDomainItemSelector<EmployeeShift>('employee-shift');
export const SelectedEmployeeShifts =
  getSelectedDomainItems<EmployeeShift>('employee-shift');
const Shift = () => {
  const date = useWatch<EmployeeShiftFormValues, 'date'>({ name: 'date' });
  const employeeId = useWatch<EmployeeShiftFormValues, 'employeeId'>({
    name: 'employeeId'
  });
  return (
    !!(date && employeeId) && (
      <AvailableEmployeeShiftsSelector
        startDate={date}
        endDate={date}
        name="shiftId"
        employeeId={employeeId}
      />
    )
  );
};
