import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch
} from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import {
  getItemFromStore,
  isIterableArray,
  setItemToStore
} from 'helpers/utils';
import Success, { SuccessPageProps } from './Success';
import LoadingButton from 'components/common/LoadingButton';
import Divider from 'components/common/Divider';
import useMediaForm from 'hooks/useMediaForm';
import { formWizardProps } from './types';
import Screenedout from './Screenedout';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RoleDomain } from 'apis/flex/users';
import InputConfig from './InputConfig';
import { apiError } from 'apis/errors';
import Recaptcha from './Recaptcha';
import Error401 from 'components/errors/Error401';

const recursiveMap = children => {
  return React.Children.map(children, child => {
    if (isIterableArray(child?.props?.children)) {
      return React.cloneElement(child, {
        ...child.props,
        disabled: true,
        review: true,
        children: recursiveMap(child.props.children)
      });
    }
    if (child?.props?.element) {
      return React.cloneElement(child.props.element, {
        ...child.props.element.props,
        disabled: true,
        review: true
        // children: recursiveMap(child.props.element.props.children)
      });
    } else {
      return React.cloneElement(child, {
        ...child.props,
        disabled: true,
        review: true
      });
    }
  });
};
const getPages = children => {
  if (!children) return [];
  const pages = [];
  const recurse = children => {
    React.Children.map(children, child => {
      if (React.isValidElement(child) && child.type === FormWizard.Page) {
        pages.push(child);
      }
      if (isIterableArray(child?.props?.children)) {
        recurse(child.props.children);
      }
    });
  };
  recurse(children);
  return pages;
};
export const getPageForms = (children, fieldArray) =>
  getPages(fieldArray?.pagesBefore && fieldArray?.pagesBefore())
    .concat(getPages(children))
    .concat(
      !fieldArray
        ? []
        : fieldArray.data.map((field, i) => (
            <Page
              key={i}
              {...{
                icon: fieldArray.icon(field, i),
                label: fieldArray.label(field, i),
                delayNext:
                  fieldArray.delayNext && fieldArray.delayNext(field, i)
              }}
              onSubmit={
                fieldArray.onSubmit &&
                function (vals, done, fail, screenout) {
                  fieldArray.onSubmit(field, vals, done, fail, screenout);
                }
              }
            >
              {field.output
                ? field.output(field, i)
                : fieldArray.output(field, i)}
            </Page>
          ))
    )
    .concat(getPages(fieldArray?.pagesAfter && fieldArray?.pagesAfter()));
const ReviewPage = ({ pageForms, getFormVals }) => {
  const methods = useForm({
    shouldUnregister: true,
    defaultValues: getFormVals(),
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });
  useEffect(() => {
    methods.reset(getFormVals());
  }, []);
  return (
    <FormProvider {...methods}>
      <fieldset disabled>
        {pageForms.map(p => (
          <>
            <Divider className="py-2">{p.props.label}</Divider>
            {recursiveMap(p)}
          </>
        ))}
      </fieldset>
    </FormProvider>
  );
};
ReviewPage.propTypes = {
  pageForms: PropTypes.array,
  getFormVals: PropTypes.func
};
type FormPageProps = {
  pageRef?: React.RefObject<HTMLDivElement>;
  step: number;
  setStep: (step: number) => void;
  isLoading: boolean;
  totalSteps: number;
  success?: SuccessPageProps & { element?: ReactNode };
  pageForms: JSX.Element[];
  onSubmit: (screenoutIds?: (string | number)[]) => void;
  review?: boolean;
  allowPrev?: boolean;
  isSubmitting?: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  getFormVals: () => any;
  setScreenoutIds: (screenoutIds: (string | number)[]) => void;
  screenoutIds: (string | number)[];
  onScreenout: OnWizardScreenout;
  readOnly?: boolean;
  fluid?: boolean;
  testMode?: boolean;
};
export type UseFormPageReturn = {
  setShowNext: (show: boolean) => void;
};
const FormPageContext = createContext<UseFormPageReturn>(null);
export const useFormPage = () => {
  return useContext(FormPageContext);
};
const ErrorRevalidator = () => {
  const allVals = useWatch();
  const { trigger } = useFormContext();
  const { errors } = useFormState();
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      trigger();
    }
  }, [errors, allVals]);
  return <></>;
};
const FormPage = ({
  step,
  setStep,
  isLoading,
  totalSteps,
  success,
  pageForms,
  onSubmit,
  review,
  allowPrev,
  isSubmitting,
  setIsSubmitting,
  getFormVals,
  setScreenoutIds,
  screenoutIds,
  onScreenout,
  readOnly,
  fluid,
  testMode,
  pageRef
}: FormPageProps) => {
  const CurrentForm = useCallback(() => {
    if (step === totalSteps) {
      return success?.element || <Success {...success} />;
    }
    if (step === totalSteps - 1 && review) {
      return <ReviewPage pageForms={pageForms} getFormVals={getFormVals} />;
    }
    return pageForms[step];
  }, [step, pageForms]);
  const {
    trigger,
    getValues,
    formState: { errors }
  } = useFormContext();
  const triggerIfNotReadOnly =
    readOnly || testMode ? () => Promise.resolve(true) : trigger;

  const onSubmitStep = () => {
    setIsSubmitting(true);
    triggerIfNotReadOnly().then(valid => {
      if (!valid) {
        setIsSubmitting(false);
        return console.log('ERRORS', errors);
      }
      const pageFormProps: Omit<WizardFieldArray, 'onSubmit'> & {
        onSubmit: (
          vals: any,
          done: (updatedScreenouts?: (string | number)[]) => void,
          fail: () => void,
          screenout: (questionId?: (number | string)[]) => void
        ) => void;
      } = pageForms[step]?.props;
      if (pageFormProps?.onSubmit && !readOnly) {
        const goToNext = updatedScreenouts => {
          if (step === totalSteps - 1) {
            return onSubmit(updatedScreenouts);
          }
          setIsSubmitting(false);
          setStep(step + 1);
        };
        return pageFormProps.onSubmit(
          getValues(),
          goToNext,
          () => setIsSubmitting(false),
          (questionIds: (string | number)[]) => {
            const unique = new Set([
              ...(screenoutIds || []),
              ...questionIds.map(id => id.toString())
            ]);
            const allScreenoutIds = [...unique];

            if (onScreenout) {
              onScreenout({
                vals: getFormVals(),
                screenoutIds: allScreenoutIds,
                submit: () => {
                  onSubmit(allScreenoutIds);
                },
                goToNext: () => {
                  goToNext(allScreenoutIds);
                  setScreenoutIds(questionIds);
                }
              });
            } else {
              goToNext(allScreenoutIds);
              setScreenoutIds(questionIds);
            }
            return allScreenoutIds;
          }
        );
      }
      if (step === totalSteps - 1 && !readOnly) {
        return onSubmit();
      }
      // console.log('setting step', step + 1);
      setStep(step + 1);
      setIsSubmitting(false);
    });
  };
  const [showNext, setShowNext] = useState(true);
  useEffect(() => {
    if (pageForms[step]?.props?.delayNext && !readOnly) {
      setShowNext(false);
      const to = setTimeout(
        () => setShowNext(true),
        pageForms[step]?.props?.delayNext
      );
      return () => clearTimeout(to);
    }
  }, [step, pageForms]);
  // console.log('readonly?', readOnly);
  return (
    <FormPageContext.Provider value={{ setShowNext }}>
      <Card.Body ref={pageRef} className="fw-normal px-md-6 py-4">
        <InputConfig readOnly={readOnly}>
          <fieldset aria-readonly={readOnly}>
            <Row className="justify-content-center">
              <Col xs={12} lg={fluid ? 8 : 12}>
                <CurrentForm></CurrentForm>
                {errors && <ErrorRevalidator />}
              </Col>
            </Row>
          </fieldset>
        </InputConfig>
      </Card.Body>
      {(step < totalSteps ||
        process.env.NODE_ENV === 'development' ||
        readOnly) && (
        <Card.Footer
          className={classNames('px-md-6 bg-light justify-content-between', {
            'd-none': step === totalSteps + 1,
            'd-flex': step < totalSteps
          })}
        >
          <IconButton
            variant="link"
            icon="chevron-left"
            iconAlign="left"
            breakpoint="xs"
            className={classNames({
              invisible: step === 0
            })}
            onClick={() => {
              setStep(step - 1);
            }}
            disabled={isLoading || !allowPrev}
          >
            Prev
          </IconButton>
          {step < totalSteps && showNext && (
            <>
              {step < totalSteps - 1 ? (
                <IconButton
                  breakpoint="xs"
                  variant="primary"
                  // className="px-5"
                  type="button"
                  icon={'chevron-right'}
                  iconAlign="right"
                  onClick={onSubmitStep}
                  loading={isLoading}
                >
                  Next
                </IconButton>
              ) : (
                !readOnly && (
                  <LoadingButton
                    variant="primary"
                    // className="px-5"
                    type="button"
                    onClick={onSubmitStep}
                    loading={isLoading || isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                )
              )}
            </>
          )}
        </Card.Footer>
      )}
    </FormPageContext.Provider>
    // </FormProvider>
  );
};
export const FormPlaceholder = () => {
  return (
    <>
      <Card.Body className="fw-normal px-md-6 py-4">
        <Skeleton className="mb-2 w-25" />
        <Skeleton className="mb-3 w-75" />
        <Skeleton className="mb-2 w-25" />
        <Skeleton className="mb-3 w-75" />
      </Card.Body>
      <Card.Footer className="px-md-6 bg-light justify-content-between d-flex">
        <Skeleton style={{ width: 150, height: 38 }} />
        <Skeleton style={{ width: 150, height: 38 }} />
      </Card.Footer>
    </>
  );
};
export const WizardNavs = ({
  step,
  totalSteps,
  isFormLoading,
  navItems,
  handleNavs
}) =>
  (step < totalSteps || isFormLoading) && (
    <Card.Header className="bg-light pb-2 px-0">
      <Nav className="justify-content-start flex-nowrap nav overflow-x-auto">
        {(isFormLoading ? [{}, {}, {}, {}, {}] : navItems).map(
          (item, index) =>
            item && (
              <NavItem
                key={index}
                index={index}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
                totalSteps={totalSteps}
                isLoading={isFormLoading}
              />
            )
        )}
      </Nav>
      <ProgressBar
        className="mt-2"
        style={{ height: 5, width: '100%' }}
        now={step}
        max={totalSteps}
      />
    </Card.Header>
  );
WizardNavs.propTypes = {
  isFormLoading: PropTypes.bool,
  navItems: PropTypes.array,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
  isLoading: PropTypes.bool
};
const WizardContext = createContext({});
export const useWizard = () => useContext(WizardContext);
export type WizardFieldArray<TPage = any> = {
  data: TPage[];
  icon: (page: TPage, index: number) => IconProp;
  label: (page: TPage, index: number) => React.ReactNode;
  output: (page: TPage, index: number) => React.ReactNode;
  onSubmit?: (
    page: TPage,
    vals: any,
    done: () => void,
    fail: () => void,
    screenout: (questionId?: (number | string)[]) => void
  ) => void;
  delayNext?: (page: TPage, index: number) => number;
  pagesBefore?: () => ReactNode;
  pagesAfter?: () => ReactNode;
};
export type OnWizardScreenout = (props: {
  vals: any;
  screenoutIds: (string | number)[];
  submit: () => void;
  goToNext: () => void;
}) => void;
export type OnWizardSubmit = (props: {
  data: any;
  originalData: any;
  done: () => void;
  err: (err: Error) => void;
  setAuthItemId: (id: number, done?: () => void) => void;
  screenoutIds: (string | number)[];
  clearStore: () => void;
}) => void;
export type FormWizardProps<TPage = any> = {
  children?: React.ReactNode;
  onSubmit: OnWizardSubmit;
  success?: SuccessPageProps & { element?: ReactNode };
  isLoading?: boolean;
  review?: boolean;
  storeId?: string;
  store?: typeof sessionStorage | typeof localStorage;
  fieldArray?: WizardFieldArray<TPage>;
  isFormLoading?: boolean;
  allowPrev?: boolean;
  onScreenout?: OnWizardScreenout;
  authItemCollection?: RoleDomain;
  authItemId?: number;
  continueOnScreenout?: boolean;
  readOnly?: boolean;
  data?: any;
  fluid?: boolean;
  companyId?: number;
  recaptcha?: boolean;
  onChange?: (data: any) => void;
  bottomElement?: ReactNode;
  topElement?: ReactNode;
  testMode?: boolean;
  /** Navigate freely around the chapters */
  freeNav?: boolean;
};
const FormWizard = <TPage extends Record<string, any> = any>({
  children,
  onSubmit,
  success,
  isLoading,
  review,
  storeId,
  store = sessionStorage,
  fieldArray,
  isFormLoading: isFormLoadingExt,
  allowPrev,
  onScreenout = ({ goToNext }) => {
    goToNext();
  },
  authItemCollection,
  authItemId,
  continueOnScreenout,
  readOnly,
  data,
  fluid,
  companyId,
  recaptcha,
  onChange,
  bottomElement,
  topElement,
  testMode,
  freeNav,
  ...rest
}: FormWizardProps<TPage>) => {
  const storage = useMemo(
    () => ({
      getItem: (key, val) => getItemFromStore(key, val, store),
      setItem: (key, val) => setItemToStore(key, val, store),
      removeItem: key => store.removeItem(key)
    }),
    [store]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [formValsStore, setFormValsStore] = useAtom(valsAtom);
  const cleanIfFile = (fileValue: any) => {
    const v = fileValue;
    if (!v) return v;
    if ((v.blob || v.preview) && (v.name || v.path)) {
      if (v?.uploadPath) {
        //it's a file that has been uploaded
        return v.uploadPath;
      }
      //it's a file that hasn't been uploaded yet
      return '';
    }
    return fileValue;
  };
  const setCacheVals = useCallback(
    vals => {
      if (readOnly) return;
      if (storeId) {
        const cleanVals = Object.entries<any>(vals).reduce((acc, [k, v]) => {
          //can't store files to the cache; the blob won't be there when the page reloads
          acc[k] = cleanIfFile(v);
          return acc;
        }, {});
        storage.setItem(storeId + '-formVals', cleanVals);
      } else {
        storage.removeItem(storeId + '-formVals');
      }
    },
    [storeId]
  );
  const methods = useMediaForm({
    authItemCollection,
    authItemId,
    reValidateMode: 'onChange',
    companyId
  });
  useEffect(() => {
    if (data && !methods.formState.isDirty) {
      methods.reset(data);
    }
  }, [data, methods.formState.isDirty]);
  useEffect(() => {
    if (storeId && !readOnly) {
      const vals = storage.getItem(storeId + '-formVals', null);
      if (vals) {
        const cleanVals = Object.entries<any>(vals).reduce((acc, [k, v]) => {
          //can't load files from the cache; the blob won't be there when the page reloads, and useForm will think all is well because it has a value
          acc[k] = cleanIfFile(v);
          return acc;
        }, {});
        methods.reset(cleanVals);
      }
      const step = storage.getItem(storeId + '-step', 0);
      setStepState(step);
    }
  }, [storeId, storage]);
  const [step, setStepState] = useState(0);
  useEffect(() => {
    // console.log('caching values', methods.getValues());
    setCacheVals(methods.getValues());
  }, [methods.getValues()]);
  const pageRef = useRef<HTMLDivElement>(null);
  const setStep = useCallback(
    val => {
      setStepState(val);
      //scroll to top
      pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [storeId, storage]
  );
  const pageForms = useMemo(
    () => getPageForms(children, fieldArray),
    [children, fieldArray]
  );
  const totalSteps = pageForms.length + (review ? 1 : 0);
  const isFormLoading = isFormLoadingExt || totalSteps === 0;
  const navItems = React.Children.map(pageForms, child => {
    return (
      !!child.props.label && {
        icon: child.props.icon,
        label: child.props.label
      }
    );
  });
  if (!readOnly) {
    if (review) {
      navItems.push({
        icon: 'eye',
        label: 'Review'
      });
    }
    navItems.push({
      icon: 'check',
      label: 'Done'
    });
  }
  const handleNavs = targetStep => {
    if (
      (targetStep < step && !!allowPrev) ||
      testMode ||
      (freeNav && targetStep < totalSteps)
    ) {
      // if (step != totalSteps) {
      setStep(targetStep);
      // }
    }
  };
  const clearStore = () => {
    storage.removeItem(storeId + '-formVals');
    storage.removeItem(storeId + '-step');
  };
  const handleFinalSubmit = (finalScreenoutIds?: (number | string)[]) => {
    const unpagedScreenouts = unpageScreenouts();
    const unique = new Set([
      ...unpagedScreenouts,
      ...(finalScreenoutIds || [])
    ]);
    methods.handleSubmit(
      vals => {
        onSubmit({
          data: vals,
          originalData: vals,
          done: () => {
            setIsSubmitting(false);
            setStep(step + 1);
          },
          err: err => {
            setIsSubmitting(false);
            apiError(err);
          },
          setAuthItemId: (id, done) =>
            methods.setAuthItemId(id, done, () => setIsSubmitting(false)),
          screenoutIds: [...unique].filter(d => d),
          clearStore
        });
      },
      err => {
        setIsSubmitting(false);
        apiError(err);
      }
    )();
  };
  const getFormVals = methods.getValues;
  const [screenoutIds, setScreenoutIds] = useState<(string | number)[][]>([]);
  useEffect(() => {
    setScreenoutIds(
      screenoutIds.map((page, i) => (i === step - 1 ? [] : page))
    );
    if (storeId && step > 0) {
      storage.setItem(storeId + '-step', step);
    }
  }, [step]);
  const pageScreenouts = (screenouts: (string | number)[]) => {
    const s = [...screenoutIds];
    s[step - 1] = [...screenouts].map(id => id.toString());
    return s;
  };
  const unpageScreenouts = () => {
    const u = screenoutIds?.flatMap(page => page.map(id => id.toString()));
    return u || [];
  };
  const setPageScreenouts = screenouts => {
    setScreenoutIds(pageScreenouts(screenouts));
  };
  const [passedRecaptcha, setPassedRecaptcha] = useState<boolean>(null);
  const [checked, setChecked] = useState(false);
  const recaptchaRef = useRef(null);
  useEffect(() => {
    if (!recaptcha) {
      return setPassedRecaptcha(true);
    }
    if (!checked) {
      recaptchaRef.current?.execute();
      setChecked(true);
    }
  }, [recaptcha]);
  console.log('form pages check', {
    step,
    totalSteps,
    isFormLoading,
    pageForms
  });
  return (
    <WizardContext.Provider value={{ clearStore }}>
      <FormProvider {...methods}>
        <Watcher onChange={onChange} />
        {topElement}
        <Card className="theme-wizard" {...rest}>
          {recaptcha && (
            <Recaptcha
              ref={recaptchaRef}
              onSuccess={() => setPassedRecaptcha(true)}
            />
          )}
          {passedRecaptcha === true ? (
            screenoutIds?.length > 0 && !continueOnScreenout ? (
              <Screenedout />
            ) : (
              <>
                <WizardNavs
                  {...{
                    step,
                    totalSteps,
                    isFormLoading,
                    navItems,
                    handleNavs
                  }}
                />
                {isFormLoading ? (
                  <FormPlaceholder />
                ) : (
                  <FormPage
                    pageRef={pageRef}
                    {...{
                      step,
                      setStep,
                      isLoading: isLoading,
                      totalSteps,
                      success,
                      pageForms,
                      getFormVals,
                      // setFormVals,
                      onSubmit: handleFinalSubmit,
                      review,
                      readOnly,
                      allowPrev,
                      setIsSubmitting,
                      isSubmitting,
                      setScreenoutIds: setPageScreenouts,
                      screenoutIds: unpageScreenouts(),
                      onScreenout,
                      fluid,
                      testMode
                    }}
                  />
                )}
                {/* {storeId && (
            <p className="my-3 px-7 text-center fs--1">
              Your position in this form is being automatically saved after each
              step, so feel free to leave this page and come back later, your
              progress won't be lost!
            </p>
          )} */}
              </>
            )
          ) : passedRecaptcha === false ? (
            <Error401 />
          ) : null}
        </Card>
        {bottomElement}
      </FormProvider>
    </WizardContext.Provider>
  );
};
FormWizard.propTypes = formWizardProps;
const Page = ({
  children,
  element
}: {
  children?: ReactNode;
  element?: ReactNode;
}) => {
  return (
    <div>
      {element}
      {children}
    </div>
  );
};
const Watcher = ({ onChange }) => {
  const vals = useWatch();
  useEffect(() => {
    onChange?.(vals);
  }, [vals]);
  return <></>;
};
Page.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.any,
  label: PropTypes.string,
  onSubmit: PropTypes.func
};
FormWizard.Page = Page;
const Input = ({ children }) => {
  return <div>{children}</div>;
};
Input.propTypes = Page.propTypes;
FormWizard.Input = Input;
const NavItem = ({
  index,
  step,
  handleNavs,
  icon,
  label,
  totalSteps,
  isLoading
}) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < totalSteps ? step > index : step > totalSteps,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          {isLoading || !icon ? (
            <Skeleton
              circle
              containerClassName="nav-item-circle"
              className="h-100"
              style={{ lineHeight: 3 }}
            />
          ) : (
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </span>
        <span className="d-none d-md-block mt-1 mx-3 fs--1">
          {isLoading ? <Skeleton /> : label}
        </span>
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  ...WizardNavs.propTypes,
  index: PropTypes.number.isRequired,
  icon: PropTypes.any,
  label: PropTypes.string,
  isLoading: PropTypes.bool
};

export default FormWizard;
