import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getGroupedUserNotifications,
  userNotificationsApi
} from "apis/flex/notifications";
import useReportData from "components/app/reporting/useReportData";
import {
  defaultCrudHookBuilder,
  mergeFilters
} from "hooks/defaultCrud/useDefaultCrud";
import { useUser } from "hooks/useUser";
import { useMemo } from "react";
const useNotifications = ({
  filters,
  ...rest
}) => {
  const { id: userId } = useUser();
  return useUserNotifications({
    filters: mergeFilters(filters, { first: "userId", second: userId }),
    useFilter: !!filters,
    staleTime: 1e3 * 60 * 2,
    ...rest
  });
};
export default useNotifications;
export const useUserNotifications = defaultCrudHookBuilder(
  "UserNotifications",
  userNotificationsApi
);
export const useNewDomainNotifications = ({
  domain,
  ...rest
}) => {
  return useNotifications({
    filters: { domain, actioned: "null" },
    staleTime: 1e3 * 60 * 2,
    ...rest
  });
};
export const useGroupedUserNotifications = (pageSize = 10) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery({
    queryKey: ["groupedUserNotifications"],
    // queryFn:({pageParam = { page: 1, pageSize: pageSize }})=>getGroupedUserNotifications(user.id,pageParam),
    queryFn: async ({ pageParam = { page: 1, pageSize } }) => {
      if (!pageParam) return [];
      return await getGroupedUserNotifications(pageParam);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === pageSize ? { page: pages.length + 1, pageSize } : void 0;
    }
  });
  return { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching };
};
export const useNotificationEventCounts = () => {
  const user = useUser();
  const data = useReportData({
    config: {
      id: void 0,
      type: "line",
      db: "Notifications",
      table: "UserNotifications",
      filters: [
        [{ question: "userId", type: "==", value: user.id.toString() }],
        [{ question: "actioned", type: "empty", value: "" }]
      ],
      xAxes: [
        {
          field: "domain"
        }
      ]
    }
  });
  return useMemo(
    () => ({
      ...data,
      totalCount: data?.data?.UserNotifications.reduce(
        (a, b) => a + b[0]?.count || 0,
        0
      ),
      eventCounts: data?.data?.UserNotifications.reduce((a, b) => {
        if (b[0]) {
          a[b[0].domain] = b[0]?.count || 0;
        }
        return a;
      }, {})
    }),
    [data]
  );
};
