import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'apis/errors';
import CrudApi from 'apis/flex/CrudApi';
import { devApi } from 'apis/flex/helpers';
import { ApiFilters } from 'apis/types';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import CrudTableProvider from 'components/common/advance-table-v2/CrudTableProvider';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { JSONCellFormatter } from 'components/common/JSONLister';
import LoadingButton from 'components/common/LoadingButton';
import PageHeader from 'components/common/PageHeader';
import SettingsBox from 'components/common/SettingsBox';
import WizardInput from 'components/wizard/WizardInput';
import { addMinutes } from 'date-fns';
import { CustomRule } from 'helpers/validation/validate';
import useEvents from 'hooks/useEvents';
import useMediaForm from 'hooks/useMediaForm';
import React from 'react';
import { Button, Card, InputGroup } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';

const DevActions = () => {
  return (
    <Card>
      <FalconCardHeader title="Actions" />
      <Card.Body>
        <InputForm
          fn={v => devApi.refireEvent(v.id, {}, !v.notifications)}
          text="Refire Event"
          defaultValues={{ notifications: true }}
        >
          <InputGroup>
            <WizardInput type="text" name="id" />
            <WizardInput
              type="checkbox"
              name="notifications"
              formGroupProps={{
                className: 'justify-content-sm-end'
              }}
            />
          </InputGroup>
        </InputForm>
        <InputForm
          fn={v => devApi.refireTransaction(v.id)}
          text="Refire Transaction"
        >
          <WizardInput type="text" name="id" />
        </InputForm>
        <InputForm
          fn={devApi.refreshEmplyeeSchedules}
          text="Refresh Employee Schedules"
        />
        <InputForm fn={devApi.testSocket} text="Test Socket" />
        <InputForm fn={devApi.triggerDbEvent} text="Fire DB Event">
          <WizardInput type="text" name="tableName" />
        </InputForm>
      </Card.Body>
    </Card>
  );
};
const InputForm = ({
  fn,
  text,
  children = null,
  defaultValues
}: {
  fn: (v: any) => any;
  text: string;
  children?: React.ReactNode;
  defaultValues?: any;
}) => {
  const methods = useMediaForm({ defaultValues });
  const { mutate, isLoading } = useMutation<any, ApiError, any>({
    mutationFn: v => fn(v)
  });
  return (
    <SettingsBox title={text} className="mb-4">
      <FormProvider {...methods}>
        {children}
        <LoadingButton
          loading={isLoading}
          onClick={methods.handleSubmit(v => mutate(v), console.error)}
        >
          {text || 'Submit'}
        </LoadingButton>
      </FormProvider>
    </SettingsBox>
  );
};
const EventsTable = () => {
  const [timeFilter, setTimeFilter] = React.useState<CustomRule>([]);
  return (
    <CrudTableProvider
      title="Events"
      crudHook={useEvents}
      sqlDb="System"
      sqlTables={['Events']}
      crudProps={{
        columns: [
          'id',
          'sagaId',
          'domain',
          'action',
          'createdDate',
          'data',
          'options',
          'itemId'
        ],
        customFilter: timeFilter
      }}
      globalActions={[
        {
          name: 'Refire',
          actionFn: async r => {
            await Promise.all(r.map(rr => devApi.refireEvent(rr.original.id)));
          },
          icon: faPaperPlane
        }
      ]}
      columns={[
        'id',
        {
          id: 'createdDate',
          type: 'datetime',
          formatter: (v, d) => (
            <Button
              variant="link"
              className="py-0"
              size="sm"
              onClick={() => {
                setTimeFilter([
                  [
                    {
                      question: 'createdDate',
                      type: '>=',
                      value: addMinutes(
                        new Date(d.createdDate),
                        -5
                      ).toISOString()
                    },
                    {
                      question: 'createdDate',
                      type: '<=',
                      value: addMinutes(
                        new Date(d.createdDate),
                        5
                      ).toISOString()
                    }
                  ]
                ]);
              }}
            >
              {new Date(d.createdDate).toLocaleString()}
            </Button>
          )
        }, // formatter: v => new Date(v()).toLocaleString() },
        'sagaId',
        'domain',
        'action',
        'itemId',
        {
          id: 'data',
          formatter: v => <JSONCellFormatter>{v()}</JSONCellFormatter>
        },
        {
          id: 'options',
          formatter: v => <JSONCellFormatter>{v()}</JSONCellFormatter>
        }
      ]}
    >
      <AdvanceTable />
    </CrudTableProvider>
  );
};
export default () => {
  return (
    <>
      <PageHeader title="Dev Dashboard" className="mb-3" />
      <EventsTable />
      <DevActions />
    </>
  );
};
